<div (mouseleave)="initializeColumnHovered(); forceRerender()">
  <dx-data-grid
    #patientGrid
    (onRowClick)="onRowClick($event)"
    (onRowPrepared)="onRowPrepared($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    [allowColumnReordering]="true"
    [columnMinWidth]="15"
    [dataSource]="dataSource"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    [showColumnLines]="false"
    [showRowLines]="true"
    [wordWrapEnabled]="true"
    [remoteOperations]="true"
    id="patientGrid"
    noDataText="{{ 'No missions found' | transloco }}"
    width="100%"
  >
    <dxo-state-storing [enabled]="true" [savingTimeout]="500" [storageKey]="gridName"></dxo-state-storing>
    <dxo-filter-row [visible]="filter"></dxo-filter-row>
    <dxo-header-filter [visible]="filter"></dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100, 500]"></dxo-pager>
    <dxo-search-panel #searchInput [visible]="true" [(text)]="searchValue" placeholder="{{ 'Search...' | transloco }}"></dxo-search-panel>
    @for (column of columns; track column; let index = $index) {
    <dxi-column
      [allowReordering]="column['allowReordering']"
      [allowResizing]="column['allowResizing']"
      [allowSorting]="column['allowSorting']"
      [allowExporting]="column['allowExporting']"
      [calculateSortValue]="column['calculateSortValue']"
      [caption]="column['caption'] ? (column['caption'] | transloco) : ('' | transloco)"
      [cellTemplate]="column['dxTemplate']"
      [cssClass]="column.type === 'status' ? 'statusCol' : columnHovered[index] === true ? 'column-hover-effect' : ''"
      [dataField]="column['dataField']"
      [hidingPriority]="column['hidingPriority']"
      [minWidth]="column['minWidth']"
      [visible]="column['visibility']"
      [width]="column['width']"
      [dataType]="column['dataType']"
      [allowFiltering]="column['allowFilter']"
      [allowHeaderFiltering]="column['allowHeaderFiltering']"
      [trueText]="column['trueText']"
      [falseText]="column['falseText']"
      [filterOperations]="column['filterOperations']"
      alignment="left"
    >
    </dxi-column>
    }

    <div
      *dxTemplate="let d of 'statusColor'"
      [class]="
        d.value === -1
          ? 'template-statusBasic'
          : d.value === 0
          ? 'template-statusBasic status0'
          : d.value === 1
          ? 'template-statusBasic status1'
          : 'template-statusBasic status2'
      "
    ></div>

    <div *dxTemplate="let d of 'rowStatus'" class="template-rowStatus">
      @if (!d.data.arrivalTime) {
      <i [title]="'No pre-registration, date equals protocol date' | transloco" class="dx-icon-doc template-options-font-size"></i>
      <br />
      } @if (!d.data.seen && !d.data.new) {
      <i [title]="'Update for patient available' | transloco" class="icon icon-bold-reload template-options-font-size"></i>
      <br />
      } @if (d.data.seen && !d.data.settings.visible) {
      <i class="icon icon-bold-view-off template-options-font-size"></i> <br />
      }
    </div>

    <div *dxTemplate="let d of 'arrivalTime'" class="template-arrivalTime">
      <div>
        <div class="arrivalTime-spacer">
          @if (!displayDateIfToday(d.value)) {
          <div class="arrivalTime-additional-date-display">
            <div>
              @if (d.value) {
              {{ formatMoment(d.value, 'day') }}
              } @else {
              {{ formatMoment(d.data.date, 'day') }}
              }
            </div>
          </div>
          }
        </div>

        <div>
          <div>
            @if (d.value) {
            {{ formatMoment(d.value, 'time') }}
            } @else {
            {{ formatMoment(d.data.date, 'time') }}
            }
          </div>
        </div>

        <div class="arrivalTime-spacer">
          <div class="arrivalTime-additional-date-display">
            <div>
              @if (d.value) {
              <nida-web-countdown [end]="d.data['arrivalTime']"></nida-web-countdown>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let d of 'diagnosis'">
      <div>
        {{ d.value | transloco | translocoWord }}
      </div>
    </div>

    <div *dxTemplate="let d of 'gender'" class="template-gender">
      @switch (d.value) { @case (0) {
      <i class="icon icon-bold-gender-female"></i>
      } @case (1) {
      <i class="icon icon-bold-gender-male"></i>
      } @case (2) {
      <i class="icon icon-bold-gender-diverse"></i>
      } }
    </div>

    <div *dxTemplate="let d of 'breathingAndVentilation'">
      @switch (d?.data?.intubated) { @case (true) {
      <span class="color-red">{{ 'intubated' | transloco }} </span>
      } @case (false) {
      <span>{{ 'not intubated' | transloco }} </span>
      } } @switch (d?.data?.breathing) { @case ('unbeeinträchtigt') {
      <span>{{ 'unimpared' | transloco }}</span>
      } @case ('beeinträchtigt') {
      <span class="color-red">{{ 'impared' | transloco }}</span>
      } @case ('nicht-invasiv beatmet') {
      <span class="color-red">{{ 'non-invasively ventilated' | transloco }}</span>
      } @case ('invasiv beatmet') {
      <span class="color-red">{{ 'invasively ventilated' | transloco }}</span>
      } @default {
      <span class="color-red">{{ d.data.breathing | transloco }}</span>
      } }
    </div>

    <div *dxTemplate="let d of 'circulation'">
      @switch (d.value) { @case ('stabil') {
      <span>{{ 'Stable_lowercase' | transloco }}</span>
      } @case ('stabil unter Therapie') {
      <span>{{ 'stable under therapy' | transloco }}</span>
      } @case ('kontinuierliche Katecholamintherapie') {
      <span class="color-red">{{ 'continuous catecholamine therapy' | transloco }}</span>
      } @case ('instabil unter Therapie') {
      <span class="color-red">{{ 'unstable under therapy' | transloco }}</span>
      } @case ('instabil') {
      <span class="color-red">{{ 'Unstable_lowercase' | transloco }}</span>
      } @default {
      <span class="color-red">{{ d.value | transloco }}</span>
      } }
    </div>

    <div *dxTemplate="let d of 'consciousness'">
      @switch (d.value) { @case ('wach') {
      <span>{{ 'awake' | transloco }}</span>
      } @case ('getrübt') {
      <span class="color-red">{{ 'clouded' | transloco }}</span>
      } @case ('bewusstlos') {
      <span class="color-red">{{ 'unconscious' | transloco }}</span>
      } @case ('sediert / narkotisiert') {
      <span class="color-red">{{ 'sedated / narcotized' | transloco }}</span>
      } @default {
      <span class="color-red">{{ d.value | transloco }}</span>
      } }
    </div>

    <div *dxTemplate="let d of 'yesNoEmpty'" class="template-gender">
      @switch (d.value) { @case (true) {
      <span class="color-red">{{ 'Yes' | transloco }}</span>
      } @case (false) {
      <span>{{ 'No' | transloco }}</span>
      } }
    </div>

    <div *dxTemplate="let d of 'gcs'" [class.color-red]="d.value && d.value <= 8 && d.value >= 0">
      {{ d.value | replaceVitalParameter }}
    </div>

    <div *dxTemplate="let d of 'attachments'">
      @if (d.value.picture) {
      <i class="attachment icon icon-picture-landscape"></i>
      } @if (d.value.video) {
      <i class="attachment icon icon-video-player"></i>
      } @if (d.value.pdf) { @if (!d.value.pdfStatus) {
      <i class="attachment icon icon-office-file-pdf"></i>
      } @if (d.value.pdfStatus) {
      <i class="attachment icon icon-office-file-pdf pdf-status-read"></i>
      } } @if (d.value.ekg) {
      <i class="attachment icon icon-graph-stats-square"></i>
      }
    </div>

    <div *dxTemplate="let d of 'iStatus'">
      @if(d.value === 'ja'){
      <span class="color-red">{{ d.value }}</span>
      } @else {
      <span>{{ d.value }}</span>
      }
    </div>

    <div *dxTemplate="let d of 'hl7ExportAt'">
      @if (d.value) {
      <i class="icon icon-hyperlink"></i>
      }
    </div>

    <div *dxTemplate="let d of 'options'" style="position: relative">
      <div>
        <i (click)="onRowButtonClick(d)" class="icon icon-bold-navigation-menu-vertical"></i>
      </div>

      @if (d.data['settings']['clicked']) {
      <nida-web-patient-row-settings
        (closing)="triggerClosing(d)"
        (preventDetail)="preventDetailOpening()"
        [rawRowData]="d"
      ></nida-web-patient-row-settings>
      }
    </div>
  </dx-data-grid>
</div>
