export class PatientView {
  nidaId: string;
  protocolId: number;
  missionId?: string;
  status?: number;
  arrivalTime?: Date;
  diagnosis?: string;
  registrationText?: string;
  emergencyContact?: string;
  gender?: number;
  age?: number | null;
  ageType?: string;
  birthday?: Date;
  name?: string;
  firstName?: string;
  lastName?: string;
  circulation?: string;
  gcs?: number;
  intubated?: boolean;
  iStatus?: string;
  breathing?: string;
  consciousness?: string;
  vehicle?: string;
  vehiclePhone?: string;
  attachments?: {
    picture: string;
    video: string;
    pdf: string;
    pdfStatus?: number;
    ekg: string;
  };
  date?: Date;
  seen?: boolean;
  new?: boolean;
  settings?: {
    clicked: boolean;
    visible: boolean;
  };
  hl7ExportAt?: Date;
  archived?: boolean;
  dispatchTel?: string;

  c3_mission_id?: string;
  c3_mission_serial_dim?: string;
  prenotificationVersion?: number;

  constructor(
    nidaId: string,
    protocolId: number,
    missionId?: string,
    status?: number,
    arrivalTime?: Date,
    diagnosis?: string,
    registrationText?: string,
    emergencyContact?: string,
    gender?: number,
    age?: number | null,
    ageType?: string,
    birthday?: Date,
    name?: string,
    firstName?: string,
    lastName?: string,
    circulation?: string,
    gcs?: number,
    intubated?: boolean,
    iStatus?: string,
    breathing?: string,
    consciousness?: string,
    vehicle?: string,
    vehiclePhone?: string,
    attachments?: any,
    date?: Date,
    seen?: boolean,
    newPatient?: boolean,
    settings?: any,
    hl7ExportAt?: Date,
    archived?: boolean,
    dispatchTel?: string
  ) {
    this.nidaId = nidaId;
    this.protocolId = protocolId;
    this.missionId = missionId;
    this.status = status;
    this.arrivalTime = arrivalTime;
    this.diagnosis = diagnosis;
    this.registrationText = registrationText;
    this.emergencyContact = emergencyContact;
    this.gender = gender;
    this.age = age;
    this.ageType = ageType;
    this.birthday = birthday;
    this.name = name;
    this.firstName = firstName;
    this.lastName = lastName;
    this.circulation = circulation;
    this.gcs = gcs;
    this.intubated = intubated;
    this.iStatus = iStatus;
    this.breathing = breathing;
    this.consciousness = consciousness;
    this.vehicle = vehicle;
    this.vehiclePhone = vehiclePhone;
    this.attachments = attachments;
    this.date = date;
    this.seen = seen;
    this.new = newPatient;
    this.settings = settings;
    this.hl7ExportAt = hl7ExportAt;
    this.archived = archived;
    this.dispatchTel = dispatchTel;
  }
}
