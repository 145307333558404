<div class="missions-overview-table-wrapper m-3">
  <dx-data-grid
    #kisGrid
    (onContentReady)="applyFilterToState(kisGrid)"
    (onRowExpanding)="onRowExpanding($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="exportToExcelFileService.export($event, 'exported_kis_list')"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [dataSource]="this.data"
    [masterDetail]="{ enabled: true, template: 'detail' }"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    [showColumnLines]="true"
    [showRowLines]="true"
    [wordWrapEnabled]="true"
    id="kisGrid"
    keyExpr="id"
    noDataText="{{ 'No his data found' | transloco }}"
  >
    <dxo-export [enabled]="true" [texts]="exportTexts"></dxo-export>
    <dxo-state-storing [enabled]="false" [savingTimeout]="500" storageKey="kisGrid"></dxo-state-storing>
    <dxo-filter-row [visible]="filter"></dxo-filter-row>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-search-panel [visible]="true" placeholder="{{ 'Search...' | transloco }}"></dxo-search-panel>
    <ng-container *ngFor="let column of columns; index as index">
      <dxi-column
        [allowReordering]="!!column['allowReordering']"
        [allowResizing]="!!column['allowResizing']"
        [allowSorting]="!!column['allowSorting']"
        [calculateSortValue]="column['calculateSortValue']"
        [caption]="column['caption']?.toString() | transloco"
        [cellTemplate]="column['dxTemplate']"
        [cssClass]="column.name === 'status' ? 'statusCol' : columnHovered[index] === true ? 'column-hover-effect' : ''"
        [dataField]="column['name']?.toString()"
        [dataType]="column['dataType']?.toString()"
        [filterValue]="column['filterValue']"
        [hidingPriority]="column['displayPriority'] + 0"
        [minWidth]="column['minWidth'] + 0"
        [visible]="!!column['visibility']"
        [width]="column['width']?.toString()"
        [editorOptions]="{ showClearButton: true }"
        alignment="left"
      >
      </dxi-column>
    </ng-container>

    <div *dxTemplate="let data of 'newToolbar'">
      <div>
        <button (click)="changeFilterVisibility()" [ngClass]="{ 'clicked-button-status': filter }" class="basic-button-design">
          <i class="icon icon-bold-filter"></i>
        </button>
        <ng-container *ngIf="getScreen() !== 'screen-x-small'">
          <button (click)="changeColumnVisibility()" [ngClass]="{ 'clicked-button-status': tooltip }" class="basic-button-design">
            <i class="icon icon-bold-layout-column"></i>
          </button>
          <button
            (click)="resetListState(kisGrid)"
            (mouseenter)="toggleResetTooltip()"
            (mouseleave)="toggleResetTooltip()"
            class="basic-button-design"
            id="resetListButton"
          >
            <i class="icon icon-bold-rotate-back"></i>
            <dx-tooltip [(visible)]="resetTooltipVisible" [hideOnOutsideClick]="false" target="#resetListButton">
              <div *dxTemplate="let data = data; of: 'content'">
                {{ 'Restore default view of the list' | transloco }}
              </div>
            </dx-tooltip>
          </button>
        </ng-container>
      </div>
    </div>
    <div *dxTemplate="let kis of 'detail'" class="template-masterview">
      <div class="master-detail-caption">
        <nida-web-kis-row-detail [currentKis]="kis.data" [nidaId]="nidaId" [protocolId]="protocolId"></nida-web-kis-row-detail>
      </div>
    </div>
    <div *dxTemplate="let d of 'date'" class="template-masterview">
      {{ formatMoment(d.value, 'date') }}
    </div>
    <div *dxTemplate="let d of 'timestamp'" class="template-masterview">
      {{ formatMoment(d.value, 'medium') }}
    </div>
    <div *dxTemplate="let d of 'adtMessage'" class="template-masterview">
      {{ d.value | adtMessageConverter | transloco }}
    </div>
  </dx-data-grid>
</div>
