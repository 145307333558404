import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayNanConfig } from './models/display-nan-config.model';

import { DISPLAY_NAN_CONFIG, DisplayNanPipe } from './pipes/display-nan.pipe';
import { MeddvLoaderComponent } from './meddv-loader/meddv-loader.component';
import { KeyExtractionPipe } from './pipes/key-extraction.pipe';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BreadcrumbService } from './services/breadcrumb.service';
import { TableManagementService } from './services/table-management.service';
import { CountdownComponent } from './countdown/countdown.component';
import { ModuleSettingsService } from './services/module-settings.service';
import { DecamelizePipe } from './pipes/decamelize.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ProcessStepperComponent } from './process-stepper/process-stepper.component';
import { ProcessMultiStepperComponent } from './process-multi-stepper/process-multi-stepper.component';
@NgModule({
  declarations: [
    DisplayNanPipe,
    MeddvLoaderComponent,
    KeyExtractionPipe,
    BreadcrumbsComponent,
    CountdownComponent,
    DecamelizePipe,
    ProcessStepperComponent,
    ProcessMultiStepperComponent,
  ],
  imports: [CommonModule, TranslocoModule],
  exports: [
    DisplayNanPipe,
    MeddvLoaderComponent,
    KeyExtractionPipe,
    BreadcrumbsComponent,
    CountdownComponent,
    DecamelizePipe,
    ProcessStepperComponent,
    ProcessMultiStepperComponent,
  ],
  providers: [{ provide: DISPLAY_NAN_CONFIG, useValue: { nanPlaceholder: '—' } }, BreadcrumbService, TableManagementService],
})
/**
 * Provides all services in root (see below) or in module scope (see above)
 */
export class CoreModule {
  static forRoot(config: DisplayNanConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [{ provide: DISPLAY_NAN_CONFIG, useValue: config }, ModuleSettingsService],
    };
  }
}
