import * as Sentry from '@sentry/angular';
import { Injectable } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { DevExpressFilterOperations } from '../../models/dev-express-filter-operations.enum';
import { devExpressFilter, devExpressFilterArray } from '../../models/dev-express-filter.model';
import { devExpressSortArray } from '../../models/dev-express-sort.model';
import { LoadOptions } from 'devextreme/data';
import {
  ElasticQueryRangeObject,
  ElasticQueryString,
  ElasticQueryStringObject,
  ElasticRestService,
  ElasticResult,
  QueryDSL,
} from '@nida-web/api/rest/nidaserver/elastic';
import { columnTypeByName } from '../../models/columnTypeByName';
import DevExpress from 'devextreme';
import Column = DevExpress.ui.dxDataGrid.Column;
import SortDescriptor = DevExpress.data.SortDescriptor;

/*

Some information about the devExpress search string

Search types and combinations to identify the filterTypes Enum out of devExpressFilterArray:

Global:

- [1] Entry contains "or"

Single column:

- [1] Entry is "Search Operation"
  OR
- [0][0] === [2][0]

Multi column:

- Every odd entry in the array is "and"
- (
  - every even entry is "Search Operation"
  - OR
  - [x][0] === [x][2]
- )

Global & Single column:

- [0] === [0][0] === [0][2]
- OR
- (
  - [0][1] is "Search Operation"
  - [1] is "and"
  - [2] all odd are "or"
- )

Global & Multi column:

- (
  - [0] all odd are "and"
  - AND
  - [0][0] !== [0][2]
- )
- [1] is "and"
- [2] all odd are "or"
 */

enum filterTypes {
  None,
  Global,
  SingleColumn,
  MultiColumn,
  GlobalAndSingleColumn,
  GlobalAndMultiColumn,
}

// https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-types.html
type unmappedType = 'boolean' | 'keyword' | 'constant_keyword' | 'long' | 'double' | 'date' | 'date_nanos' | 'ip' | 'version';

@Injectable({
  providedIn: 'root',
})
export class ElasticStoreService {
  get constQueryStrings(): ElasticQueryString[] {
    return this._constQueryStrings;
  }

  set constQueryStrings(value: ElasticQueryString[]) {
    this._constQueryStrings = value;
  }

  get indexName(): string {
    return this._indexName;
  }

  set indexName(value: string) {
    this._indexName = value;
    this.elasticQuery.indexName = this._indexName;
  }

  set take(value: number) {
    if (this.elasticQuery.pagination) {
      this.elasticQuery.pagination.take = value;
    }
  }

  set skip(value: number) {
    if (this.elasticQuery.pagination) {
      this.elasticQuery.pagination.skip = value;
    }
  }

  get filterType(): filterTypes {
    return this._filterType;
  }

  get globalFilterValue(): string {
    return this._globalFilterValue;
  }

  get globalFilterColumns(): string[] {
    return this._globalFilterColumns;
  }

  get globalFilterColumnIndex(): number[] {
    return this._globalFilterColumnIndex;
  }

  get columnsWithFilter(): { columnName: string; filterValue: string | boolean | number }[] {
    return this._columnsWithFilter;
  }

  public queryPrepared: BehaviorSubject<boolean>;
  protected elasticQuery: QueryDSL;
  private _indexName = 'meddv-elastic-index';
  private _reviseData: (data: any[]) => any[];
  private _filterType: filterTypes;
  private _constQueryStrings: ElasticQueryString[];
  private _globalFilterValue = '';
  private _globalFilterColumns: string[] = [];
  private _globalFilterColumnIndex: number[] = [];
  private mapFieldsToRest: (appFields: string[]) => string[];
  private defaultSort: Record<string, unknown>;
  private dataGrid: DxDataGridComponent;
  private _rangeFiltersMust: ElasticQueryRangeObject[];
  private _rangeFiltersShould: ElasticQueryRangeObject[];
  private _columnsWithType: columnTypeByName[];
  private _columnsWithFilter: {
    columnName: string;
    filterValue: string | boolean | number;
  }[] = [];

  constructor(protected elasticRestService: ElasticRestService) {
    this.queryPrepared = new BehaviorSubject(false);
    this._constQueryStrings = [];
    this._columnsWithType = [];
    this._rangeFiltersMust = [];
    this._rangeFiltersShould = [];
    this._filterType = filterTypes.None;
    this.resetQuery();
  }

  private static isEven(n) {
    return n % 2 === 0;
  }

  private static isOdd(n) {
    return Math.abs(n % 2) === 1;
  }

  private static removeDateFilter(filter: devExpressFilterArray, index: number): void {
    if (index > 0) {
      filter.splice(index - 1, 2);
    } else {
      filter.splice(index, 2);
    }
  }

  /**
   * Creates ElasticSearch expression from Devextreme filter expression
   * e.g. 'Test'+ 'startswith' becomes 'Test*'
   */
  private static fitSearchTermToFilterOperation(
    searchTerm: string | boolean | number,
    filterOperation: DevExpressFilterOperations
  ): string | boolean | number {
    if (typeof searchTerm !== 'string') {
      if (typeof searchTerm === 'number') {
        switch (filterOperation) {
          case DevExpressFilterOperations.NotEqual:
            searchTerm = '(!' + searchTerm + ')';
            break;
        }
      }
      return searchTerm;
    }

    // console.log('fitSearchTerm' , searchTerm , typeof searchTerm, filterOperation);
    // TODO: do we need smaller/ greater than filter ? if yes, number should be evaluated further
    searchTerm = searchTerm.trim();
    // ignore eslint in order for regex to work
    // eslint-disable-next-line
    searchTerm = searchTerm.replace(/([+\-\\=<>!(){}\]\[^"~*?:/])/g, '\\' + '$1');
    switch (filterOperation) {
      case DevExpressFilterOperations.Contains:
        searchTerm = '*' + searchTerm + '*';
        break;
      case DevExpressFilterOperations.NotContains:
        searchTerm = '(!*' + searchTerm + '*)';
        break;
      case DevExpressFilterOperations.StartsWith:
        // makes sense if user inputs only one string per field
        searchTerm = searchTerm + '*';
        break;
      case DevExpressFilterOperations.EndsWith:
        // makes sense if user inputs only one string per field
        searchTerm = '*' + searchTerm;
        break;
      case DevExpressFilterOperations.NotEqual:
        searchTerm = '(!' + searchTerm + ')';
        break;
      case DevExpressFilterOperations.Smaller:
        searchTerm = '<' + searchTerm;
        break;
      case DevExpressFilterOperations.Greater:
        searchTerm = '>' + searchTerm;
        break;
      case DevExpressFilterOperations.SmallerEqual:
        searchTerm = '<=' + searchTerm;
        break;
      case DevExpressFilterOperations.GreaterEqual:
        searchTerm = '>=' + searchTerm;
        break;
    }

    return searchTerm;
  }

  private generateRangeFilter(filterItem): ElasticQueryRangeObject {
    const filterValue1: string = filterItem[0][2];
    const filterValue2: string = filterItem[2][2];
    const columName: string | undefined = this.mapFieldsToRest([filterItem[0][0]]).pop();
    const columnRestName: string = columName ? columName : '';
    // every date field will have its own range
    return {
      range: {
        [columnRestName]: {
          gte: filterValue1,
          lt: filterValue2,
        },
      },
    };
  }

  createElasticPit(): void {
    this.elasticRestService.createPit(this._indexName, { keep_alive: '5m' }).subscribe((pitId) => {
      this.elasticRestService.queryPit(pitId.id, this._indexName, this.elasticQuery).subscribe((value) => {
        console.log(value);
      });
    });
  }

  private buildElasticSortWithOptions(columnName: string, order: 'desc' | 'asc', unmappedType?: unmappedType): Record<string, unknown> {
    let column = columnName;
    const columnType = this._columnsWithType.find((columnTypeByNames) => {
      return columnTypeByNames.name === columnName;
    });

    const sortOption: {
      format?: string;
      order: 'asc' | 'desc';
      unmapped_type?: unmappedType;
    } = {
      order: order,
    };

    if (unmappedType) {
      sortOption.unmapped_type = unmappedType;
    }

    if (columnType) {
      switch (columnType.dataType) {
        case 'datetime':
        case 'date':
          sortOption.format = 'strict_date_optional_time_nanos';
          break;
        case 'string':
          column = this.addKeywordToSearchColumn(column);
          break;
      }
    }
    const elasticSort = {};
    elasticSort[column] = sortOption;

    return elasticSort;
  }

  buildSort(sort: devExpressSortArray) {
    if (sort.length === 1) {
      const sortItem = sort[0];
      if (sortItem && sortItem.selector && sortItem.desc !== undefined) {
        const columnName = this.mapFieldsToRest([sortItem.selector])[0];
        const order = sortItem.desc ? 'desc' : 'asc';
        if (this.elasticQuery.pagination) {
          this.elasticQuery.pagination.sort = [this.buildElasticSortWithOptions(columnName, order)];
        }
      }
    }
  }

  addKeywordToSearchColumn(column: string): string {
    return column + '.keyword';
  }

  isValidFilterOperation(filter: devExpressFilter | string): boolean {
    return typeof filter === 'string' && Object.values<string>(DevExpressFilterOperations).includes(filter);
  }

  addGlobalSearchToQuery(filter: devExpressFilterArray | devExpressFilter) {
    if (Array.isArray(filter)) {
      filter.forEach((filterItem) => {
        //you cant search for boolean variables via the global search
        if (typeof filterItem[2] === 'string') {
          if (Array.isArray(filterItem) && !Array.isArray(filterItem[0]) && filterItem[0]) {
            const terms = filterItem[2].trim().split(/\s+/);
            let output = '';
            terms.forEach((term) => (output += ' ' + ElasticStoreService.fitSearchTermToFilterOperation(term, filterItem[1])));
            this._globalFilterValue = output.trim();
          }
          if (filterItem !== 'or' && !Array.isArray(filterItem[0])) {
            this._globalFilterColumns.push(filterItem[0]);
            this._globalFilterColumnIndex.push(filterItem.columnIndex);
          }
        }
      });
    }
  }

  addSingleSearchToQuery(filter) {
    let searchTerms = typeof filter[2] === 'string' ? [filter[2].trim()] : [filter[2]];
    if (filter[1] == 'contains') {
      // split searchTerms on whitespace(s)
      searchTerms = filter[2].split(/\s+/);
    }
    searchTerms.forEach((term) => {
      const columnName = filter[0];
      const columnFilterValue = ElasticStoreService.fitSearchTermToFilterOperation(term, filter[1]);
      if (typeof columnName === 'string') {
        this._columnsWithFilter.push({
          columnName: columnName,
          filterValue: columnFilterValue,
        });
      }
    });
  }

  private extractMultiColumnDateFilters(filter) {
    const dateIndexes: number[] = [];
    filter.forEach((value, index) => {
      if (Array.isArray(value) && value[0][0] && value[2][0] && value[0][0] === value[2][0]) {
        dateIndexes.push(index);
        this._rangeFiltersMust.push(this.generateRangeFilter(value));
      }
    });
    dateIndexes.reverse();
    dateIndexes.forEach((index) => {
      ElasticStoreService.removeDateFilter(filter, index);
    });
  }

  private extractGlobalDateFilters(filter) {
    filter.forEach((value, index) => {
      // isArray check is needed to prevent comparison of strings and so on
      if (
        Array.isArray(value) &&
        value[0][0] &&
        Array.isArray(value[2]) &&
        Array.isArray(value[0]) &&
        value[2][0] &&
        value[0][0] === value[2][0]
      ) {
        this._rangeFiltersShould.push(this.generateRangeFilter(value));
        ElasticStoreService.removeDateFilter(filter, index);
      }
    });
  }

  private buildDateFilter(filter: devExpressFilterArray): devExpressFilterArray {
    switch (this._filterType) {
      case filterTypes.Global:
        this.extractGlobalDateFilters(filter);
        break;
      case filterTypes.SingleColumn:
        if (filter[0][0] === filter[2][0]) {
          this._rangeFiltersMust.push(this.generateRangeFilter(filter));
          filter = [];
        }
        break;
      case filterTypes.MultiColumn:
        this.extractMultiColumnDateFilters(filter);
        break;
      case filterTypes.GlobalAndSingleColumn: {
        const columnFilter = filter[0];
        if (columnFilter[0][0] === columnFilter[2][0]) {
          this._rangeFiltersMust.push(this.generateRangeFilter(columnFilter));
          filter[0] = [];
        }
        this.extractGlobalDateFilters(filter[2]);
        break;
      }
      case filterTypes.GlobalAndMultiColumn:
        this.extractMultiColumnDateFilters(filter[0]);
        this.extractGlobalDateFilters(filter[2]);
        break;
    }
    this.buildStringFilter(filter);
    return filter;
  }

  private checkEveryOdd(filter: devExpressFilter | devExpressFilterArray, compare: 'and' | 'or'): boolean {
    let ok = true;
    if (Array.isArray(filter) && filter.length > 0) {
      filter.forEach((value, index) => {
        if (ElasticStoreService.isOdd(index) && value !== compare) {
          ok = false;
        }
      });
    } else {
      ok = false;
    }

    return ok;
  }

  private checkEveryEvenForSearchOperationOrDateCompare(filter: devExpressFilterArray): boolean {
    let ok = true;

    filter.forEach((value, index) => {
      if (ElasticStoreService.isEven(index) && !this.isValidFilterOperation(value[1]) && value[0][0] !== value[2][0]) {
        ok = false;
      }
    });
    return ok;
  }

  analyseFilter(filter: devExpressFilterArray) {
    if (filter.length === 0) {
      this._filterType = filterTypes.None;
    } else if (filter[1] && filter[1] === 'or') {
      this._filterType = filterTypes.Global;
    } else if (filter[1] && (this.isValidFilterOperation(filter[1]) || filter[0][0] === filter[2][0])) {
      this._filterType = filterTypes.SingleColumn;
    } else if (this.checkEveryOdd(filter, 'and') && this.checkEveryEvenForSearchOperationOrDateCompare(filter)) {
      this._filterType = filterTypes.MultiColumn;
    } else {
      this.analyseFilterForGlobalAndColumn(filter);
    }
    this.buildDateFilter(filter);
  }

  private analyseFilterForGlobalAndColumn(filter) {
    if (filter[1] && filter[1] === 'and' && this.checkEveryOdd(filter[2], 'or')) {
      const filterFirstItem = filter[0];
      if (
        filterFirstItem[0] &&
        filterFirstItem[1] &&
        filterFirstItem[2] &&
        (filterFirstItem[0][0] === filterFirstItem[2][0] || this.isValidFilterOperation(filterFirstItem[1]))
      ) {
        this._filterType = filterTypes.GlobalAndSingleColumn;
      } else {
        this._filterType = filterTypes.GlobalAndMultiColumn;
      }
    } else {
      Sentry.captureMessage('Analysis for filter values did not match for filter: ' + filter);
    }
  }

  private buildStringFilter(filter: devExpressFilterArray) {
    switch (this._filterType) {
      case filterTypes.Global:
        this.addGlobalSearchToQuery(filter);
        break;
      case filterTypes.SingleColumn:
        if (filter.length > 0) {
          this.addSingleSearchToQuery(filter);
        }
        break;
      case filterTypes.MultiColumn:
        this.fetchSingleSearchesFromArray(filter);
        break;
      case filterTypes.GlobalAndSingleColumn:
        if (filter[0].length > 0) {
          this.addSingleSearchToQuery(filter[0]);
        }
        this.addGlobalSearchToQuery(filter[2]);
        break;
      case filterTypes.GlobalAndMultiColumn:
        if (Array.isArray(filter[0])) {
          this.fetchSingleSearchesFromArray(filter[0]);
        }
        this.addGlobalSearchToQuery(filter[2]);
        break;
    }
  }

  private fetchSingleSearchesFromArray(filter) {
    filter.forEach((filterItem) => {
      if (filterItem !== 'and') {
        this.addSingleSearchToQuery(filterItem);
      }
    });
  }

  buildColumnQueryStrings(queryStrings: ElasticQueryStringObject[]) {
    this._columnsWithFilter.forEach((filterColumnName) => {
      const filterValue = filterColumnName.filterValue;
      const filterColumnsRest = this.mapFieldsToRest([filterColumnName.columnName]);
      filterColumnsRest.forEach((restColumnName) => {
        const queryString: ElasticQueryString = {
          query: filterValue,
          fields: [restColumnName],
        };
        queryStrings.push({ query_string: queryString });
      });
    });
  }

  public addColumnQueryString(queryString: ElasticQueryString) {
    this._constQueryStrings.push(queryString);
  }

  public setDefaultSort(columnName: string, order: 'desc' | 'asc', unmappedType: unmappedType) {
    if (this.elasticQuery.pagination) {
      this.defaultSort = this.buildElasticSortWithOptions(columnName, order, unmappedType);
    }
  }

  addConstColumnQueryStrings(queryStrings: ElasticQueryStringObject[]) {
    this._constQueryStrings.forEach((queryString) => {
      queryStrings.push({ query_string: queryString });
    });
  }

  buildQuery() {
    switch (this._filterType) {
      case filterTypes.Global:
      case filterTypes.GlobalAndMultiColumn:
      case filterTypes.GlobalAndSingleColumn: {
        const queryStringsShould: Array<ElasticQueryStringObject> = [];
        const queryStringsMust: Array<ElasticQueryStringObject> = [];
        const globalFilterColumnsRest = this.mapFieldsToRest(this._globalFilterColumns);
        const globalFilterValues = this._globalFilterValue.split(/\s+/);
        globalFilterValues.forEach((globalSearchTerm) => {
          globalFilterColumnsRest.forEach((value) => {
            const queryString: ElasticQueryString = {
              query: globalSearchTerm,
              fields: [value],
            };
            queryStringsShould.push({ query_string: queryString });
          });
        });
        this.buildColumnQueryStrings(queryStringsMust);
        this.addConstColumnQueryStrings(queryStringsMust);

        const tempQueryStringsShould: Array<ElasticQueryStringObject | ElasticQueryRangeObject> = queryStringsShould;
        const tempQueryStringsMust: Array<ElasticQueryStringObject | ElasticQueryRangeObject> = queryStringsMust;

        this._rangeFiltersShould.forEach((value) => {
          tempQueryStringsShould.push(value);
        });
        this._rangeFiltersMust.forEach((value) => {
          tempQueryStringsMust.push(value);
        });

        this.elasticQuery.query = {
          bool: {
            should: tempQueryStringsShould,
            must: tempQueryStringsMust,
            minimum_should_match: globalFilterValues.length,
          },
        };
        break;
      }
      case filterTypes.SingleColumn:
      case filterTypes.MultiColumn: {
        const queryStrings: Array<ElasticQueryStringObject> = [];
        this.buildColumnQueryStrings(queryStrings);
        this.addConstColumnQueryStrings(queryStrings);
        const tempQueryStrings: Array<ElasticQueryStringObject | ElasticQueryRangeObject> = queryStrings;

        this._rangeFiltersMust.forEach((value) => {
          tempQueryStrings.push(value);
        });
        this.elasticQuery.query = {
          bool: {
            must: tempQueryStrings,
          },
        };
        break;
      }
      case filterTypes.None: {
        const queryStrings: Array<ElasticQueryStringObject> = [];
        this.addConstColumnQueryStrings(queryStrings);
        this.elasticQuery.query = {
          bool: {
            must: queryStrings,
            should: [],
          },
        };
      }
    }
    this.queryPrepared.next(true);

    return this.elasticQuery;
  }

  cleanUpFirst() {
    this._columnsWithFilter = [];
    this._globalFilterColumns = [];
    this._globalFilterValue = '';
    this._rangeFiltersShould = [];
    this._rangeFiltersMust = [];
    this._filterType = filterTypes.None;
    this.resetQuery();
  }

  private resetQuery() {
    this.elasticQuery = {
      indexName: this._indexName,
      query: {
        bool: {
          must: [],
          should: [],
          minimum_should_match: 1,
        },
      },
      pagination: {
        sort: [this.defaultSort],
        take: 10,
      },
    };
  }

  analyseLoadOptions(loadOptions: LoadOptions) {
    let filter: devExpressFilterArray = [];
    this.take = loadOptions.take ? loadOptions.take : 10;
    this.skip = loadOptions.skip ? loadOptions.skip : 0;

    if (loadOptions.filter) {
      filter = loadOptions.filter;
    }
    this.analyseFilter(filter);
    this.analyseSort(loadOptions.sort);
  }

  private analyseSort(sortDescription: SortDescriptor<any> | SortDescriptor<any>[] | undefined) {
    const sort: devExpressSortArray = [];
    if (sortDescription && sortDescription instanceof Array) {
      sortDescription.forEach((value) => {
        let desc = false;
        if (value['desc'] && typeof value['desc'] === 'boolean') {
          desc = value['desc'];
        }
        let selector = '';
        if (value['selector'] && typeof value['selector'] === 'string') {
          selector = value['selector'];
        }
        if (selector.length > 0) {
          sort.push({
            desc: desc,
            selector: selector,
          });
        }
      });
    }
    this.buildSort(sort);
  }

  private analyseColumnTypes() {
    this.dataGrid.columns.forEach((column: Column) => {
      const availableFilterTypes = ['string', 'number', 'date', 'datetime'];
      if (column.dataType && column.name && availableFilterTypes.indexOf(column.dataType) !== -1) {
        const columnRestName = this.mapFieldsToRest([column.name]).pop();
        if (columnRestName) {
          this._columnsWithType.push({
            dataType: column.dataType,
            name: columnRestName,
          });
        }
      }
    });
  }

  fireQuery<Type>(
    loadOptions: LoadOptions,
    // TODO: Define "any" later
    mapEntry: (item: any) => Type,
    mapFieldsToRest: (appFields: string[]) => string[],
    dataGrid: DxDataGridComponent,
    reviseResult?: (data: Type[]) => Type[]
  ): PromiseLike<{ data: Type[]; totalCount: number }> {
    this.mapFieldsToRest = mapFieldsToRest;
    if (reviseResult) {
      this._reviseData = reviseResult;
    } else {
      this._reviseData = (data) => {
        return data;
      };
    }
    this.cleanUpFirst();
    if (dataGrid) {
      this.dataGrid = dataGrid;
      this.analyseColumnTypes();
    }
    this.analyseLoadOptions(loadOptions);
    this.buildQuery();

    return this.elasticRestService
      .elasticQuery(this._indexName, this.elasticQuery)
      .pipe(debounceTime(1000))
      .toPromise()
      .then((response: ElasticResult) => {
        if (!response) throw new Error('Expected Response');
        const sets = response.hits.hits;
        const totalCount: number = response.hits.total.value;
        let data: Type[] = [];

        sets.forEach((hit) => {
          data.push(mapEntry(hit._source));
        });

        data = this._reviseData(data);

        return {
          data: data,
          totalCount: totalCount, // if requireTotalCount = true
        };
      });
  }
}
