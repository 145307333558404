import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavAppInfoService } from '@nida-web/navigation';
import { FormBuilder, Validators } from '@angular/forms';
import { LogoSettingsService } from '@nida-web/core';
import { Subscription } from 'rxjs';
import { AppInfoService } from '@nida-web/shared/data-access';
import { AuthenticationServable } from '@nida-web/api/generic-interfaces/authentication';

@Component({
  selector: 'nida-web-secure-code-auth',
  templateUrl: './secure-code-auth.component.html',
  styleUrls: ['./secure-code-auth.component.scss'],
})
export class SecureCodeAuthComponent implements OnInit, OnDestroy {
  public logoSettings: string[];
  private subscriptionArray: Array<Subscription>;
  public appTitle: string;
  public codePattern = /^\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*$/;
  public valueChangeEvent = 'change';
  public showWrongCodeMessage = false;
  public validatingCode = false;

  public codeForm = this.fb.group({
    code: ['', Validators.required],
  });

  constructor(
    private auth: AuthenticationServable,
    public navAppInfoService: NavAppInfoService,
    private logoSettingsService: LogoSettingsService,
    private appInfo: AppInfoService,
    private fb: FormBuilder
  ) {
    this.logoSettings = [];
    this.subscriptionArray = [];
    this.appTitle = this.appInfo.title;
  }

  public ngOnInit(): void {
    this.getLogoInformation();
  }

  ngOnDestroy() {
    this.subscriptionArray.forEach((sub) => sub.unsubscribe());
  }

  validateCodeInput() {
    this.valueChangeEvent = 'keyup';
  }

  public submitForm(): void {
    if (this.validatingCode) return;
    this.validatingCode = true;
    if (this.codeForm.value.code) {
      this.auth.login2FA(SecureCodeAuthComponent.extractNumbersOnly(this.codeForm.value.code)).subscribe((result) => {
        console.log('Result: ', result);
        if (!result) {
          this.validatingCode = false;
          this.codeForm.reset();
        }
      });
    }
  }

  public cancel2FAForm(): void {
    this.auth.startLogin();
  }

  public static extractNumbersOnly(inputString: string): string {
    return inputString.replace(/[^0-9]/g, '');
  }

  private getLogoInformation() {
    this.subscriptionArray.push(
      this.logoSettingsService.getSettings().subscribe((settings) => {
        for (const [logoName, show] of Object.entries(settings)) {
          if (show) {
            this.logoSettings.push(logoName);
          }
        }
      })
    );
  }
}
