import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
// eslint-disable-next-line @nx/enforce-module-boundaries
import packageInfo from '../../../package.json';
import config from './assets/config/NIDAtracker/default/config.json';
import { environment } from './environments/environment';

if (environment.production) enableProdMode();

if (!isDevMode()) {
  Sentry.init({
    ignoreErrors: [
      // TODO: Is this the best solution? All Errors will be ignored.
      '401 Unauthorized',
      'Non-Error exception captured',
    ],
    // Should be possible to disable, if the app is used in a closed system, that cannot reach sentry.io
    enabled: config.EnableModules.sentry,
    release: packageInfo.version,
    dsn: 'https://b72dae72fb8e42399b8b9dbf8513ca84@o476262.ingest.sentry.io/4504643603922944',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // no tracing means 0 and adjust it in between 0.0 and 1.0
    // TODO: Deactivated due to additional header "sentry-trace: 94c2b95566f0415792b10e34cfaba0fb-84d0278919cec2a1-1"...
    // TODO: ... which is blocked by CORS of NIDAserver at the moment
    // tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  })
  .catch((err) => console.error(err));
