import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableManagementService {
  constructor() {}

  public expandRow(e: any) {
    if (e.handled) return;
    if (e.rowType !== 'detail') {
      const rowIndex = e.rowIndex;
      const rowKey = e.component.getKeyByRowIndex(rowIndex);
      if (!e.component.isRowExpanded(rowKey)) {
        e.component.expandRow(rowKey);
      } else {
        e.component.collapseRow(rowKey);
      }
    }
  }

  public expandSingleRow(e: any, checkHandled?: boolean) {
    if (checkHandled === undefined) {
      checkHandled = true;
    }

    if (checkHandled || (e.rowType === 'group' && e.handled)) {
      if (e.handled) return;
    }
    if (e.rowType !== 'detail') {
      const rowIndex = e.rowIndex;
      const rowKey = e.component.getKeyByRowIndex(rowIndex);
      if (!e.component.isRowExpanded(rowKey)) {
        this.collapseAllRows(e);
        e.component.expandRow(rowKey);
      } else {
        e.component.collapseRow(rowKey);
      }
    }
  }

  public expandRowForColumnsWithCaption(e: any) {
    if (e.column.caption) {
      this.expandRow(e);
    }
  }

  public collapseAllRows(e: any) {
    if (e.rowType !== 'detail') {
      e.component.collapseAll(-1);
    }
  }
}
