<ng-container *ngIf="loaded; else elseBlock">
  <div class="global-slider-holder basic-margin-10">
    <dx-range-selector
      (onValueChanged)="onValueChanged($event)"
      [(value)]="visualRange"
      [dataSource]="previewData"
      [shutter]="{ opacity: 0.4 }"
      id="ecg-global-slider"
    >
      <dxo-slider-marker [customizeText]="customizeTextArgumentAxisLabel"></dxo-slider-marker>

      <dxo-size [height]="90"></dxo-size>
      <dxo-margin [left]="10"></dxo-margin>
      <dxo-behavior [allowSlidersSwap]="false" [animationEnabled]="false" [snapToTicks]="false"></dxo-behavior>

      <dxo-scale [maxRange]="visualRange['endValue']" [minRange]="visualRange['endValue']">
        <dxo-behavior [allowSlidersSwap]="false" [manualRangeSelectionEnabled]="false"></dxo-behavior>
        <dxo-label [customizeText]="customizeTextArgumentAxisLabel"></dxo-label>
      </dxo-scale>

      <dxo-chart>
        <dxi-series [ignoreEmptyPoints]="true" argumentField="id" type="line" valueField="value"></dxi-series>
        <dxi-series [ignoreEmptyPoints]="true" argumentField="id" type="line" valueField="bottomLine"></dxi-series>

        <dxo-common-series-settings [ignoreEmptyPoints]="true" color="dimgrey" width="1px">
          <dxo-point [size]="1"></dxo-point>
        </dxo-common-series-settings>
        <dxo-argument-axis [color]="'blue'" [wholeRange]="{ startValue: 0, length: 200000 }">
          <!--          <dxo-label [customizeText]="customizeTextArgumentAxisLabel"></dxo-label>-->
        </dxo-argument-axis>
      </dxo-chart>
    </dx-range-selector>
  </div>

  <div class="flex-container basic-margin-10">
    <div class="basic-margin-right basic-padding-top-bottom-5">
      <dx-button-group
        (onItemClick)="onZoomStepClick($event)"
        [items]="zoomSteps"
        [selectedItemKeys]="[zoomStep['text']]"
        keyExpr="text"
        selectionMode="single"
        stylingMode="outlined"
      ></dx-button-group>
    </div>

    <div class="basic-margin-right basic-padding-top-bottom-5">
      <dx-button-group
        (onItemClick)="onSpeedStepClick($event)"
        [items]="speedSteps"
        [selectedItemKeys]="[speedStep['text']]"
        keyExpr="text"
        selectionMode="single"
        stylingMode="outlined"
      ></dx-button-group>
    </div>

    <div class="basic-margin-right basic-padding-top-bottom-5">
      <dx-button-group
        (onItemClick)="changeCurrentDetailMode($event)"
        [items]="modeOptions"
        [selectedItemKeys]="[modeOption.icon]"
        keyExpr="icon"
        selectionMode="single"
        stylingMode="outlined"
      ></dx-button-group>
    </div>

    <div class="basic-margin-right basic-padding-top-bottom-5">
      <dx-button (click)="undoMajorChanges()" [icon]="'icon icon-bold-rotate-back'" stylingMode="contained"></dx-button>
    </div>
    <div class="basic-margin-right basic-padding-top-bottom-5">
      <dx-button
        *ngIf="pdfGenerationEnabled && protocolId"
        (click)="generatePdf()"
        icon="print"
        [text]="'Print PDF' | transloco"
      ></dx-button>
    </div>
  </div>

  <div>
    <div *ngFor="let channel of chosenChannels">
      <div class="lead-holder">
        <nida-web-ecg-detail
          (point)="triggerPointStorage($event)"
          (range)="triggerRangeStorage($event)"
          [channel]="channel"
          [clickedPoints]="clickedPointStorage"
          [data]="restEcg[channel]"
          [detailMode]="detailMode"
          [intervals]="intervals"
          [selectedRangeArguments]="selectedRangeArguments"
          [visualRange]="visualRange"
          [zoomRange]="zoomStep['range']"
        >
        </nida-web-ecg-detail>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseBlock>
  <div class="loader-wrapper">
    <div>
      <nida-web-meddv-loader></nida-web-meddv-loader>
    </div>
  </div>
</ng-template>
