import { Injectable } from '@angular/core';
import { Findings } from '@nida-web/api/generic-interfaces/patient-management';
import { FindingMapping } from '../models/finding-mapping.model';
import { MappingConfigServable } from '../interfaces/mapping-config-servable.interface';
import { MappingFunctionsProviderService } from './mapping-functions-provider.service';
import { throwError } from 'rxjs';
import { Finding } from '../models/finding';

@Injectable({
  providedIn: 'root',
})
export class FindingMappingService {
  private findingMapping?: FindingMapping;

  constructor(
    private mappingConfigServable: MappingConfigServable,
    private mappingFunctionsProviderService: MappingFunctionsProviderService
  ) {
    this.mappingConfigServable.getMappingConfig().subscribe((newMappingConf) => {
      if (newMappingConf.findingMapping !== undefined) {
        this.findingMapping = newMappingConf.findingMapping;
      }
    });
  }

  public mapValues(data: Finding[]): Findings {
    const resultObj = new Findings();

    if (this.findingMapping === undefined) {
      throwError(() => new Error('Mapping not Loaded! Critical!'));
    } else {
      for (const item of data) {
        const mappingEntry = Object.entries(this.findingMapping).filter(
          ([_key, mapping]) => item['befund.beschreibung'] === mapping.sourceItem && item['befund.befund_art'] === mapping.sourceGroup
        );

        for (const entry of mappingEntry) {
          const [key, mapping] = entry;
          resultObj[key] = this.mapFindingItem(item, mapping);
        }
        /* else {
          console.log(
            'No mapping found for: ',
            item['befund.befund_art'],
            item['befund.beschreibung'],
            item['befund.wert_string'],
            item['befund.wert_int'],
            item['befund.wert_float'],
            item['befund.wert_datetime']
          );
        }*/
      }
    }
    return resultObj;
  }

  private mapFindingItem(item: Finding, mapping: any): any {
    let value;
    switch (mapping.sourceDataType) {
      case 'integer':
        value = item['befund.wert_int'];
        break;
      case 'float':
        value = item['befund.wert_float'];
        break;
      case 'datetime':
        value = item['befund.wert_datetime'];
        break;
      case 'string':
        value = item['befund.wert_string'];
        break;
      default:
        value = null;
    }
    return this.executeMappingFunction(mapping.mappingFunction, value);
  }

  private executeMappingFunction(mappingFunction: string, value: any): any {
    return this.mappingFunctionsProviderService['mapping' + mappingFunction](value);
  }
}
