import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ArchiveStoreService } from '../services/store/archive.store.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { PatientSettingsService } from '@nida-web/core';
import dayjs from 'dayjs';

@Component({
  selector: 'nida-web-patient-archive',
  templateUrl: './patient-archive.component.html',
  styleUrls: ['./patient-archive.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientArchiveComponent implements OnInit {
  @Input() archived: boolean;
  @Input() protocolId?: number;
  @Input() loaded: boolean;
  @Input() patientData: PatientView;

  public disabledArchive: boolean;
  public autoArchiveInHours: number;

  constructor(
    private archiveStoreService: ArchiveStoreService,
    private router: Router,
    private translocoService: TranslocoService,
    private patientSettingsService: PatientSettingsService
  ) {
    this.loaded = false;
  }

  ngOnInit() {
    this.patientSettingsService.getSettings().subscribe((config) => {
      this.autoArchiveInHours = config.autoArchiveInHours;

      this.disabledArchive = this.checkIfArchiveButtonIsDisabled();
    });
  }

  checkIfArchiveButtonIsDisabled(): boolean {
    if (!this.patientData.arrivalTime) return true;

    const diffHours = dayjs().diff(this.patientData.arrivalTime, 'hours');

    return diffHours >= this.autoArchiveInHours;
  }

  submitForm(): void {
    if (this.protocolId) {
      this.archiveStoreService.archivePatient(this.protocolId, this.archived).subscribe((archivingDone: boolean) => {
        this.archived = archivingDone;

        let message: string;
        let url: string;
        // if patient was moved/marked as archived we go back to current patients and push a message
        if (this.archived) {
          message = 'Patient successfully moved to archive';
          url = '/current-patients/overview';
        } else {
          message = 'Patient successfully moved to current patients';
          url = 'patients/overview';
        }
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([url]);
        });
        notify({
          message: this.translocoService.translate(message),
          type: 'Success',
          displayTime: 5000,
        });
      });
    }
  }
}
