import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { SharedUiModule } from '@nida-web/shared/ui';

@Component({
  selector: 'nida-web-page-not-found',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss'],
  standalone: true,
  imports: [TranslocoTranslationModule, DxButtonModule, SharedUiModule],
})
export class PageNotFoundComponent {
  constructor(private location: Location) {}

  navigateBack() {
    this.location.back();
  }
}
