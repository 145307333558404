/**
 * NIDA Attachments Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { DisinfectionProtocolPdf } from '../models/disinfectionProtocolPdf';
import { DisinfectionProtocolPdfList } from '../models/disinfectionProtocolPdfList';
import { ProtocolWithDisinfection } from '../models/protocolWithDisinfection';
import { ProtocolWithDisinfectionList } from '../models/protocolWithDisinfectionList';

@Injectable({
  providedIn: 'root',
})
export class DisinfectionProtocolService {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}attachments`;
    });
  }
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get the disinfection protocol pdf metadata of the given mission and the id
   * @param nidaId The mission id
   * @param id The id of the disinfection protocol pdf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDisinfectionProtocolPdf(
    nidaId: string,
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<DisinfectionProtocolPdf>;
  public getDisinfectionProtocolPdf(
    nidaId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<DisinfectionProtocolPdf>>;
  public getDisinfectionProtocolPdf(
    nidaId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<DisinfectionProtocolPdf>>;
  public getDisinfectionProtocolPdf(
    nidaId: string,
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getDisinfectionProtocolPdf.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getDisinfectionProtocolPdf.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<DisinfectionProtocolPdf>(
      `${this.basePath}/pdfs/disinfection-protocol/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get the disinfection protocol pdf blob of the given mission and the id
   * @param nidaId The mission id
   * @param id The id of the disinfection protocol pdf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDisinfectionProtocolPdfBlob(
    nidaId: string,
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'image/jpeg' | 'application/json' | 'text/plain' }
  ): Observable<Blob>;
  public getDisinfectionProtocolPdfBlob(
    nidaId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'image/jpeg' | 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Blob>>;
  public getDisinfectionProtocolPdfBlob(
    nidaId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'image/jpeg' | 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Blob>>;
  public getDisinfectionProtocolPdfBlob(
    nidaId: string,
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'image/jpeg' | 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getDisinfectionProtocolPdfBlob.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getDisinfectionProtocolPdfBlob.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['image/jpeg', 'application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get(
      `${this.basePath}/pdfs/disinfection-protocol/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(id))}/blob`,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets the disinfection protocol pdfs metadata of the given mission
   * @param nidaId The mission id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDisinfectionProtocolPdfList(
    nidaId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<DisinfectionProtocolPdfList>;
  public getDisinfectionProtocolPdfList(
    nidaId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<DisinfectionProtocolPdfList>>;
  public getDisinfectionProtocolPdfList(
    nidaId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<DisinfectionProtocolPdfList>>;
  public getDisinfectionProtocolPdfList(
    nidaId: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getDisinfectionProtocolPdfList.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<DisinfectionProtocolPdfList>(
      `${this.basePath}/pdfs/disinfection-protocol/${encodeURIComponent(String(nidaId))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   * Gets the protocols with disinfection. (Only accessible if the m.nida and m.nida.desinfprot modules are activated)
   * @param id The id of the protocol with disinfection
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolWithDisinfection(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<ProtocolWithDisinfection>;
  public getProtocolWithDisinfection(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<ProtocolWithDisinfection>>;
  public getProtocolWithDisinfection(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<ProtocolWithDisinfection>>;
  public getProtocolWithDisinfection(
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getProtocolWithDisinfection.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<ProtocolWithDisinfection>(`${this.basePath}/protocols/with-disinfection/${encodeURIComponent(String(id))}`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Gets the protocol list with disinfection. (Only accessible if the m.nida and m.nida.desinfprot modules are activated)
   * @param idAfter The id after which rows will be fetched (seek pagination)
   * @param idBefore The id before which rows will be fetched (seek pagination)
   * @param skip The number of queried rows to skip before returning results to the client (offset pagination)
   * @param take The number of elements to query (seek and offset pagination)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolWithDisinfectionList(
    idAfter?: number,
    idBefore?: number,
    skip?: number,
    take?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<ProtocolWithDisinfectionList>;
  public getProtocolWithDisinfectionList(
    idAfter?: number,
    idBefore?: number,
    skip?: number,
    take?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<ProtocolWithDisinfectionList>>;
  public getProtocolWithDisinfectionList(
    idAfter?: number,
    idBefore?: number,
    skip?: number,
    take?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<ProtocolWithDisinfectionList>>;
  public getProtocolWithDisinfectionList(
    idAfter?: number,
    idBefore?: number,
    skip?: number,
    take?: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (idAfter !== undefined && idAfter !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>idAfter, 'id_after');
    }
    if (idBefore !== undefined && idBefore !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>idBefore, 'id_before');
    }
    if (skip !== undefined && skip !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>skip, 'skip');
    }
    if (take !== undefined && take !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>take, 'take');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<ProtocolWithDisinfectionList>(`${this.basePath}/protocols/with-disinfection`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
