import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Findings,
  MissionData,
  Patient,
  PatientDetailServable,
  PatientListServable,
  PatientServable,
  PatientView,
  PatientViewList,
  PictureList,
  VitalData,
  VitalSignsServable,
} from '@nida-web/api/generic-interfaces/patient-management';
import { Location } from '@angular/common';
import { DateService, ModuleSettingsService } from '@nida-web/core';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { EcgListComponent } from '@nida-web/ecg-viewer';
import { PDFListComponent } from '@nida-web/pdf-viewer';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { PreregistrationVersion } from '../models/preregistration-version.enum';

@Component({
  selector: 'nida-web-patient-detail-archive-elk',
  templateUrl: '../patient-detail/patient-detail.component.html',
  styleUrls: ['../patient-detail/patient-detail.component.scss'],
})
export class PatientDetailArchiveElkComponent {
  public id: string;
  public nidaId: string;
  public protocolId?: number;
  public patientData?: PatientView;
  public emergencyRoomEnabled: boolean;
  public kisEnabled: boolean;
  public loaded: BehaviorSubject<boolean>;
  public detailData: Patient;
  public vitalData: ReplaySubject<VitalData>;
  public pictureList: PictureList;
  public arrivalIsInFuture: boolean;
  public archived: boolean;
  public findingData: Findings;
  public missionData: MissionData;
  public findingsNotYetLoaded: boolean;

  protected preregistrationVersion?: PreregistrationVersion;
  protected preregistrationVersionEnum = PreregistrationVersion;

  @ViewChild('ecgChild') ecgChild!: EcgListComponent;
  @ViewChild('pdfChild') pdfChild!: PDFListComponent;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected patientDetailServable: PatientDetailServable,
    protected location: Location,
    protected vitalSignsServable: VitalSignsServable,
    protected dateService: DateService,
    protected patientServable: PatientServable,
    protected sessionManagerService: SessionManagerService,
    protected moduleSettingsService: ModuleSettingsService,
    protected translocoService: TranslocoService,
    private patientListServable: PatientListServable
  ) {
    this.loaded = new BehaviorSubject<boolean>(false);
    this.nidaId = '';
    this.id = this.nidaId;
    this.emergencyRoomEnabled = false;
    this.kisEnabled = false;
    this.pictureList = [];
    this.arrivalIsInFuture = false;
    this.findingData = new Findings();
    this.missionData = new MissionData();
    this.findingsNotYetLoaded = true;
  }

  ngOnInit(): void {
    this.getId();
    this.checkPermissions();
  }

  getBasicData(): void {
    if (this.protocolId === undefined) {
      throw new Error('protocolId is undefined!');
    }
    this.patientListServable.getPatientList(undefined, this.protocolId).subscribe((patientViewList: PatientViewList) => {
      if (patientViewList.length > 0) {
        this.patientData = patientViewList[0];
        this.preregistrationVersion = patientViewList[0].prenotificationVersion;
      }
      if (this.patientData) {
        this.protocolId = this.patientData.protocolId;
        this.id = this.patientData.nidaId;
        if (!this.patientData.seen) {
          this.patientData.seen = true;
          this.patientListServable.setReadProtocol(this.id, this.protocolId).subscribe((result) => {
            if (!result) {
              throw new Error('Error on set Protocol read.');
            }
          });
        }
        this.archived = this.patientData.archived !== undefined ? this.patientData.archived : false;
        this.loadAllData();
      } else {
        this.showProtocolNotFoundErrorMessage();
        this.navigateBack();
      }
    });
  }

  loadAllData(): void {
    this.getAdditionalData();
    this.getPictures();
    this.checkIfArrivalIsInFuture();
    if (this.pdfChild && this.id.length > 0) {
      this.pdfChild.ngOnInit(this.id);
    }

    if (this.ecgChild && this.id.length > 0) {
      this.ecgChild.ngOnInit(this.id);
    }
    this.loaded.next(true);
  }

  getId(): void {
    const protocolId = this.route.snapshot.paramMap.get('id');
    if (protocolId) {
      const parsedID = parseInt(protocolId);
      if (!isNaN(parsedID)) {
        this.protocolId = parsedID;
        this.getBasicData();
      } else {
        this.showProtocolNotFoundErrorMessage();
        this.navigateBack();
      }
    }
  }

  navigateBack(): void {
    this.router.navigate(['patients/overview']);
  }

  showProtocolNotFoundErrorMessage() {
    notify({
      message: this.translocoService.translate('protocolNotFoundError'),
      type: 'Error',
      displayTime: 5000,
    });
  }

  private checkPermissions(): void {
    this.sessionManagerService.hasPermission(['nida.kis', 'nida.kis.map', 'nida.kis.unmap', 'klinik.kisimport']).subscribe((result) => {
      this.kisEnabled = result;
    });
  }

  getAdditionalData(): void {
    if (this.id === undefined) {
      throw new Error('Patient ID is undefined!');
    }
    if (this.protocolId === undefined) {
      throw new Error('Patient Protocol ID is undefined!');
    }

    this.patientServable.getPatientByProtocolId(this.protocolId).subscribe((response) => {
      this.detailData = response;
    });
    this.patientDetailServable.getDetailFinding(String(this.protocolId)).subscribe((response: Findings) => {
      this.findingData = response;
      this.findingsNotYetLoaded = false;
    });
    this.vitalSignsServable.getAfByProtocolId(this.protocolId).subscribe((response: any) => {
      this.vitalData = response;
    });
  }

  getPictures(): void {
    if (this.id === undefined) {
      throw new Error('Patient ID is undefined!');
    }

    this.patientDetailServable.getPictureList(this.id).subscribe((val: any) => {
      this.pictureList = val;
    });
  }

  checkIfArrivalIsInFuture(): void {
    if (this.patientData) {
      const arrivalTime = this.patientData.arrivalTime;
      const now = new Date();
      if (arrivalTime && arrivalTime > now) {
        this.arrivalIsInFuture = true;
      }
    }
  }
}
