<nida-web-site-wrapper [disableHeadline]="true">
  <div class="mb-3">
    <dx-button text="{{ 'Back' | transloco }}" icon="back" (click)="navigateBack()"></dx-button>
  </div>
  <ng-container *ngIf="patientData">
    <div class="row mb-4" style="margin-left: -12px; margin-right: -12px">
      <div
        class="col-12 col-lg-12 col-xl-9 basic-flex-container basic-direction-column mb-4 mb-xl-0"
        style="padding-right: 12px; padding-left: 12px"
      >
        <div class="row percent-100-height" style="margin-left: -12px; margin-right: -12px">
          <div class="basic-flex-container basic-direction-column col-md-12 col-lg-8" style="padding-right: 12px; padding-left: 12px">
            <div class="basic-flex-column row mb-4">
              <div class="col-12">
                <nida-web-card [height100]="true" headline="Pre-registration information" class="percent-100-height">
                  <ng-container *ngIf="findingsNotYetLoaded; else elseBlock">
                    <div style="margin: auto; width: 5%">
                      <nida-web-meddv-loader></nida-web-meddv-loader>
                    </div>
                  </ng-container>
                  <ng-template #elseBlock>
                    @if (this.preregistrationVersion === preregistrationVersionEnum.VERSION_2) {
                    <nida-web-patient-detail-preregistration-information-version-2
                      [patientData]="patientData"
                      [missionData]="missionData"
                      [findingData]="findingData"
                      [arrivalIsInFuture]="arrivalIsInFuture"
                    >
                    </nida-web-patient-detail-preregistration-information-version-2>
                    } @else {
                    <nida-web-patient-detail-preregistration-information
                      [patientData]="patientData"
                      [missionData]="missionData"
                      [findingData]="findingData"
                      [arrivalIsInFuture]="arrivalIsInFuture"
                    ></nida-web-patient-detail-preregistration-information>
                    }
                  </ng-template>
                </nida-web-card>
              </div>
            </div>
            <div class="basic-flex-column row">
              <div class="col-12 mb-4 mb-lg-0">
                <nida-web-card [height100]="true" headline="Vital data" class="percent-100-height">
                  <nida-web-missions-vital-data
                    *ngIf="loaded.value"
                    [protocolId]="protocolId"
                    [vitalObservable]="vitalData"
                  ></nida-web-missions-vital-data>
                </nida-web-card>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-4" style="padding-right: 12px; padding-left: 12px">
            <div class="row percent-100-height">
              <div class="col-md-6 col-lg-12 mb-4">
                <nida-web-card [height100]="true" headline="Media" class="percent-100-height">
                  <nida-web-image-layout-viewer
                    *ngIf="loaded.value && pictureList"
                    [mediaList]="pictureList"
                    [thumbnailHeight]="43"
                  ></nida-web-image-layout-viewer>
                </nida-web-card>
              </div>
              <div class="col-md-6 col-lg-12 mb-4">
                <ng-template #ecgButtonTemplate>
                  <dx-button
                    *ngIf="patientData?.c3_mission_id && loaded.value"
                    [queryParams]="
                      patientData && patientData.c3_mission_id && patientData.c3_mission_serial_dim
                        ? {
                            missionid: patientData.c3_mission_id,
                            dimserial: patientData.c3_mission_serial_dim
                          }
                        : null
                    "
                    [routerLink]="'corpuls'"
                    type="default"
                    text="{{ patientData && patientData?.c3_mission_id ? ('Live ECG' | transloco) : ('ECG inactive' | transloco) }}"
                  >
                  </dx-button>
                </ng-template>
                <nida-web-card [height100]="true" headline="ECG" [buttonTemplate]="ecgButtonTemplate" class="percent-100-height">
                  <nida-web-ecg-list #ecgChild [nidaId]="this.id" [protocolId]="this.protocolId"></nida-web-ecg-list>
                </nida-web-card>
              </div>
              <div class="col-md-6 col-lg-12 mb-0">
                <nida-web-card [height100]="true" headline="Protocols" class="percent-100-height">
                  <nida-web-pdf-list #pdfChild [nidaId]="this.id" [disableDescriptionColumn]="true" [pageSize]="5"></nida-web-pdf-list>
                </nida-web-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 col-xl-3 basic-flex-container basic-direction-column" style="padding-right: 12px; padding-left: 12px">
        <div class="row percent-100-height" style="margin-left: -12px; margin-right: -12px">
          <div class="col-md-6 col-xl-12 mb-4 mb-lg-0 mb-xl-4" style="padding-right: 12px; padding-left: 12px">
            <nida-web-patient-detail-rescue-unit [loaded]="loaded" [patientData]="patientData"></nida-web-patient-detail-rescue-unit>
          </div>

          <div class="col-md-6 col-xl-12 mb-4 mb-lg-0 mb-xl-4" style="padding-right: 12px; padding-left: 12px">
            <nida-web-patient-personal-data
              [detailData]="detailData"
              [loaded]="loaded.value"
              [patientData]="patientData"
            ></nida-web-patient-personal-data>
          </div>
          <div class="col-md-6 col-xl-12" style="padding-right: 12px; padding-left: 12px">
            <nida-web-patient-insurance *ngIf="patientData" [protocolId]="patientData.protocolId"></nida-web-patient-insurance>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-left: -12px; margin-right: -12px">
      <div
        class="mb-4 col-12 col-sm-6 col-lg-4 col-xl-3 basic-flex-container basic-direction-column"
        style="padding-right: 12px; padding-left: 12px"
      >
        <nida-web-card [height100]="true" headline="Archive" class="percent-100-height">
          <nida-web-patient-archive
            *ngIf="!findingsNotYetLoaded && patientData"
            [protocolId]="patientData.protocolId"
            [archived]="!patientData.archived"
            [patientData]="patientData"
            [loaded]="loaded.value"
          ></nida-web-patient-archive>
        </nida-web-card>
      </div>
      <div
        *ngIf="kisEnabled"
        class="mb-4 col-12 col-sm-6 col-lg-4 col-xl-3 basic-flex-container basic-direction-column"
        style="padding-right: 12px; padding-left: 12px"
      >
        <nida-web-card [height100]="true" headline="His import" class="percent-100-height">
          <nida-web-kis-tile [detailData]="detailData" [loaded]="loaded.value" [patientData]="patientData" class="mr-3"></nida-web-kis-tile>
        </nida-web-card>
      </div>
      <div
        *ngIf="this.emergencyRoomEnabled"
        class="mb-4 col-12 col-sm-6 col-lg-4 col-xl-3 basic-flex-container basic-direction-column"
        style="padding-right: 12px; padding-left: 12px"
      >
        <nida-web-card headline="Emergencyroom">
          <nida-web-er-tile *ngIf="patientData" [nidaId]="patientData.nidaId" [protocolId]="patientData.protocolId" class="mr-3">
          </nida-web-er-tile>
        </nida-web-card>
      </div>
    </div>
  </ng-container>
</nida-web-site-wrapper>
