import { Injectable } from '@angular/core';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { ColumnConfigServable } from '../interfaces/column-config-servable.interface';
import { ColumnEntry } from '../models/column-entry.model';
import { BehaviorSubject } from 'rxjs';
import { PatientColumn } from '../models/patient-column.model';

@Injectable({
  providedIn: 'root',
})
export class ColumnProviderService {
  protected columnEntriesByKey: Map<string, ColumnEntry[]>;
  protected configColumns: string;
  protected columnTypes: PatientColumn[] = [
    {
      type: 'status',
      dataType: 'string',
      dxTemplate: 'statusColor',
      allowReordering: false,
      allowResizing: false,
      visibility: true,
      allowFilter: false,
      allowHeaderFiltering: false,
    },
    {
      // age
      type: 'simpleText',
      dataType: 'string',
      dxTemplate: undefined,
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      type: 'simpleNumber',
      dataType: 'number',
      dxTemplate: undefined,
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      type: 'diagnosis',
      dataType: 'string',
      dxTemplate: 'diagnosis',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      type: 'datetime',
      dataType: 'date',
      dxTemplate: 'arrivalTime',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
      filterOperations: ['=', 'between'],
    },
    {
      type: 'datetimeSimple',
      dataType: 'date',
      dxTemplate: 'datetime',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
    },
    {
      type: 'time',
      dataType: 'date',
      dxTemplate: 'time',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
    },
    {
      type: 'date',
      dataType: 'date',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
      allowHeaderFiltering: false,
      filterOperations: ['=', 'between'],
    },
    {
      type: 'duration',
      dataType: 'date',
      dxTemplate: 'arrivalDuration',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      allowHeaderFiltering: false,
      filterOperations: ['=', 'between'],
    },
    {
      type: 'gender',
      dataType: 'string',
      dxTemplate: 'gender',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: false,
      allowHeaderFiltering: true,
    },
    {
      type: 'circulation',
      dataType: 'boolean',
      dxTemplate: 'circulation',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      calculateSortValue: this.calculateSortCirculation,
      allowFilter: true,
      allowHeaderFiltering: false,
      falseText: 'instabil',
      trueText: 'stabil',
    },
    {
      type: 'consciousness',
      dataType: 'boolean',
      dxTemplate: 'consciousness',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      type: 'breathingAndVentilation',
      dataType: 'string',
      dxTemplate: 'breathingAndVentilation',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      type: 'gcs',
      dataType: 'number',
      dxTemplate: 'gcs',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      type: 'yesno',
      dataType: 'boolean',
      dxTemplate: 'yesNoEmpty',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      allowHeaderFiltering: false,
      falseText: 'Nein',
      trueText: 'Ja',
    },
    {
      type: 'iStatus',
      dataType: 'string',
      dxTemplate: 'iStatus',
      allowReordering: true,
      allowResizing: true,
      visibility: true,
      allowFilter: true,
      allowHeaderFiltering: false,
    },
    {
      // Voranmeldung
      type: 'option',
      dataType: 'string',
      dxTemplate: 'rowStatus',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowExporting: false,
      allowFilter: false,
    },
    {
      type: 'attachments',
      dataType: 'string',
      dxTemplate: 'attachments',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowExporting: true,
      calculateSortValue: this.calculateSortAttachments,
      allowFilter: false,
    },
    {
      type: 'protocolDate',
      dataType: 'date',
      dxTemplate: 'date',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      filterOperations: ['=', 'between'],
    },
    {
      type: 'options',
      dataType: 'string',
      dxTemplate: 'options',
      visibility: true,
      allowReordering: false,
      allowResizing: true,
      allowFilter: true,
    },
    {
      type: 'link',
      dataType: 'string',
      dxTemplate: 'hl7ExportAt',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'evm',
      dataType: 'number',
      dxTemplate: 'evmImage',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'zek',
      dataType: 'number',
      dxTemplate: 'zekImage',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'pdf',
      dataType: 'number',
      dxTemplate: 'pdfImage',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'ecg',
      dataType: 'number',
      dxTemplate: 'ecgImage',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'picture',
      dataType: 'number',
      dxTemplate: 'pictureImage',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'signature',
      dataType: 'string',
      dxTemplate: 'signature',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: false,
    },
    {
      type: 'protocolStatus',
      dataType: 'string',
      dxTemplate: 'protocolStatus',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
    },
    {
      type: 'billingStatus',
      dataType: 'number',
      dxTemplate: 'billingStatus',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      allowFilter: true,
      filterOperations: ['=', '<>'],
    },
  ];
  // KIS
  protected kisColumnEntriesByKey: Map<string, ColumnEntry[]>;
  public kisColumnsLoaded: BehaviorSubject<boolean>;
  protected columnStore: ColumnEntry[] = [
    {
      name: 'patientFirstName',
      dxTemplate: null,
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'First name',
      dataType: 'string',
      minWidth: 130,
      displayPriority: 12,
    },
    {
      name: 'patientLastName',
      dxTemplate: null,
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'Last name',
      dataType: 'string',
      minWidth: 130,
      displayPriority: 12,
    },
    {
      name: 'patientBirthdate',
      dxTemplate: 'date',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'Birthday',
      dataType: 'date',
      minWidth: 100,
      displayPriority: 12,
    },
    {
      name: 'insuredNumber',
      dxTemplate: null,
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'Insurance membership number',
      filterValue: '',
      dataType: 'string',
      minWidth: 300,
      displayPriority: 12,
    },
    {
      name: 'insuranceValid',
      dxTemplate: null,
      visibility: false,
      allowReordering: true,
      allowResizing: true,
      caption: 'Insurance valid',
      filterValue: '',
      dataType: 'string',
      minWidth: 300,
      displayPriority: 12,
    },
    {
      name: 'insuredLastName',
      dxTemplate: null,
      visibility: false,
      allowReordering: true,
      allowResizing: true,
      caption: 'Insurance member last name',
      filterValue: '',
      dataType: 'string',
      minWidth: 300,
      displayPriority: 12,
    },
    {
      name: 'insuredFirstName',
      dxTemplate: null,
      visibility: false,
      allowReordering: true,
      allowResizing: true,
      caption: 'Insurance member first name',
      filterValue: '',
      dataType: 'string',
      minWidth: 300,
      displayPriority: 12,
    },
    {
      name: 'insuredBirthdate',
      dxTemplate: null,
      visibility: false,
      allowReordering: true,
      allowResizing: true,
      caption: 'Insurance member birthdate',
      filterValue: '',
      dataType: 'string',
      minWidth: 300,
      displayPriority: 12,
    },
    {
      name: 'CaseNumber',
      dxTemplate: null,
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'Case number',
      dataType: 'string',
      minWidth: 130,
      displayPriority: 130,
    },
    {
      name: 'receivedAt',
      dxTemplate: 'timestamp',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'His update time',
      dataType: 'datetime',
      minWidth: 130,
      displayPriority: 100,
    },
    {
      name: 'messageType',
      dxTemplate: 'adtMessage',
      visibility: true,
      allowReordering: true,
      allowResizing: true,
      caption: 'Message type',
      dataType: 'string',
      minWidth: 130,
      displayPriority: 110,
    },
  ];

  constructor(protected columnConfigServable: ColumnConfigServable) {
    this.columnEntriesByKey = new Map<string, ColumnEntry[]>();
    // KIS
    this.kisColumnEntriesByKey = new Map<string, ColumnEntry[]>();
    this.kisColumnsLoaded = new BehaviorSubject(false);
  }

  public buildTableColumns(columnConfigName: string, kis = false) {
    const kisColumnEntriesForKey: ColumnEntry[] = [];
    const columnEntriesForKey: ColumnEntry[] = [];

    this.columnConfigServable.getColumnConfig().subscribe((mainConfig) => {
      if (mainConfig === undefined || mainConfig[columnConfigName] === undefined) {
        throw new Error('KIS Column configuration missing!!!');
      }

      if (kis) {
        for (const entry of mainConfig[columnConfigName]) {
          const storeColumn = this.columnStore.find((column) => column.name === entry.name);
          if (storeColumn) {
            if (entry.width !== undefined && entry.width !== '') {
              storeColumn.width = entry.width;
            }
            storeColumn.minWidth = entry.minWidth;
            storeColumn.displayPriority = entry.displayPriority;
            // storeColumn.allowReordering = entry.allowReordering ? entry.allowReordering : false;
            kisColumnEntriesForKey.push(storeColumn);
          }
        }
        this.columnEntriesByKey.set(columnConfigName, kisColumnEntriesForKey);
        this.kisColumnsLoaded.next(true);
      } else {
        for (const entry of mainConfig[columnConfigName]) {
          // NEW CODE
          // const storeColumn = this.columnStore.find((column) => column.type === entry.type);
          // if (storeColumn) {
          //   if (entry.width !== undefined && entry.width !== '') {
          //     storeColumn.width = entry.width;
          //   }
          //
          //   storeColumn.minWidth = entry.minWidth;
          //   storeColumn.hidingPriority = entry.hidingPriority;
          //   storeColumn.caption = entry.caption;
          //   storeColumn.dataField = entry.dataField;
          //   storeColumn.format = entry.format;
          //   storeColumn.headerFilterSource = entry.headerFilterSource;
          //   storeColumn.allowFilter = entry.allowFilter !== undefined ? entry.allowFilter : storeColumn.allowFilter;
          //   storeColumn.allowHeaderFiltering =
          //     entry.allowHeaderFiltering !== undefined ? entry.allowHeaderFiltering : storeColumn.allowHeaderFiltering;
          //   storeColumn.allowSorting = entry.allowSorting !== undefined ? entry.allowSorting : storeColumn.allowSorting;
          //
          //   columnEntriesForKey.push(storeColumn);
          // }

          // ORIGINAL CODE
          columnEntriesForKey.push(Object.assign({}, this.columnTypes.filter((element) => element.type === entry.type)[0]));
          if (entry.width !== undefined && entry.width !== '') {
            columnEntriesForKey[columnEntriesForKey.length - 1].width = entry.width;
          }
          columnEntriesForKey[columnEntriesForKey.length - 1].minWidth = entry.minWidth;
          columnEntriesForKey[columnEntriesForKey.length - 1].hidingPriority = entry.hidingPriority;
          columnEntriesForKey[columnEntriesForKey.length - 1].caption = entry.caption;
          columnEntriesForKey[columnEntriesForKey.length - 1].dataField = entry.dataField;
          columnEntriesForKey[columnEntriesForKey.length - 1].format = entry.format;
          columnEntriesForKey[columnEntriesForKey.length - 1].headerFilterSource = entry.headerFilterSource;
          columnEntriesForKey[columnEntriesForKey.length - 1].allowFilter =
            entry.allowFilter !== undefined ? entry.allowFilter : columnEntriesForKey[columnEntriesForKey.length - 1].allowFilter;
          columnEntriesForKey[columnEntriesForKey.length - 1].allowHeaderFiltering =
            entry.allowHeaderFiltering !== undefined
              ? entry.allowHeaderFiltering
              : columnEntriesForKey[columnEntriesForKey.length - 1].allowHeaderFiltering;
          columnEntriesForKey[columnEntriesForKey.length - 1].allowSorting =
            entry.allowSorting !== undefined ? entry.allowSorting : columnEntriesForKey[columnEntriesForKey.length - 1].allowSorting;
        }
        this.columnEntriesByKey.set(columnConfigName, columnEntriesForKey);
      }
    });
  }

  public getTableColumns(configName: string): ColumnEntry[] {
    const columnEntries = this.columnEntriesByKey.get(configName);
    return columnEntries ? columnEntries : [];
  }

  public calculateSortAttachments(rowData: PatientView): number {
    // init with 0 - no data means at the end or at the top of the list/column
    let position = 0;

    enum Attachments {
      picture = 1,
      pdf = 2,
      video = 4,
      ekg = 8,
    }

    if (rowData.attachments && rowData.attachments.picture) {
      position += Attachments.picture;
    }
    if (rowData.attachments && rowData.attachments.pdf) {
      position += Attachments.pdf;
    }
    if (rowData.attachments && rowData.attachments.video) {
      position += Attachments.video;
    }
    if (rowData.attachments && rowData.attachments.ekg) {
      position += Attachments.ekg;
    }

    return position;
  }

  /**
   * Sort algorithm to prevent/solve NIDAserver error
   *
   * NIDAsever provides also values like "Radialispuls tastbar" of "False" to
   * prevent list from ordering those values we sadly need this method
   *
   * @param rowData
   */
  public calculateSortCirculation(rowData: PatientView): number {
    // init with 0 - no data means at the end or at the top of the list/column
    let position = 0;

    if (rowData.circulation && rowData.circulation === 'stabil') {
      position = 1;
    } else if (rowData.circulation && rowData.circulation === 'instabil') {
      position = 2;
    }

    return position;
  }
}
