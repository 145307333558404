<div class="flex-alert-column alert alert-{{ priority !== undefined && priority !== null ? priority : 0 }}">
  <div class="flex-container" [innerHTML]="'<p></p>' + displayValue"></div>
  <div class="flex-alert-row border-alert-top" *ngIf="footerText || showTrashCan">
    <div *ngIf="footerText" class="flex-container align-self-start">
      {{ footerText }}
    </div>
    <div *ngIf="showTrashCan" class="ml-2 flex-container">
      <a class="mark-on-hover" (click)="onDeleteClick()"><i class="dx-icon dx-icon-trash"></i></a>
    </div>
  </div>
</div>
