import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GroupList, GroupServable, UserServable } from '@nida-web/api/generic-interfaces/user-management';
import { SessionInformation, SessionManagerService } from '@nida-web/api/rest/authentication';
import { firstValueFrom, forkJoin, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'nida-web-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserManagementComponent implements OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  public currentUser?: SessionInformation;
  public groups?: GroupList;

  public tableData: DataSource;

  constructor(
    private sessionManagerService: SessionManagerService,
    private userService: UserServable,
    private groupService: GroupServable,
    private translocoService: TranslocoService
  ) {
    this.tableData = new DataSource({
      key: 'id',
      load: () => {
        return firstValueFrom(
          forkJoin({
            currentUser: this.sessionManagerService.getSessionInformation().pipe(take(1)),
            personal: this.sessionManagerService.getSessionInformation().pipe(
              take(1),
              switchMap((sessionInformation) => {
                return this.userService.getAllUsers(sessionInformation.tenantId).pipe(take(1));
              }),
              takeUntil(this.unsubscribe$)
            ),
            groups: this.groupService.getAllGroups().pipe(take(1)),
          }).pipe(
            map(({ currentUser, personal, groups }) => {
              this.currentUser = currentUser;
              this.groups = groups;

              return personal;
            })
          )
        );
      },
      remove: (idKey) => {
        return firstValueFrom(
          this.userService.deleteUser(idKey).pipe(
            tap({
              error: () => {
                notify({
                  message: this.translocoService.translate('unknownServerError'),
                  type: 'Error',
                  displayTime: 5000,
                });
              },
              complete: () => {
                notify({
                  message: this.translocoService.translate('saveSuccessfully'),
                  type: 'Success',
                  displayTime: 5000,
                });
              },
            }),
            map(() => {
              return;
            }),
            takeUntil(this.unsubscribe$)
          )
        );
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
