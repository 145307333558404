import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ItemStatus, PDFElement, PDFTypes } from '@nida-web/api/generic-interfaces/attachments';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { forkJoin, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import {
  ApiPDFStatus,
  AttachmentsECGService,
  AttachmentsV2Service,
  DisinfectionProtocolPdfList,
  DisinfectionProtocolService,
  ECGPdfList,
  PDFItemMappingService,
  PdfList,
  RoutineDisinfection2PdfList,
  RoutineDisinfection2Service,
  AttachmentsPatientClarificationService,
  PatientClarificationPdfList,
} from '@nida-web/api/rest/attachments';
import { AvailableEndpointsStoreService } from '@nida-web/shared/feature';
import { TranslocoService } from '@jsverse/transloco';
import { RowClickEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'nida-web-pdf-list',
  templateUrl: './pdf-list.component.html',
  styleUrls: ['./pdf-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PDFListComponent implements OnInit {
  @Input() public pageSize: number;
  @Input() public disableDescriptionColumn: boolean;
  @Input() public nidaId: string;
  @Input() public protocolId: number;
  @Input() public download: boolean;
  // if true, we need to request a reason for access to the ProtocolComplete pdf
  @Input() public allowedToRequestPatientData: boolean;
  @Input() public allowedDisplayAddress: boolean;

  public pdfList: Array<PDFElement>;
  public currentBlob: Blob;
  public downloadFileName: string;
  public popupTitle: string;
  public showPopup = false;
  public showRequestPdfPopup = false;
  private documentId: number;
  private pdfStatus: ApiPDFStatus;
  private eventData: PDFElement;

  constructor(
    private attachmentsServiceV2: AttachmentsV2Service,
    private patientsClarificationService: AttachmentsPatientClarificationService,
    private pdfItemMappingService: PDFItemMappingService,
    protected sessionManagerService: SessionManagerService,
    private disinfectionProtocolService: DisinfectionProtocolService,
    private routineDisinfection2Service: RoutineDisinfection2Service,
    private attachmentsEcgService: AttachmentsECGService,
    private availableEndpointsStoreService: AvailableEndpointsStoreService,
    public transloco: TranslocoService
  ) {}

  ngOnInit(nidaId?: string) {
    this.getData(nidaId).subscribe((r) => (this.pdfList = r));
    this.downloadFileName = 'document.pdf';
  }

  getData(nidaId?: string): Observable<Array<PDFElement>> {
    if (nidaId && !this.nidaId) {
      this.nidaId = nidaId;
    }
    if (this.nidaId || nidaId) {
      return forkJoin({
        pdfStatusList: this.attachmentsServiceV2
          .getPdfsByNidaId(this.nidaId)
          .pipe(map((result: PdfList) => this.pdfItemMappingService.mapPDFEntries(result))),
        pdfDisinfectionList: this.getPDFDisinfectionProtocols(),
        pdfRoutineDisinfectionList: this.getPDFRoutineDisinfectionProtocols(),
        pdfEcgList: this.getPDFEcgProtocols(),
        pdfPatientClarificationList: this.getPatientClarification(),
      }).pipe(
        map((response) => [
          ...response.pdfStatusList,
          ...response.pdfDisinfectionList,
          ...response.pdfRoutineDisinfectionList,
          ...response.pdfEcgList,
          ...response.pdfPatientClarificationList,
        ])
      );
    } else {
      return of([]);
    }
  }

  getPDFEcgProtocols(): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.dekg']).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.availableEndpointsStoreService
            .getEndpoints()
            .pipe(
              map((endpoints) => {
                return AvailableEndpointsStoreService.endpointsContained(endpoints, [
                  {
                    path: '/attachments/ecg/pdfs/{nidaId}',
                    methods: ['get'],
                  },
                ]);
              })
            )
            .pipe(
              switchMap((hasEndpoint) => {
                if (hasEndpoint) {
                  return this.attachmentsEcgService
                    .getECGPdfsByNidaId(this.nidaId)
                    .pipe(map((result: ECGPdfList) => this.pdfItemMappingService.mapEcgEntries(result)));
                } else {
                  return of([]);
                }
              })
            );
        } else {
          return of([]);
        }
      })
    );
  }

  getPatientClarification(): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['aufklaerung.protokollliste']).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.availableEndpointsStoreService
            .getEndpoints()
            .pipe(
              map((endpoints) => {
                return AvailableEndpointsStoreService.endpointsContained(endpoints, [
                  {
                    path: '/attachments/patient-clarification/pdfs/{nidaId}',
                    methods: ['get'],
                  },
                ]);
              })
            )
            .pipe(
              switchMap((hasEndpoint) => {
                if (hasEndpoint) {
                  return this.patientsClarificationService
                    .getPatientClarificationPdfsByNidaId(this.nidaId)
                    .pipe(map((result: PatientClarificationPdfList) => this.pdfItemMappingService.mapClarificationEntries(result)));
                } else {
                  return of([]);
                }
              })
            );
        } else {
          return of([]);
        }
      })
    );
  }

  getPDFDisinfectionProtocols(): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.pdf.desinfektionsprotokoll']).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.availableEndpointsStoreService
            .getEndpoints()
            .pipe(
              map((endpoints) => {
                return AvailableEndpointsStoreService.endpointsContained(endpoints, [
                  {
                    path: '/attachments/pdfs/disinfection-protocol/{nidaId}',
                    methods: ['get'],
                  },
                ]);
              })
            )
            .pipe(
              switchMap((hasEndpoint) => {
                if (hasEndpoint) {
                  return this.disinfectionProtocolService.getDisinfectionProtocolPdfList(this.nidaId).pipe(
                    map((result: DisinfectionProtocolPdfList) => this.pdfItemMappingService.mapDisinfectionEntries(result)),
                    mergeMap((list4) => this.filterDisinfectionProtocolByPermission(list4))
                  );
                } else {
                  return of([]);
                }
              })
            );
        } else {
          return of([]);
        }
      })
    );
  }

  getPDFRoutineDisinfectionProtocols(): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.pdf.desinfektionsprotokoll']).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.availableEndpointsStoreService
            .getEndpoints()
            .pipe(
              map((endpoints) => {
                return AvailableEndpointsStoreService.endpointsContained(endpoints, [
                  {
                    path: '/attachments/pdfs/routine-disinfection2/{nidaId}',
                    methods: ['get'],
                  },
                ]);
              })
            )
            .pipe(
              switchMap((hasEndpoint) => {
                if (hasEndpoint) {
                  return this.routineDisinfection2Service.getRoutineDisinfection2PdfList(this.nidaId).pipe(
                    map((result: RoutineDisinfection2PdfList) => this.pdfItemMappingService.mapDisinfectionEntries(result)),
                    mergeMap((list4) => this.filterDisinfectionProtocolByPermission(list4))
                  );
                } else {
                  return of([]);
                }
              })
            );
        } else {
          return of([]);
        }
      })
    );
  }

  filterDisinfectionProtocolByPermission(list: PDFElement[]): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.pdf.desinfektionsprotokoll']).pipe(
      map((hasPermission) => {
        return !hasPermission ? list.filter((pdf) => !(pdf.pdfType === PDFTypes.DisinfectionProtocol)) : list;
      })
    );
  }

  onRowClick($event: RowClickEvent) {
    const eventData: PDFElement = $event.data;
    this.eventData = eventData;
    if (eventData.pdfType === PDFTypes.DisinfectionProtocol) {
      if (eventData.nidaId && eventData.id) {
        this.disinfectionProtocolService.getDisinfectionProtocolPdfBlob(eventData.nidaId, eventData.id).subscribe((pdf: Blob) => {
          this.currentBlob = pdf || new Blob();
          if (!eventData.name) {
            const timeStamp = eventData.timeStamp ? new Date(eventData.timeStamp) : undefined;
            this.downloadFileName = this.generateDownloadFileName(this.transloco.translate('Disinfection protocol'), timeStamp);
          } else {
            this.downloadFileName = eventData.name;
          }

          this.popupTitle = this.downloadFileName;
          this.showPopup = true;
        });
      }
    } else if (eventData.pdfType === PDFTypes.RoutineDisinfectionProtocol) {
      if (eventData.nidaId && eventData.id) {
        console.log('JKHKJHÖKJHKJH');
        this.routineDisinfection2Service.getRoutineDisinfection2PdfBlob(eventData.nidaId, eventData.id).subscribe((pdf: Blob) => {
          this.currentBlob = pdf || new Blob();
          if (!eventData.name) {
            const timeStamp = eventData.timeStamp ? new Date(eventData.timeStamp) : undefined;
            this.downloadFileName = this.generateDownloadFileName(this.transloco.translate('routineDisinfectionProtocol'), timeStamp);
          } else {
            this.downloadFileName = eventData.name;
          }

          this.popupTitle = this.downloadFileName;
          this.showPopup = true;
        });
      }
    } else if (eventData.pdfType === PDFTypes.ECG) {
      if (eventData.nidaId && eventData.id) {
        this.attachmentsEcgService.getECGPdfByDocumentId(eventData.nidaId, eventData.id).subscribe((pdf: Blob) => {
          this.currentBlob = pdf || new Blob();
          if (!eventData.name) {
            const timeStamp = eventData.timeStamp ? new Date(eventData.timeStamp) : undefined;
            this.downloadFileName = this.generateDownloadFileName(this.transloco.translate('Ecg'), timeStamp);
          } else {
            this.downloadFileName = eventData.name;
          }

          this.popupTitle = this.downloadFileName;
          this.showPopup = true;
        });
      }
    } else if (eventData.pdfType === PDFTypes.PatientClarification) {
      if (eventData.nidaId && eventData.id) {
        this.patientsClarificationService.getPatientClarificationPdfByDocumentId(eventData.nidaId, eventData.id).subscribe((pdf: Blob) => {
          this.currentBlob = pdf || new Blob();
          if (!eventData.name) {
            const timeStamp = eventData.timeStamp ? new Date(eventData.timeStamp) : undefined;
            this.downloadFileName = this.generateDownloadFileName(this.transloco.translate('Patient Clarification'), timeStamp);
          } else {
            this.downloadFileName = eventData.name;
          }

          this.popupTitle = this.downloadFileName;
          this.showPopup = true;
        });
      }
    } else {
      if (eventData && eventData.nidaId && eventData.id) {
        const pdfStatus: ApiPDFStatus = {
          printStatus: ItemStatus.READ,
        };
        this.pdfStatus = pdfStatus;
        console.log(
          this.allowedToRequestPatientData,
          !this.allowedDisplayAddress,
          eventData.pdfType,
          eventData.pdfType === PDFTypes.ProtocolComplete
        );
        if (this.allowedToRequestPatientData && !this.allowedDisplayAddress && eventData.pdfType === PDFTypes.ProtocolComplete) {
          this.documentId = eventData.id;
          this.showRequestPdfPopup = true;
        } else {
          this.attachmentsServiceV2.patchPDFStatus(pdfStatus, eventData.nidaId, eventData.id).subscribe(() => {
            if (eventData.nidaId && eventData.id) {
              this.attachmentsServiceV2.getPdfByDocumentId(eventData.nidaId, eventData.id).subscribe((pdfBlob: Blob) => {
                this.currentBlob = pdfBlob || new Blob();

                if (!eventData.name) {
                  const timeStamp = eventData.timeStamp ? new Date(eventData.timeStamp) : undefined;
                  this.downloadFileName = this.generateDownloadFileName('DIVI_Protocol', timeStamp);
                } else {
                  this.downloadFileName = eventData.name;
                }

                this.popupTitle = this.downloadFileName;
                this.showPopup = true;

                this.pdfList.map((pdfListItem: PDFElement) => {
                  if (eventData && eventData.id) {
                    if (pdfListItem.id === eventData.id) {
                      pdfListItem.status = this.transloco.translate('Read');
                    }
                  }
                  return pdfListItem;
                });
              });
            }
          });
        }
      }
    }
  }

  generateDownloadFileName(fileName: string, date?: Date): string {
    let name = fileName;
    if (date) {
      name = this.formatDate(date) + '_' + fileName;
    }
    return name;
  }

  /**
   * returns Date in format yyyy-mm-dd
   * @param date
   */
  formatDate(date: Date): string {
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return newDate.toISOString().split('T')[0];
  }

  getPdfWithReason(reason: string): void {
    if (this.eventData.nidaId && this.eventData.id) {
      this.attachmentsServiceV2.patchPDFStatus(this.pdfStatus, this.eventData.nidaId, this.eventData.id).subscribe(() => {
        this.attachmentsServiceV2
          .getPdfByDocumentIdWithAccessReason(this.nidaId, this.documentId, {
            protocolId: this.protocolId,
            description: reason,
          })
          .subscribe((pdfBlob) => {
            this.currentBlob = pdfBlob ? pdfBlob : new Blob();
            const timeStamp = new Date();
            this.downloadFileName = this.generateDownloadFileName('DIVI_Protocol', timeStamp);

            this.popupTitle = this.downloadFileName;
            this.showPopup = true;
          });
      });
    }
  }
}
