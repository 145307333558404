<div class="basic-background-lightgrey basic-padding-10">
  <div class="basic-padding mb-3">
    <dx-button text="{{ 'Back' | transloco }}" icon="back" (click)="triggerNavigation()"></dx-button>
  </div>

  <div class="basic-display-flex">
    <div>
      <div class="basic-page-top-text">{{ 'Resting ecg' | transloco }}</div>
    </div>
  </div>

  <div class="standard-tile basic-padding-10 basic-margin-10">
    <nida-web-ecg-overview-head [nidaId]="this.nidaId" [timestamp]="this.timestamp"></nida-web-ecg-overview-head>
  </div>

  <div class="standard-tile basic-padding-10 basic-margin-10">
    <nida-web-ecg-overview-channels
      (chosenChannels)="triggerChosenChannels($event)"
      [ecgId]="this.ecgId"
      [nidaId]="this.nidaId"
    ></nida-web-ecg-overview-channels>
  </div>

  <div *ngIf="chosenChannels.length > 0" class="standard-tile basic-padding-10 basic-margin-10">
    <nida-web-ecg-overview-detail
      [chosenChannels]="chosenChannels"
      [ecgId]="ecgId"
      [nidaId]="nidaId"
      [protocolId]="protocolId"
      [timestamp]="this.timestamp"
    ></nida-web-ecg-overview-detail>
  </div>
</div>
