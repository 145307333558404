import { Injectable } from '@angular/core';
import { LocalstorageUserSettingsService } from '@nida-web/shared/local-storage';
import { NewNotification } from '../models/new-notification.model';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessageService {
  private active: boolean;
  private status: string;

  constructor(private localstorageUserService: LocalstorageUserSettingsService, private transloco: TranslocoService) {
    if (!('Notification' in window) || Notification === undefined) {
      this.active = false;
      this.status = 'Unsupported by browser';
    } else {
      if (Notification.permission === 'granted') {
        this.active = true;
        this.status = 'OK';
      } else {
        this.active = false;
        this.status = 'Permission denied';
      }
    }
  }

  public getActive(): boolean {
    return this.active;
  }

  public getStatus(): string {
    return this.status;
  }

  pushNotificationMessage(notification: NewNotification) {
    if (this.active) {
      if (notification.iconPath === null) {
        notification.iconPath = '';
      }
      try {
        new Notification(notification.title, {
          body: notification.text,
          icon: notification.iconPath,
          // TODO: Why is the property gone in typescript 5.3.3 to 5.4.5?
          // vibrate: [100, 50, 100],
        });
      } catch (e) {
        //pass
        //Chrome Mobile allows Notifications, only if they are created via a serviceWorkerRegistration (not via new Notification())
        //https://bugs.chromium.org/p/chromium/issues/detail?id=481856
      }
    }
  }

  public initializeNotificationStatus() {
    const currentUserSettings = this.localstorageUserService.currentUserSettings;
    if (currentUserSettings['notificationMessages']) {
      this.enable();
    } else {
      this.disable();
    }
  }

  public disable(): void {
    this.localstorageUserService.setCurrentUserSettingByKey('notificationMessages', false);

    if (this.active) {
      this.active = false;
      this.status = 'Disabled by user';
    }
  }

  public enable(): void {
    this.localstorageUserService.setCurrentUserSettingByKey('notificationMessages', true);

    if ('Notification' in window && Notification !== undefined && Notification.permission === 'granted') {
      this.active = true;
      this.status = 'OK';
      this.pushNotificationMessage({
        title: this.transloco.translate('Notifications enabled'),
        text: this.transloco.translate('Notifications successfully enabled'),
        iconPath: 'assets/resources/ambulance-car.png',
      });
    } else {
      this.status = 'Permission denied or unsupported by browser';
      this.active = false;
    }
  }
}
