<div class="site-wrapper" [ngClass]="{ 'p-1 p-sm-2 p-md-4 ': this.disableContentPadding === false }">
  <div *ngIf="enableBackButton" class="mb-3">
    <dx-button icon="back" text="{{ 'Back' | transloco }}" (onClick)="triggerBack()"></dx-button>
  </div>
  <div *ngIf="enableCustomBackButton" class="mb-3">
    <dx-button icon="back" text="{{ 'Back' | transloco }}" (onClick)="triggerCustomBack()"></dx-button>
  </div>
  <div
    *ngIf="!disableHeadline"
    class="site-headline d-flex justify-content-between {{
      breakpointForButtonTemplate ? 'd-' + breakpointForButtonTemplate + '-flex' : ''
    }}"
    style="margin-top: 0.25rem"
    [ngClass]="{
      headlineMargin: this.disableHeadlineMarginBottom === false,
      'd-flex': !buttonTemplate,
      'd-block': buttonTemplate
    }"
  >
    <h1 class="page-title m-0 d-inline-block" style="font-weight: normal; font-size: 1.75rem">
      {{ headline | transloco }}{{ translatedHeadline }}
    </h1>
    <div
      class="d-flex align-items-center {{ breakpointForButtonTemplate ? 'mt-' + breakpointForButtonTemplate + '-0' : '' }}"
      [ngClass]="{
        'mt-2': buttonTemplate
      }"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </div>
  </div>
  <ng-content></ng-content>
</div>
