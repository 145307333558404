import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthenticationServable } from '@nida-web/api/generic-interfaces/authentication';
import { BadgeCounterService } from '@nida-web/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { Router } from '@angular/router';

@Component({
  selector: 'nida-web-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LogoutComponent implements OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private auth: AuthenticationServable,
    private badgeCounterService: BadgeCounterService,
    private sessionManagerService: SessionManagerService,
    private router: Router
  ) {
    this.badgeCounterService.reset();

    this.auth
      .logout()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.sessionManagerService.resetSession();
        // this.router.navigate(['login']).then();

        this.router.navigate(['/login']);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
