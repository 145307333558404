import { Injectable } from '@angular/core';
import { ConfigSubjectService } from './config-subject.service';
import { ConfigService } from '@nida-web/api/rest/config';

@Injectable({
  providedIn: 'root',
})
export class CorpulsBindingService {
  private bindingLink: string;
  private paramsString: string;
  private params: string[];

  constructor(private configSubjectService: ConfigSubjectService, private appConfig: ConfigService) {
    this.bindingLink = '';
    this.paramsString = '?';
    this.configSubjectService.apiConfig.subscribe((apiobj) => {
      if (apiobj.Corpuls) {
        this.bindingLink = apiobj.Corpuls.widgetUrl;
        this.params = apiobj.Corpuls.AllowedParams;

        if (!this.bindingLink) this.getAppConfigGroup();
      } else {
        console.log('missing config for corpuls!');
      }
    });
  }

  private getAppConfigGroup() {
    this.appConfig.getConfigsByGroup('liveECG').subscribe((configs) => {
      const configentry = configs.data.find((conf) => conf.key === 'corpulsLink');
      if (configentry && configentry.value) this.bindingLink = configentry.value;
    });
  }

  public setParameter(paramName: string, paramValue: string) {
    if (this.params.includes(paramName)) {
      if (this.paramsString === '?') {
        this.paramsString += paramName + '=' + paramValue;
      } else {
        this.paramsString += '&' + paramName + '=' + paramValue;
      }
    }
  }

  public clearParameters() {
    this.paramsString = '?';
  }

  public generateLink(view?: 'livedata' | 'liveview' | 'typelabel' | 'handbook'): string {
    let generatedLink = '';
    switch (view) {
      case undefined:
        generatedLink = this.bindingLink + this.paramsString;
        break;
      case 'typelabel':
        generatedLink = this.bindingLink + 'type-label/?locale=de';
        break;
      case 'handbook':
        generatedLink = this.bindingLink + 'app/?servicehandler=manualServiceHandler&locale=de&filetype=GAN';
        break;
      default:
        generatedLink = this.bindingLink + view + this.paramsString;
        break;
    }
    this.clearParameters();
    return generatedLink;
  }

  public setBindingLink(bindingLink: string): void {
    this.bindingLink = bindingLink;
  }
}
