import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Account, ResetPasswordService, Tenant } from '@nida-web/api/rest/authentication';
import { GetTenantsService } from '../../services/get-tenants.service';
import { LogoSettingsService } from '@nida-web/core';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { Router } from '@angular/router';

@Component({
  selector: 'nida-web-reset-user-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  /** The login form */
  public resetForm!: UntypedFormGroup;
  private clientId: number;
  private username: string;
  public tenantList: Array<Tenant>;
  public logoSettings: string[];
  private settings: Subscription;
  private resetPassword: Subscription;

  constructor(
    private getTenantService: GetTenantsService,
    private logoSettingsService: LogoSettingsService,
    private resetPasswordService: ResetPasswordService,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.tenantList = [];
    this.logoSettings = [];
    this.getTenantService.loadTenants();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.getLogoInformation();
    this.getTenantService.tenantsLoaded.subscribe((tenantsLoaded) => {
      if (tenantsLoaded) {
        this.tenantList = this.getTenantService.getTenants();
      }
    });
  }

  ngOnDestroy(): void {
    this.settings.unsubscribe();
  }

  private initFormGroup(): void {
    this.resetForm = new UntypedFormGroup({
      clientId: new UntypedFormControl(this.clientId),
      username: new UntypedFormControl(this.username),
    });
  }

  private getLogoInformation() {
    this.settings = this.logoSettingsService.getSettings().subscribe((settings) => {
      for (const [logoName, show] of Object.entries(settings)) {
        if (show) {
          this.logoSettings.push(logoName);
        }
      }
    });
  }

  submitForm() {
    const resetFormRawValues = this.resetForm.getRawValue();
    const account: Account = {
      account: resetFormRawValues.username,
      tenantId: resetFormRawValues.clientId,
    };
    this.resetPassword = this.resetPasswordService.resetAccountPassword(account, 'response').subscribe({
      next: () => {
        notify({
          message: this.translocoService.translate('password reset successfully'),
          type: 'Info',
          displayTime: 100000,
        });
        this.router.navigate(['login']).then();
        this.resetPassword.unsubscribe();
      },
      error: () => {
        notify({
          message: this.translocoService.translate('error during password reset'),
          type: 'Error',
          displayTime: 5000,
        });
      },
    });
  }
}
