import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxTemplateModule } from 'devextreme-angular';
import { DxiColumnModule, DxoPagingModule } from 'devextreme-angular/ui/nested';
import { TranslocoModule } from '@jsverse/transloco';
import { PDFElement } from '@nida-web/api/generic-interfaces/attachments';
import { RowClickEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'nida-web-ui-shared-pdf-list',
  standalone: true,
  imports: [CommonModule, DxDataGridModule, DxTemplateModule, DxiColumnModule, DxoPagingModule, TranslocoModule],
  templateUrl: './ui-shared-pdf-list.component.html',
  styleUrl: './ui-shared-pdf-list.component.scss',
})
export class UiSharedPdfListComponent {
  @Input() public pdfListData: Array<PDFElement>;
  @Input() public pageSize: number;
  @Input() public disableDescriptionColumn: boolean;

  @Output() public rowClickEvent = new EventEmitter<RowClickEvent>();

  onRowClick(event: RowClickEvent) {
    this.rowClickEvent.emit(event);
  }
}
