import { Component } from '@angular/core';
import { PatientDetailComponent } from '../patient-detail/patient-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientListStoreService } from '../services/store/patient-list.store.service';
import { PatientDetailServable, PatientServable, VitalSignsServable } from '@nida-web/api/generic-interfaces/patient-management';
import { Location } from '@angular/common';
import { DateService, ModuleSettingsService } from '@nida-web/core';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'nida-web-patient-detail-current',
  templateUrl: '../patient-detail/patient-detail.component.html',
  styleUrls: ['../patient-detail/patient-detail.component.scss'],
})
export class PatientDetailCurrentComponent extends PatientDetailComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected patientDetailServable: PatientDetailServable,
    protected location: Location,
    protected vitalSignsServable: VitalSignsServable,
    protected dateService: DateService,
    protected patientServable: PatientServable,
    protected sessionManagerService: SessionManagerService,
    protected moduleSettingsService: ModuleSettingsService,
    protected translocoService: TranslocoService,

    private patientListStoreService: PatientListStoreService
  ) {
    super(
      route,
      router,
      patientDetailServable,
      location,
      vitalSignsServable,
      dateService,
      patientServable,
      moduleSettingsService,
      sessionManagerService,
      translocoService
    );
  }

  initializePage(): void {
    this.patientListStoreService.patientsLoaded.subscribe((val) => {
      if (val && this.id) {
        this.baseListRenewed = this.patientListStoreService.getRenewInformation();
        this.loadAllData();
      } else {
        this.patientListStoreService.renewList();
      }
    });
  }

  getBasicData(): void {
    if (this.id === undefined) {
      throw new Error('Patient ID is undefined!');
    }

    this.patientData = this.patientListStoreService.getDetailInformation(this.id);

    if (this.patientData) {
      this.archived = this.patientData.archived !== undefined ? this.patientData.archived : false;
    } else {
      this.showProtocolNotFoundErrorMessage();
      this.navigateBack();
      return;
    }
  }
}
