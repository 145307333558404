<dx-data-grid
  id="protocolPdfList"
  (onRowClick)="onRowClick($event)"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [dataSource]="pdfListData"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="true"
  [wordWrapEnabled]="true"
  keyExpr="nidaId"
  noDataText="{{ 'No data' | transloco }}"
>
  <dxo-paging *ngIf="pageSize > 0" [enabled]="true" [pageSize]="pageSize"></dxo-paging>
  <dxo-paging *ngIf="!pageSize" [enabled]="false"></dxo-paging>

  <dxi-column
    *ngIf="!disableDescriptionColumn"
    [caption]="'Description' | transloco"
    alignment="left"
    dataField="description"
    dataType="string"
  ></dxi-column>
  <dxi-column [caption]="'Status' | transloco" alignment="left" dataField="status" dataType="string"></dxi-column>
  <dxi-column [caption]="'Date' | transloco" dataField="timeStamp" dataType="datetime" cellTemplate="dateTemplate" width="135"></dxi-column>
  <div *dxTemplate="let data of 'dateTemplate'">
    {{ data.value | date: 'dd.MM.yyyy, HH:mm' }}
  </div>
</dx-data-grid>
