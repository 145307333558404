import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ImageCarouselViewerComponent } from '../image-carousel-viewer/image-carousel-viewer.component';
import { Picture } from '@nida-web/api/generic-interfaces/patient-management';
import { SafeResourceUrl } from '@angular/platform-browser';
import { RowClickEvent, SelectionChangedEvent, ContentReadyEvent } from 'devextreme/ui/data_grid';
import { TranslocoModule } from '@jsverse/transloco';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxoSelectionModule, DxiColumnModule } from 'devextreme-angular/ui/nested';
import { NgIf, NgStyle } from '@angular/common';
import { DxDataGridModule, DxPopupModule, DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'nida-web-image-layout-viewer',
  templateUrl: './image-layout-viewer.component.html',
  styleUrls: ['./image-layout-viewer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    DxDataGridModule,
    NgIf,
    DxoSelectionModule,
    DxiColumnModule,
    DxTemplateModule,
    DxPopupModule,
    NgStyle,
    DxButtonModule,
    ImageCarouselViewerComponent,
    TranslocoModule,
  ],
})
export class ImageLayoutViewerComponent implements OnInit {
  @Input() public thumbnailHeight: number | undefined;
  @Input() public mediaList: Picture[] | undefined;

  @Input() public topOffset = 0;
  @Input() public responsive = false;
  @Input() public selectable = false;
  @Output() public selectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();
  public carouselData: SafeResourceUrl[];

  @ViewChild('carouselViewerComponent') carouselViewer: ImageCarouselViewerComponent | undefined;
  public popupVisible: boolean;
  public popupMediaIndex: number;
  public videoRotation: number;
  public videoZoomLevel: number;
  public videoTranslateY: number;
  public videoTranslateX: number;
  private directionsMap: Map<string, string>;
  public showPrintBtn: boolean;

  constructor() {
    this.popupVisible = false;
    this.popupMediaIndex = -1;
    this.videoRotation = 0;
    this.videoZoomLevel = 1;
    this.videoTranslateX = 0;
    this.videoTranslateY = 0;
    this.directionsMap = new Map<string, string>();
    this.carouselData = [];
    this.showPrintBtn = false;
  }

  ngOnInit() {
    this.carouselData = [];

    if (this.mediaList === undefined) {
      this.mediaList = [];
    }
    for (const item of this.mediaList) {
      if (item.srcUrl) {
        this.carouselData.push(item.srcUrl);
      }
    }
    this.resetVideo();
    this.buildDirectionsMap();
  }

  onRowClick($event: RowClickEvent): void {
    if ($event.data.isVideo) {
      this.resetVideo();
      this.popupMediaIndex = $event.rowIndex;
      this.popupVisible = true;
    } else {
      if (this.carouselViewer === undefined) {
        throw new Error('Undefined Exception');
      }

      this.showPrintBtn = true;

      this.carouselViewer.viewIndex($event.rowIndex);
    }
  }

  onContentReady($event: ContentReadyEvent): void {
    this.carouselData = [];
    for (const item of $event.component.getVisibleRows()) {
      this.carouselData.push(item.data.srcUrl);
    }

    if (this.carouselViewer === undefined) {
      throw new Error('Undefined Exception');
    }

    this.carouselViewer.updateCarouselData(this.carouselData);
  }

  onSelectionChanged($event: SelectionChangedEvent): void {
    this.selectionChanged.emit($event);
  }

  handleShowPrintBtnEvent(showPrintBtn: boolean): void {
    this.showPrintBtn = showPrintBtn;
  }

  printImage(): void {
    const imgWidth: number = parseInt(this.carouselViewer?.viewImages?.nativeElement?.viewer.imageData.naturalWidth);
    const imgHeight: number = parseInt(this.carouselViewer?.viewImages?.nativeElement?.viewer.imageData.naturalHeight);

    if (imgWidth <= 0 || imgHeight <= 0) {
      return;
    }

    const printwin = window.open('', '_blank') as Window;
    const ratio: number = imgHeight / imgWidth;

    let isLandscape = '';
    if (ratio < 0.8) {
      isLandscape = '@page { size: landscape; }';
    }

    const windowcontent: string =
      '<style>@media print { ' +
      isLandscape +
      ' img { width: 100%; max-width: ' +
      imgWidth +
      'px; } }</style><img src="' +
      this.carouselViewer?.viewImages?.nativeElement?.viewer.image.currentSrc +
      '">';

    printwin.document.write(windowcontent);
    printwin.print();
    printwin.close();
  }

  rotateVideo(rotateLeft: boolean): void {
    if (rotateLeft) {
      if (this.videoRotation - 90 < 0) {
        this.videoRotation = 270;
      } else {
        this.videoRotation -= 90;
      }
    } else {
      if (this.videoRotation + 90 > 270) {
        this.videoRotation = 0;
      } else {
        this.videoRotation += 90;
      }
    }
  }

  zoomVideo(zoomIn: boolean) {
    if (zoomIn) {
      if (!(this.videoZoomLevel + 0.25 > 10)) {
        this.videoZoomLevel += 0.25;
      }
    } else {
      if (!(this.videoZoomLevel - 0.25 <= 0)) {
        this.videoZoomLevel -= 0.25;
      }
    }
  }

  moveVideo(direction: string) {
    const directionRotation = this.directionsMap.get(direction + '_' + this.videoRotation);
    switch (directionRotation) {
      case 'left':
        this.videoTranslateX -= 1;
        break;
      case 'right':
        this.videoTranslateX += 1;
        break;
      case 'up':
        this.videoTranslateY += 1;
        break;
      case 'down':
        this.videoTranslateY -= 1;
        break;
      default:
    }
  }

  resetVideo() {
    this.videoRotation = 0;
    this.videoZoomLevel = 1;
    this.videoTranslateX = 0;
    this.videoTranslateY = 0;
  }

  @HostListener('document:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (this.popupVisible) {
      if (event.deltaY > 0) {
        this.zoomVideo(true);
      } else {
        this.zoomVideo(false);
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPressed(event: KeyboardEvent) {
    if (this.popupVisible) {
      switch (event.key) {
        case 'ArrowLeft':
          this.moveVideo('right');
          break;
        case 'ArrowRight':
          this.moveVideo('left');
          break;
        case 'ArrowUp':
          this.moveVideo('up');
          break;
        case 'ArrowDown':
          this.moveVideo('down');
          break;
        case '+':
          this.zoomVideo(true);
          break;
        case '-':
          this.zoomVideo(false);
          break;
        case 'q':
          this.rotateVideo(false);
          break;
        case 'e':
          this.rotateVideo(true);
          break;
      }
    }
  }

  private buildDirectionsMap() {
    this.directionsMap.set('left_0', 'left');
    this.directionsMap.set('down_90', 'left');
    this.directionsMap.set('right_180', 'left');
    this.directionsMap.set('up_270', 'left');
    this.directionsMap.set('left_90', 'up');
    this.directionsMap.set('down_180', 'up');
    this.directionsMap.set('right_270', 'up');
    this.directionsMap.set('up_0', 'up');
    this.directionsMap.set('left_180', 'right');
    this.directionsMap.set('down_270', 'right');
    this.directionsMap.set('right_0', 'right');
    this.directionsMap.set('up_90', 'right');
    this.directionsMap.set('left_270', 'down');
    this.directionsMap.set('up_180', 'down');
    this.directionsMap.set('right_90', 'down');
    this.directionsMap.set('down_0', 'down');
  }
}
