import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { TranslocoModule } from '@jsverse/transloco';
import { SiteWrapperComponent } from './site-wrapper/site-wrapper.component';
import { UiSharedSaveButtonsComponent } from './ui-shared-save-buttons/ui-shared-save-buttons.component';
import { DxButtonModule, DxDataGridModule, DxPopupModule, DxTextBoxModule, DxButtonGroupModule, DxTagBoxModule } from 'devextreme-angular';
import { UiSharedNoticeComponent } from './ui-shared-notice/ui-shared-notice.component';
import { UiSharedTableSelectionComponent } from './ui-shared-table-selection/ui-shared-table-selection.component';
import { UiSharedPopupComponent } from './ui-shared-popup/ui-shared-popup.component';
import { UiSharedTableComponent } from './ui-shared-table/ui-shared-table.component';
import { UiSharedTableBatchEditingComponent } from './ui-shared-table-batch-editing/ui-shared-table-batch-editing.component';
import { UiSharedEntryCheckComponent } from './ui-shared-entry-check/ui-shared-entry-check.component';
import { UiSharedPasswordHintsComponent } from './ui-shared-password-hints/ui-shared-password-hints.component';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    DxButtonModule,
    DxPopupModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxButtonGroupModule,
    DxTagBoxModule,
  ],
  declarations: [
    CardComponent,
    SiteWrapperComponent,
    UiSharedSaveButtonsComponent,
    UiSharedTableComponent,
    UiSharedNoticeComponent,
    UiSharedTableSelectionComponent,
    UiSharedPopupComponent,
    UiSharedTableBatchEditingComponent,
    UiSharedEntryCheckComponent,
    UiSharedPasswordHintsComponent,
  ],
  exports: [
    CardComponent,
    SiteWrapperComponent,
    UiSharedSaveButtonsComponent,
    UiSharedTableComponent,
    UiSharedNoticeComponent,
    UiSharedTableSelectionComponent,
    UiSharedPopupComponent,
    UiSharedTableBatchEditingComponent,
    UiSharedEntryCheckComponent,
    UiSharedPasswordHintsComponent,
  ],
})
export class SharedUiModule {}
