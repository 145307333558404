<div class="h-100 d-flex flex-column" style="flex: 1">
  <div class="content-block">
    <div class="responsive-paddings">
      <form id="codeFormElement" [formGroup]="codeForm" (ngSubmit)="submitForm()">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 login-header">
              <ng-container *ngFor="let logo of logoSettings; let noBorder = last">
                <img *ngIf="noBorder" alt="{{ logo }}" class="{{ logo }}-logo" src="./assets/img/logos/{{ logo }}.png" />
                <img *ngIf="!noBorder" alt="{{ logo }}" class="{{ logo }}-logo logo-border" src="./assets/img/logos/{{ logo }}.png" />
              </ng-container>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="title">{{ appTitle }}</div>
          </div>
          <div class="row justify-content-center">
            <div class="login-subheader">{{ 'twoFactorLogin.headlineActivate' | transloco }}</div>
          </div>
          <div class="row justify-content-center">
            <div class="fa-subheader">
              <strong>{{ 'twoFactorLogin.step1' | transloco }} :</strong> {{ 'twoFactorLogin.openApp' | transloco }}
            </div>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="d-flex flex-column">
              <dx-button
                stylingMode="text"
                [text]="'twoFactorLogin.appInstallMessage' | transloco"
                type="default"
                [width]="'auto'"
                [height]="40"
                (onClick)="showAuthenticatorAppInfo = !showAuthenticatorAppInfo"
              >
              </dx-button>
            </div>
          </div>
          <div class="row justify-content-center" [@slideDownUp]="showAuthenticatorAppInfo ? 'visible' : 'hidden'">
            <div class="d-flex flex-column mb-4">
              <ng-container *ngFor="let app of apps">
                <div class="mt-1">
                  <strong>{{ app.appName }}</strong>
                </div>
                <div class="d-flex flex-wrap justify-content-start align-items-center">
                  <div class="m-2" *ngIf="app.iosLink">
                    <a [href]="app.iosLink">
                      <img
                        alt="Download on the App Store"
                        height="40px"
                        src="../assets/img/store-badges/Download_on_the_App_Store_Badge_black.png"
                      />
                    </a>
                  </div>
                  <div class="m-2" *ngIf="app.androidLink">
                    <a [href]="app.androidLink">
                      <img
                        alt="Get it on Google Play"
                        height="40px"
                        src="../assets/img/store-badges/Get_It_On_Google_Play_Badge_black.png"
                      />
                    </a>
                  </div>
                  <div class="m-2" *ngIf="app.desktopLink">
                    <a [href]="app.desktopLink">
                      <dx-button
                        stylingMode="outlined"
                        [text]="'twoFactorLogin.desktopVersion' | transloco"
                        type="normal"
                        [width]="'auto'"
                        [height]="40"
                      ></dx-button>
                    </a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="fa-subheader">
              <strong>{{ 'twoFactorLogin.step2' | transloco }}:</strong>
              {{ 'twoFactorLogin.scanQRCode' | transloco }}
            </div>
          </div>

          <div class="row justify-content-center pb-2">
            <div>
              <qrcode
                *ngIf="twoFactorQrCodeUri; else loggedOut"
                [qrdata]="twoFactorQrCodeUri"
                [elementType]="'svg'"
                [width]="256"
                [colorLight]="'#ffffff'"
                [colorDark]="'#111111'"
                [title]="'2FA QR Code'"
                [ariaLabel]="'2FA QR Code'"
                [errorCorrectionLevel]="'M'"
              ></qrcode>
              <ng-template #loggedOut>
                <div class="qr-code-placeholder d-flex justify-content-center align-items-center">
                  <dx-load-indicator id="qr-code-indicator" height="60" width="60"></dx-load-indicator>
                </div>
              </ng-template>
            </div>
          </div>
          <div *ngIf="twoFactorSecret" class="row justify-content-center pb-2">
            <div>{{ 'twoFactorLogin.copyCode' | transloco }}</div>
          </div>

          <div class="row justify-content-center pb-4">
            <div *ngIf="twoFactorSecret" class="code d-flex align-items-center">
              <samp>{{ twoFactorSecret }}</samp>
              <dx-button [cdkCopyToClipboard]="twoFactorSecret" class="code-btn" [icon]="'copy'" stylingMode="text"></dx-button>
            </div>
          </div>

          <div class="row justify-content-center mt-3">
            <div class="fa-subheader">
              <strong>{{ 'twoFactorLogin.step3' | transloco }}: </strong>
              {{ 'twoFactorLogin.enterCodeMessage' | transloco }}
            </div>
          </div>
          <div class="row justify-content-center pb-2">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-8">
              <div class="d-flex flex-column code-input-box mx-auto">
                <div class="dx-field">
                  <dx-text-box
                    id="code-input"
                    formControlName="code"
                    [valueChangeEvent]="valueChangeEvent"
                    (onValueChanged)="validateCodeInput()"
                  >
                    <dx-validator>
                      <dxi-validation-rule
                        type="required"
                        [message]="'twoFactorLogin.validation.required' | transloco"
                      ></dxi-validation-rule>
                      <dxi-validation-rule
                        type="pattern"
                        [pattern]="codePattern"
                        [message]="'twoFactorLogin.validation.minlength' | transloco"
                      ></dxi-validation-rule>
                      <dxi-validation-rule
                        type="stringLength"
                        [min]="6"
                        [message]="'twoFactorLogin.validation.pattern' | transloco"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <dx-button
                      (onClick)="cancel2FAForm()"
                      [text]="'twoFactorLogin.cancel' | transloco"
                      type="normal"
                      class="mb-2"
                    ></dx-button>
                  </div>
                  <div>
                    <dx-button
                      [useSubmitBehavior]="true"
                      [disabled]="!twoFactorSecret"
                      [text]="'twoFactorLogin.continue' | transloco"
                      type="default"
                      class="ml-2 mb-2"
                    ></dx-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <footer class="footer mt-auto py-3 d-block">
    <div class="container text-center">
      &copy; {{ navAppInfoService.cpYear }} <a href="https://www.meddv.de/">medDV GmbH</a> |
      <a routerLink="/legal-notice">{{ 'Legal Notice' | transloco }}</a>
    </div>
  </footer>
  <dx-popup
    [(visible)]="successVisible"
    [title]="'twoFactorLogin.activateSuccessHeadline' | transloco"
    [width]="360"
    [height]="320"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
  >
    <div>
      {{ 'twoFactorLogin.activateSuccessMessage1' | transloco }}
    </div>
    <div class="mt-3">
      {{ 'twoFactorLogin.activateSuccessMessage2' | transloco }}
    </div>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="center" [options]="successButtonOptions"></dxi-toolbar-item>
  </dx-popup>

  <!-- Failed Dialog -->
  <dx-popup
    [(visible)]="failedVisible"
    [title]="'twoFactorLogin.activateFailedHeadline' | transloco"
    [width]="360"
    [height]="320"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
  >
    <div>
      {{ 'twoFactorLogin.activateFailedMessage1' | transloco }}
    </div>
    <div class="mt-3">
      {{ 'twoFactorLogin.activateFailedMessage2' | transloco }}
    </div>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="cancelButtonOptions"></dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="failedButtonOptions"></dxi-toolbar-item>
  </dx-popup>
</div>
