<div class="row basic-flex-container">
  <div class="col-md-6 mb-1 mb-md-0">
    <div class="basic-sub-card-title">
      {{ 'Pre-registration diagnosis' | transloco }}
    </div>
    @if (missionData) {
    <div class="basic-font-large-bold break-word">
      {{ patientData.diagnosis ? patientData.diagnosis : ('' | transloco | translocoWord | displayNan) }}
    </div>
    }
  </div>
  <div class="col-md-3 col-6">
    <div class="basic-sub-card-title">
      {{ 'Arrival' | transloco }}
    </div>

    <div class="basic-font-large-important-bold arrival">
      {{ formatMoment(patientData.arrivalTime, 'time') | displayNan }}
    </div>
    @if (arrivalIsInFuture) {
    <div class="basic-font-medium-bold arrival">
      <nida-web-countdown [end]="patientData.arrivalTime"></nida-web-countdown>
    </div>
    } @if (!arrivalIsInFuture) {
    <div class="basic-font-medium arrival">
      {{ formatMoment(patientData.arrivalTime, 'date') | displayNan }}
    </div>
    }
  </div>
  <div class="col-md-3 col-6">
    @if (patientData.status !== -1) {
    <div class="basic-sub-card-title">
      {{ 'Priority' | transloco }}
    </div>
    <div class="basic-font-medium">
      @if (patientData.status === 0) {
      <div>
        <div class="basic-padding-small basic-rounded-corners priority-0 font-weight-bold">
          {{ 'Normal' | transloco }}
        </div>
      </div>
      } @if (patientData.status === 1) {
      <div>
        <div class="basic-padding-small basic-rounded-corners priority-1 font-weight-bold">
          {{ 'Urgent' | transloco }}
        </div>
      </div>
      } @if (patientData.status === 2) {
      <div>
        <div class="basic-padding-small basic-rounded-corners priority-2 font-weight-bold">
          {{ 'Immediately' | transloco }}
        </div>
      </div>
      }
    </div>
    }
  </div>
</div>

<div class="row mx-0 p-0 mt-2">
  <div class="col-md-6 col-lg-12 col-xl-6 m-0 p-0">
    <div class="row">
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Circulation' | transloco }}
        </div>
        <div class="basic-font-medium">
          @switch (patientData.circulation) { @case ('instabil') {
          <div>
            <span class="attention-text circulation-value">{{ 'Unstable_lowercase' | transloco }}</span>
          </div>
          } @case ('stabil') {
          <div>
            <span class="circulation-value">{{ 'Stable_lowercase' | transloco }}</span>
          </div>
          } @default {
          <div class="circulation-value">{{ patientData.circulation | displayNan }}</div>
          } }
        </div>
      </div>
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Intubation' | transloco }}
        </div>
        <div class="basic-font-medium">
          @switch (patientData.intubated) { @case (true) {
          <div>
            <span class="attention-text intubation-value">{{ 'Yes' | transloco }}</span>
          </div>
          } @case (false) {
          <div>
            <span class="intubation-value">{{ 'No' | transloco }}</span>
          </div>
          } @default {
          <div class="intubation-value">{{ null | displayNan }}</div>
          } }
        </div>
      </div>
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'I-Status' | transloco }}
        </div>
        <div class="basic-font-medium">
          @if (patientData?.iStatus === 'ja') {
          <span class="attention-text i-status-value">{{ 'Yes' | transloco }}</span>
          } @else if(patientData?.iStatus === 'nein'){
          <span class="i-status-value">{{ 'No' | transloco }}</span>
          } @else {
          {{ patientData?.iStatus }}
          }
        </div>
      </div>
    </div>
  </div>
  @if (findingData !== undefined) {
  <div class="col-md-6 col-lg-12 col-xl-6 m-0 p-0">
    <div class="row mt-2 mt-md-0">
      <div class="col-3">
        <div class="basic-sub-card-title">
          {{ 'GCS' | transloco }}
        </div>
        @if (findingData) {
        <div class="basic-font-medium" data-cy="gcs-value" [class.attention-text]="initialGcs && initialGcs <= 8 && initialGcs >= 0">
          {{ findingData.initial_gcs | replaceVitalParameter | displayNan }}
        </div>
        }
      </div>
      <div class="col-3">
        <div class="basic-sub-card-title">
          {{ 'Gcseyes' | transloco }}
        </div>
        @if (findingData) {
        <div class="basic-font-medium">
          {{ findingData.initial_gcs_eyes | replaceVitalParameter | displayNan }}
        </div>
        }
      </div>
      <div class="col-3">
        <div class="basic-sub-card-title">
          {{ 'Gcsmotorics' | transloco }}
        </div>
        @if (findingData) {
        <div class="basic-font-medium">
          {{ findingData.initial_gcs_motoric | replaceVitalParameter | displayNan }}
        </div>
        }
      </div>
      <div class="col-3">
        <div class="basic-sub-card-title">
          {{ 'Gcsverbal' | transloco }}
        </div>
        @if (findingData) {
        <div class="basic-font-medium">
          {{ findingData.initial_gcs_verbal | replaceVitalParameter | displayNan }}
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>

@if (!['herzinfarkt', 'heart attack', 'acs', 'krampfanfall', 'seizure', 'schlaganfall', 'stroke'].includes(patientData.diagnosis ?
patientData.diagnosis.toLowerCase() : '')) {
<div class="basic-sub-card-title mt-2">
  {{ 'Registration text' | transloco }}
</div>
@if (missionData) {
<div class="basic-font-medium">
  {{ findingData.supplement1value | displayNan }}
</div>
} } @if (findings.length > 0 || strokeMain.length > 0 || strokeTimestamps.length > 0 || strokeSum.length > 0) {
<div class="basic-sub-card-title mt-2">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ findingName }}
          @if (findingName === 'Prehospital epilepsy emergency score (pees) sum' || findingName === 'Prähospital-Epilepsie-Notfall-Score
          (PEES) Summe') {
          <span>
            {{ findingData.pees_sum }}
          </span>
          } @if (timeSinceFindingText) {
          <span>(Seit {{ timeSinceFindingText }})</span>
          }
        </p>
      </div>
    </div>
  </div>
  @if (findings) {
  <div class="row">
    <!-- Generic Finding Loop -->
    @for (item of findings; track item) {
    <div class="col-sm-6 mb-1">
      <div>{{ item.text | transloco }}</div>
      <div class="basic-font-medium">
        @if ((findingName === 'Prehospital epilepsy emergency score (pees) sum' || findingName === 'Prähospital-Epilepsie-Notfall-Score
        (PEES) Summe') && item.enabled) {
        <span class="mr-3">
          {{ item.enabled }}
        </span>
        }
        <span class="text-black">{{ item.value | displayNan }}</span>
      </div>
    </div>
    }
  </div>
  } @if (strokeMain || strokeTimestamps || strokeSum) {
  <div class="row">
    <div class="col-md-4 mb-1">
      @for (item of strokeMain; track item) {
      <div>
        <div>{{ item.text | transloco }}</div>
        <div class="basic-font-medium text-black">
          <span>{{ item.value | displayNan }}</span>
        </div>
      </div>
      }
    </div>
    <div class="col-md-4 mb-1">
      @for (item of strokeTimestamps; track item) {
      <div>
        <div>{{ item.text | transloco }}</div>
        <div class="basic-font-medium text-black">
          <span>{{ item.value | displayNan }}</span>
        </div>
      </div>
      }
    </div>
    <div class="col-md-4 mb-1">
      @for (item of strokeSum; track item) {
      <div>
        <div>{{ item.text | transloco }}</div>
        <div class="basic-font-medium text-black">
          <span>{{ item.value | displayNan }}</span>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>
}
