import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureSupportPageComponent } from './feature-support-page/feature-support-page.component';
import { SharedSupportRoutingModule } from './shared-support-routing.module';
import { SharedUiModule } from '@nida-web/shared/ui';
import { DxButtonModule } from 'devextreme-angular';
import { TranslocoModule } from '@jsverse/transloco';
import { FeatureClientSupportPageComponent } from './feature-client-support-page/feature-client-support-page.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { UiSupportTileComponent } from './ui-support-tile/ui-support-tile.component';

@NgModule({
  imports: [CommonModule, SharedSupportRoutingModule, SharedUiModule, TranslocoModule, DxButtonModule, PdfJsViewerModule],
  declarations: [FeatureSupportPageComponent, FeatureClientSupportPageComponent, UiSupportTileComponent],
})
export class SharedSupportModule {}
