import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserServable } from '@nida-web/api/generic-interfaces/user-management';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { Router } from '@angular/router';
import { PasswordAdapterService } from '@nida-web/api/rest/nidaserver/password3';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { APIPersonal, PersonalRESTService } from '@nida-web/api/rest/user-management';
import { StartPageRoutingService } from '@nida-web/core';
import { take, takeUntil } from 'rxjs/operators';
import { PasswordValidationService } from '@nida-web/shared/utils';

@Component({
  selector: 'nida-web-password-management',
  templateUrl: './password-management.component.html',
  styleUrls: ['./password-management.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PasswordManagementComponent implements OnInit, OnDestroy {
  @ViewChild('passwordChange', { static: false }) passwordChangeValidationGroup: DxValidationGroupComponent;
  private readonly unsubscribe$: Subject<void> = new Subject();

  @Input() headerName: string;
  @Input() buttonName: string;
  @Input() requireCurrentPassword: boolean;
  @Input() redirect: boolean;
  @Input() nidaMobile: boolean;
  @Input() requireUserName: boolean;

  passwordChangeForm: UntypedFormGroup;
  passwordMode: string;
  passwordButton;
  user;
  private userId: number;
  private userName: string;
  private sessionSub: Subscription;
  private sessionSub2: Subscription;

  public passwordValidationRules: Array<any>;

  constructor(
    private sessionManager: SessionManagerService,
    private passwordUserService: UserServable,
    private translocoService: TranslocoService,
    protected router: Router,
    private passwordNIDAmobileService: PasswordAdapterService,
    private personalRESTService: PersonalRESTService,
    private startPageRoutingService: StartPageRoutingService,
    private passwordValidationService: PasswordValidationService
  ) {
    this.passwordValidationRules = this.passwordValidationService.getPasswordValidationRules();
  }

  ngOnDestroy(): void {
    if (this.sessionSub) this.sessionSub.unsubscribe();
    if (this.sessionSub2) this.sessionSub2.unsubscribe();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.passwordMode = 'password';
    this.passwordButton = {
      icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
      type: 'default',
      onClick: () => {
        this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
      },
    };

    this.sessionSub = this.sessionManager
      .getSessionInformation()
      .pipe(take(1))
      .subscribe((sessionInformation) => {
        if (sessionInformation.loggedIn) {
          if (sessionInformation.userId) {
            this.userId = sessionInformation.userId;
          } else {
            this.userId = -1;
          }
          if (sessionInformation.userName) {
            this.userName = sessionInformation.userName;
          }
          this.personalRESTService.getPersonById(this.userId.toString()).subscribe((resultUser) => {
            this.passwordChangeForm.patchValue({ userName: resultUser.benutzer });
            this.user = resultUser;
          });
        }
      });
  }

  private initFormGroup() {
    this.passwordChangeForm = new UntypedFormGroup({
      userName: new UntypedFormControl(null),
      oldPassword: new UntypedFormControl(null),
      newPassword: new UntypedFormControl(null),
      passwordReplay: new UntypedFormControl(null),
    });
  }

  trySaveNewPassword(): void {
    /**
     * Check if Password is set for NIDAmobile or the User
     */
    if (this.nidaMobile) {
      this.changeNIDAmobilePassword();
    } else {
      this.passwordUserService
        .changePassword(this.userId, this.passwordChangeForm.getRawValue().oldPassword, this.passwordChangeForm.getRawValue().newPassword)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((success) => {
          if (success) {
            notify({
              message: this.translocoService.translate('saveSuccessfully'),
              type: 'Success',
              displayTime: 6000,
            });
            this.passwordChangeForm.reset();
            this.passwordChangeValidationGroup.instance.reset();
            if (this.redirect) {
              this.navigateHome();
            }
          }
        });
    }
  }

  public passwordComparison = () => {
    return this.passwordChangeForm.getRawValue().newPassword;
  };

  navigateHome() {
    this.startPageRoutingService.calculateRoute();
  }

  changeNIDAmobilePassword() {
    this.sessionSub2 = this.sessionManager
      .getSessionInformation()
      .pipe(take(1))
      .subscribe((sessionInformation) => {
        if (sessionInformation.loggedIn) {
          if (sessionInformation.userId) {
            this.userId = sessionInformation.userId;
          } else {
            this.userId = -1;
          }

          this.passwordNIDAmobileService
            .changeUserMobilePassword({ newPassword: this.passwordChangeForm.getRawValue().newPassword }, this.userId)
            .subscribe(() => {
              notify({
                message: this.translocoService.translate('saveSuccessfully'),
                type: 'Success',
                displayTime: 6000,
              });

              this.userName = this.passwordChangeForm.getRawValue().userName;
              if (this.userName.length > 1) {
                this.user.benutzer = this.userName;
                this.updateUser(this.user, this.userId);
              }

              this.passwordChangeForm.reset();
              this.passwordChangeValidationGroup.instance.reset();

              this.navigateHome();
            });
        }
      });
  }

  public updateUser(user: APIPersonal, id: number): Observable<number> {
    delete user.passwordLength;
    delete user.passwordMobileLength;
    delete user.pinLength;

    const resultSubject = new ReplaySubject<number>(1);

    this.personalRESTService.savePerson(user, id).subscribe((resultUser) => {
      if (resultUser === undefined || resultUser.id === undefined) {
        resultSubject.next(-1);
      } else if (resultUser.id >= 0) {
        resultSubject.next(resultUser.id);
      } else {
        resultSubject.next(-1);
      }
    });

    return resultSubject.asObservable();
  }
}
