import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxDrawerModule,
  DxListModule,
  DxScrollViewModule,
  DxToolbarModule,
  DxTreeViewModule,
} from 'devextreme-angular';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { SideNavigationMenuComponent } from './side-navigation-menu/side-navigation-menu.component';
import { SideNavOuterToolbarComponent } from './side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { HeaderComponent } from './header/header.component';
import { CoreModule, NavItem } from '@nida-web/core';
import { BaseRootComponent } from './base-root/base-root.component';
import { FooterComponent } from './footer/footer.component';
import { NAV_CONFIG, NavigationService } from './services/navigation.service';

@NgModule({
  declarations: [
    SideNavigationMenuComponent,
    SideNavOuterToolbarComponent,
    UserPanelComponent,
    HeaderComponent,
    BaseRootComponent,
    FooterComponent,
  ],
  imports: [
    DxTreeViewModule,
    DxListModule,
    DxScrollViewModule,
    DxDrawerModule,
    RouterModule,
    CommonModule,
    TranslocoModule,
    DxContextMenuModule,
    DxiItemModule,
    DxButtonModule,
    DxToolbarModule,
    CoreModule,
  ],
  exports: [SideNavigationMenuComponent, SideNavOuterToolbarComponent, UserPanelComponent, HeaderComponent, FooterComponent],
})
export class NavigationModule {
  static forRoot(config: NavItem[]): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [{ provide: NAV_CONFIG, useValue: config }, NavigationService],
    };
  }
}
