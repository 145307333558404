import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import {
  Findings,
  MissionData,
  PatientView,
  PatientDetailServable,
  Pdf,
  PictureList,
  VitalData,
  VitalSignsServable,
  PatientServable,
  Patient,
} from '@nida-web/api/generic-interfaces/patient-management';
import { Location } from '@angular/common';
import { DateService, ModuleSettingsService } from '@nida-web/core';
import { EcgListComponent } from '@nida-web/ecg-viewer';
import { PDFListComponent } from '@nida-web/pdf-viewer';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { PreregistrationVersion } from '../models/preregistration-version.enum';

@Component({
  selector: 'nida-web-missions-patients-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientDetailComponent implements OnInit, OnDestroy {
  public id: string;
  public loaded: BehaviorSubject<boolean>;
  public pictureList: PictureList;
  public pdf?: Pdf;
  public protocolId?: number;
  public archived: boolean;
  public findingsNotYetLoaded: boolean;
  public findingData: Findings;
  public patientData?: PatientView;
  public kisData: boolean;
  public missionData: MissionData;
  public detailData: Patient;
  public vitalData: ReplaySubject<VitalData>;
  public arrivalIsInFuture: boolean;
  public emergencyRoomEnabled: boolean;
  public kisEnabled: boolean;
  protected preregistrationVersion?: PreregistrationVersion;
  protected preregistrationVersionEnum = PreregistrationVersion;
  protected baseListRenewed?: Observable<boolean>;
  protected baseListSub?: Subscription;

  @ViewChild('ecgChild') ecgChild!: EcgListComponent;
  @ViewChild('pdfChild') pdfChild!: PDFListComponent;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected patientDetailServable: PatientDetailServable,
    protected location: Location,
    protected vitalSignsServable: VitalSignsServable,
    protected dateService: DateService,
    protected patientServable: PatientServable,
    protected moduleSettingsService: ModuleSettingsService,
    protected sessionManagerService: SessionManagerService,
    protected translocoService: TranslocoService
  ) {
    this.loaded = new BehaviorSubject<boolean>(true);
    this.pictureList = [];
    this.kisData = true;
    this.findingData = new Findings();
    this.missionData = new MissionData();
    this.vitalData = new ReplaySubject<VitalData>(1);
    this.arrivalIsInFuture = false;
    this.emergencyRoomEnabled = false;
    this.kisEnabled = false;
    this.findingsNotYetLoaded = true;
  }

  ngOnDestroy(): void {
    if (this.baseListSub) {
      this.baseListSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.pictureList = [];
    this.getId();
    this.initializePage();
    this.loadModuleSettings();
    this.checkPermissions();
    this.preregistrationVersion = this.patientData?.prenotificationVersion;
  }

  getId(): void {
    // extracting the current user's id from the URL
    const id = this.route.snapshot.paramMap.get('id');
    // test if nida_id has correct length
    if (id && id.length === 32) {
      this.id = id;
    } else {
      this.showProtocolNotFoundErrorMessage();
      this.navigateBack();
      return;
    }
  }

  initializePage(): void {
    // DO NOT DELETE GETS CALLED IN  patient-detail-current.component.ts
  }

  private loadModuleSettings(): void {
    this.moduleSettingsService.getSettings().subscribe((config) => {
      if (config) {
        this.emergencyRoomEnabled = config.emergencyRoom;
      }
    });
  }

  private checkPermissions(): void {
    this.sessionManagerService.hasPermission(['nida.kis', 'nida.kis.map', 'nida.kis.unmap', 'klinik.kisimport']).subscribe((result) => {
      this.kisEnabled = result;
    });
  }

  loadAllData(): void {
    if (this.baseListRenewed) {
      this.baseListSub = this.baseListRenewed.subscribe((msg) => {
        if (msg) {
          this.getBasicData();
          if (this.patientData) {
            this.preregistrationVersion = this.patientData?.prenotificationVersion;

            this.setProtocolId();
            this.getAdditionalData();
            this.getPictures();
            this.checkIfArrivalIsInFuture();

            if (this.pdfChild) {
              this.pdfChild.ngOnInit();
            }

            if (this.ecgChild) {
              this.ecgChild.ngOnInit();
            }
          }
        }
      });
    }
  }

  getBasicData(): void {
    // DO NOT DELETE GETS CALLED IN  patient-detail-current.component.ts
  }

  setProtocolId(): void {
    if (this.patientData) {
      this.protocolId = this.patientData.protocolId;
    }
  }

  getAdditionalData(): void {
    if (this.id === undefined) {
      throw new Error('Patient ID is undefined!');
    }
    if (this.protocolId === undefined) {
      throw new Error('Patient Protocol ID is undefined!');
    }

    this.patientServable.getPatientByProtocolId(this.protocolId).subscribe((response) => {
      this.detailData = response;
    });
    this.patientDetailServable.getDetailFinding(String(this.protocolId)).subscribe((response: Findings) => {
      this.findingData = response;
      this.findingsNotYetLoaded = false;
    });
    this.vitalSignsServable.getAfByProtocolId(this.protocolId).subscribe((response: any) => {
      this.vitalData = response;
    });
  }

  getPictures(): void {
    if (this.id === undefined) {
      throw new Error('Patient ID is undefined!');
    }

    this.patientDetailServable.getPictureList(this.id).subscribe((val: any) => {
      this.pictureList = val;
    });
  }

  checkIfArrivalIsInFuture(): void {
    if (this.patientData) {
      const arrivalTime = this.patientData.arrivalTime;
      const now = new Date();
      if (arrivalTime && arrivalTime > now) {
        this.arrivalIsInFuture = true;
      }
    }
  }

  navigateBack(): void {
    this.router.navigate(['current-patients/overview']);
  }

  showProtocolNotFoundErrorMessage() {
    notify({
      message: this.translocoService.translate('protocolNotFoundError'),
      type: 'Error',
      displayTime: 5000,
    });
  }

  sumTis(findings: any) {
    if (findings) {
      // sums the TIS values (see below)
      const sum: number =
        findings['tisLocalisation'] +
        findings['tisTraumaType'] +
        findings['tisCirculation'] +
        findings['tisBreathing'] +
        findings['tisAwareness'];
      if (isNaN(sum)) {
        return '---';
      } else {
        return sum;
      }
    } else {
      return '---';
    }
  }

  combineDateTimeForDisplay(datePart: string | number | undefined, timePart: string | number | undefined) {
    let returnString: string;
    const divideString = ' ';

    if (datePart === undefined && timePart === undefined) {
      returnString = '';
    } else {
      if (datePart === undefined) {
        datePart = '';
      }
      if (timePart === undefined) {
        timePart = '';
      }
      returnString = new Date(datePart).toLocaleDateString() + divideString + new Date(timePart).toLocaleTimeString();
    }

    return returnString;
  }

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }

  protected readonly PreregistrationVersion = PreregistrationVersion;
}
