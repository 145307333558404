<div *ngIf="carouselData$ | async; let items">
  <div #viewImages [class.d-none]="!showImageStack" class="stack-wrapper">
    <div class="stack" (click)="openGallery()">
      <img *ngIf="items.length === 0" src="./assets/img/placeholder/placeholder_picture.svg" alt="Placeholder" />
      <ng-container *ngFor="let item of items">
        <img [src]="item" />
      </ng-container>
    </div>
  </div>
</div>
