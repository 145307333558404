import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Ecg, EcgLead, EcgServable } from '@nida-web/api/generic-interfaces/ecg-viewer';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';

type EcgLeadTypes = 'I' | 'II' | 'III' | 'aVR' | 'aVL' | 'aVF' | 'naVR' | 'V1' | 'V2' | 'V3' | 'V4' | 'V5' | 'V6';

@Component({
  selector: 'nida-web-ecg-overview-channels',
  templateUrl: './ecg-overview-channels.component.html',
  styleUrls: ['./ecg-overview-channels.component.scss'],
})
export class EcgOverviewChannelsComponent implements OnInit {
  @Output() chosenChannels: EventEmitter<any> = new EventEmitter();
  @Input() nidaId: string;
  @Input() ecgId: number;

  public screenWidth: number;
  public restEcgLead: EcgLead = new EcgLead();
  public restEcg: Ecg = new Ecg(this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead);
  public rawEcg: Ecg = new Ecg(this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead);
  public leadTypesExisting: EcgLeadTypes[];
  public leadTypesToBeShown: EcgLeadTypes[];
  public channels: EcgLeadTypes[];
  public channelStatus: object;
  public zoomRange: object;

  public channelColor: string;

  public dividingFactor: number;

  public loaded: boolean;

  constructor(private ecgViewerService: EcgServable, private zone: NgZone, private translocoService: TranslocoService) {
    this.screenWidth = screen.width;

    this.nidaId = '';
    this.ecgId = 0;
    this.leadTypesExisting = ['I', 'II', 'III', 'aVR', 'aVL', 'aVF', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6'];
    // this.leadTypesToBeShown = ['I', 'II', 'III', 'aVR', 'aVL', 'aVF', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6'];
    this.leadTypesToBeShown = [];
    this.channels = [];
    this.channelStatus = {};
    this.zoomRange = { startValue: 3 * 80, endValue: 5 * 80 };

    // this.channelColor = 'rgb(205, 10, 32)'
    this.channelColor = 'cornflowerblue';

    this.dividingFactor = 6;

    this.loaded = false;
  }

  ngOnInit() {
    if (this.nidaId && this.nidaId.length === 0) {
      notify({
        message: this.translocoService.translate('Sorry, no NIDA ID and / or protocol ID given'),
        type: 'Warning',
        displayTime: 5000,
      });
      this.loaded = true;
    } else {
      this.getECGPreviewData();
    }
  }

  getECGPreviewData() {
    this.ecgViewerService.getEcg(this.nidaId, this.ecgId, 'hz_50').subscribe((ecg) => {
      this.rawEcg = ecg;

      this.refineECGPreviewData();
      this.setDividingFactor();
      this.prepareLeadStatusObject();

      this.loaded = true;
    });
  }

  refineECGPreviewData() {
    this.leadTypesExisting.forEach((lead: EcgLeadTypes) => {
      if (this.rawEcg[lead] !== undefined) {
        this.leadTypesToBeShown.push(lead);
        this.restEcg[lead] = this.rawEcg[lead];
      }
    });
  }

  setDividingFactor() {
    if (this.leadTypesToBeShown.length < 12) {
      this.dividingFactor = Math.ceil(this.leadTypesToBeShown.length / 2);
    }
  }

  prepareLeadStatusObject() {
    this.leadTypesToBeShown.forEach((lead: string) => {
      this.channelStatus[lead] = {
        chosen: false,
      };
    });
  }

  manageCheckBox(e: { value: boolean }, lead: EcgLeadTypes) {
    this.channelStatus[lead]['chosen'] = !this.channelStatus[lead]['chosen'];
    this.triggerArrayTransport(e, lead);

    this.forceRefreshing();
  }

  triggerArrayTransport(e: { value: boolean }, lead: EcgLeadTypes) {
    if (e.value) {
      this.channels.push(lead);
    } else {
      for (let i = 0; i < this.channels.length; i++) {
        if (this.channels[i] === lead) {
          this.channels.splice(i--, 1);
        }
      }
    }

    this.chosenChannels.emit(this.channels); // ['I', 'II']
  }

  forceRefreshing() {
    // ...forces the rerendering of the datagrid
    // ...=> hovering does not seem to activate Angular's rerendering
    this.zone.run(() => {
      // TODO: nothing happens here!
    });
  }
}
