<div class="h-100 d-flex flex-column">
  <div class="content-block">
    <div class="responsive-paddings">
      <form [formGroup]="codeForm" (ngSubmit)="submitForm()">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 login-header">
              <ng-container *ngFor="let logo of logoSettings; let noBorder = last">
                <img *ngIf="noBorder" alt="{{ logo }}" class="{{ logo }}-logo" src="./assets/img/logos/{{ logo }}.png" />
                <img *ngIf="!noBorder" alt="{{ logo }}" class="{{ logo }}-logo logo-border" src="./assets/img/logos/{{ logo }}.png" />
              </ng-container>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="title">{{ appTitle }}</div>
          </div>
          <div class="row justify-content-center">
            <div class="login-subheader">{{ 'twoFactorLogin.headlineCodeVerify' | transloco }}</div>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="fa-subheader">{{ 'twoFactorLogin.descriptionCodeVerify' | transloco }}</div>
          </div>
          <div class="row justify-content-center pb-2">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="d-flex flex-column code-input-box mx-auto">
                <div class="dx-field">
                  <dx-text-box
                    id="code-input"
                    formControlName="code"
                    [valueChangeEvent]="valueChangeEvent"
                    (onValueChanged)="validateCodeInput()"
                    [readOnly]="validatingCode"
                  >
                    <dx-validator>
                      <dxi-validation-rule
                        type="required"
                        [message]="'twoFactorLogin.validation.required' | transloco"
                      ></dxi-validation-rule>
                      <dxi-validation-rule
                        type="pattern"
                        [pattern]="codePattern"
                        [message]="'twoFactorLogin.validation.minlength' | transloco"
                      ></dxi-validation-rule>
                      <dxi-validation-rule
                        type="stringLength"
                        [min]="6"
                        [message]="'twoFactorLogin.validation.pattern' | transloco"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <dx-button
                      (onClick)="cancel2FAForm()"
                      [text]="'twoFactorLogin.cancel' | transloco"
                      type="normal"
                      class="mb-2"
                    ></dx-button>
                  </div>
                  <div>
                    <dx-button
                      [useSubmitBehavior]="true"
                      [disabled]="validatingCode"
                      [text]="'twoFactorLogin.continue' | transloco"
                      type="default"
                      class="ml-2 mb-2"
                    ></dx-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <footer class="footer mt-auto py-3 d-block">
    <div class="container text-center">
      &copy; {{ navAppInfoService.cpYear }} <a href="https://www.meddv.de/">medDV GmbH</a> |
      <a routerLink="/legal-notice">{{ 'Legal Notice' | transloco }}</a>
    </div>
  </footer>
</div>
