import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginCredential } from '../models/login-credential.model';
import { LoginInformation } from '../models/login-information.model';

/**
 * Authentication Interface for Standard Login and Logout procedures.
 */
@Injectable()
export abstract class AuthenticationServable {
  /**
   * Login with Credentials
   * @param credential Information with Username and Password.
   * @param returnUrl Optional return Url the user will be redirected to after successful login.
   * @return Observable which will receive the Login Information. Please be aware that this could take as much time as the timeout value
   * of the used protocol for the login procedure.
   */
  abstract login(credential: LoginCredential): Observable<LoginInformation>;

  /**
   * Logout Mechanism for the current User. If there is none nothing shall happen.
   * @return Observable which will receive the Logout Information. Please be aware that this could take as much time as the timeout value
   * of the used protocol for the login procedure.
   */
  abstract logout(): Observable<LoginInformation>;

  /**
   * Mechanism to check if the current user is already authenticated at a server
   */
  abstract getAuthState(): Observable<LoginInformation>;

  /**
   * Called to start the login process
   */
  abstract startLogin(): void;

  abstract realLogin(credential: LoginCredential, returnUrl?: string, tenantId?: string, setNidaMobilePassword?: boolean): void;

  abstract navigateTo2FAActivation(): void;

  abstract get2FASecret(): Observable<string>;

  abstract save2FASecret(code: string): Observable<boolean>;

  abstract login2FA(code: string): Observable<boolean>;

  /**
   * Called to start the app when login was successfully or user is already logged in
   */
  abstract startApp(): void;
}
