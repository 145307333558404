import { HttpClient } from '@angular/common/http';
import { provideTransloco, Translation, TranslocoLoader, TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { APP_INITIALIZER, Inject, inject, Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslocoWordPipe } from './pipes/transloco-word.pipe';
import { GlobalizeLanguageService } from './services/globalize-language.service';
import { take } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import merge from 'deepmerge-json';
import { provideTranslocoPreloadLangs } from '@jsverse/transloco-preload-langs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, @Inject('environment') private environment: any) {}

  getTranslation(lang: string) {
    const defaultLanguageFile = this.http.get<Translation>(`./assets/i18n/${lang}.json`);
    const mergedJson: ReplaySubject<Translation> = new ReplaySubject();

    defaultLanguageFile.subscribe((defaultJson) => {
      const pathToTranslation = this.environment.configbasepath + this.environment.product + '/default/i18n/';
      const customerLanguageFile = this.http.get<Translation>(pathToTranslation + `${lang}.json`);
      customerLanguageFile.subscribe((customerJson) => {
        mergedJson.next(merge(defaultJson, customerJson));
      });
    });
    return mergedJson;
  }
}

@NgModule({
  declarations: [TranslocoWordPipe],
  providers: [provideTranslocoPreloadLangs(['de', 'en', 'zh'])],
  exports: [TranslocoModule, TranslocoWordPipe],
})
export class TranslocoTranslationModule {
  static forRoot(environment: any): ModuleWithProviders<TranslocoTranslationModule> {
    return {
      ngModule: TranslocoTranslationModule,
      providers: [
        {
          // waits until useFactory is completed
          provide: APP_INITIALIZER,
          useFactory: () => {
            const globalizeLanguageService = inject(GlobalizeLanguageService);
            // gets the configured language by user or the default value
            return () => globalizeLanguageService.loadLanguageForAppInitialization().pipe(take(1));
          },
          // multi NEEDs to be true to have multiple APP_INITIALIZER
          multi: true,
          deps: [TranslocoService, GlobalizeLanguageService],
        },
        provideTransloco({
          config: {
            availableLangs: ['de', 'en', 'zh'],
            fallbackLang: 'en',
            defaultLang: 'de', // gets loaded from the browser by loadLanguageForAppInitialization
            reRenderOnLangChange: true,
            prodMode: environment.prodMode,
            missingHandler: {
              logMissingKey: !environment.prodMode,
            },
          },
          loader: TranslocoHttpLoader,
        }),
      ],
    };
  }
}
