import { NgModule } from '@angular/core';
import { KisTileComponent } from './kis-tile/kis-tile.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { KisListComponent } from './kis-list/kis-list.component';
import { DxButtonModule, DxDataGridModule, DxTooltipModule } from 'devextreme-angular';
import { KisRowDetailComponent } from './kis-list/kis-row-detail/kis-row-detail.component';
import { KisLinkComponent } from './kis-link/kis-link.component';
import { AdtMessageConverterPipe } from './services/pipes/adt-message-converter.pipe';
import { CoreModule } from '@nida-web/core';
import { SharedUiModule } from '@nida-web/shared/ui';

@NgModule({
  declarations: [KisTileComponent, KisListComponent, KisRowDetailComponent, KisLinkComponent, AdtMessageConverterPipe],
  imports: [
    TranslocoModule,
    TranslocoTranslationModule,
    RouterModule,
    CommonModule,
    DxDataGridModule,
    DxTooltipModule,
    DxButtonModule,
    CoreModule,
    SharedUiModule,
  ],
  exports: [KisTileComponent, KisListComponent, KisLinkComponent],
})
export class KisModule {}
