import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import Viewer from 'viewerjs';
import { Observable, ReplaySubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgFor, AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'nida-web-image-carousel-viewer',
  templateUrl: './image-carousel-viewer.component.html',
  styleUrls: ['./image-carousel-viewer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [NgIf, NgFor, AsyncPipe],
})
export class ImageCarouselViewerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public carouselData: string[] | SafeResourceUrl[] | undefined;
  @Input() public showImageStack = false;
  @Output() public showPrintBtnEvent = new EventEmitter<boolean>();
  @ViewChild('viewImages') viewImages: ElementRef | undefined;

  public gallery: Viewer | undefined;
  private carouselDataSubject: ReplaySubject<string[] | SafeResourceUrl[]>;
  public carouselData$: Observable<string[] | SafeResourceUrl[]>;

  public viewerOptions: any = {
    toolbar: {
      zoomIn: true,
      zoomOut: true,
      oneToOne: true,
      reset: true,
      prev: true,
      play: false,
      next: true,
      rotateLeft: true,
      rotateRight: true,
      flipHorizontal: false,
      flipVertical: false,
    },
  };

  constructor() {
    this.carouselDataSubject = new ReplaySubject<string[] | SafeResourceUrl[]>(1);
    this.carouselDataSubject.next([]);
    this.carouselData$ = this.carouselDataSubject.asObservable();
  }

  ngAfterViewInit(): void {
    if (this.viewImages === undefined) {
      throw new Error('Undefined Exception');
    }

    this.gallery = new Viewer(this.viewImages.nativeElement, {
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: true,
        reset: true,
        prev: true,
        play: false,
        next: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: false,
        flipVertical: false,
      },
    });

    this.viewImages.nativeElement.addEventListener('hide', () => {
      this.showPrintBtnEvent.emit(false);
    });
  }

  ngOnChanges() {
    if (this.carouselData === undefined) {
      this.carouselData = [];
    }
    this.carouselDataSubject.next(this.carouselData);
    if (this.gallery) {
      this.gallery.update();
    }
  }

  ngOnInit() {
    if (this.carouselData === undefined) {
      this.carouselData = [];
    }
    this.carouselDataSubject.next(this.carouselData);
    if (this.gallery) {
      this.gallery.update();
    }
  }

  /**
   * Hier ist eine Beschreibung.
   * @param index des Elements, welches angezeigt werden soll.
   * @return Nichts.
   */
  public viewIndex(index: number): void {
    if (this.gallery === undefined) {
      throw new Error('Undefined Exception');
    }

    this.gallery.update(); // TODO: Update nicht bei jedem View..
    this.gallery.view(index);
  }

  public updateCarouselData(carouselData: string[] | SafeResourceUrl[]) {
    this.carouselData = carouselData;
    this.carouselDataSubject.next(this.carouselData);
    if (this.gallery) {
      this.gallery.update();
    }
  }

  public openGallery() {
    console.log('openGallery');
    if (this.gallery === undefined) {
      throw new Error('Undefined Exception');
    }
    this.gallery.update();
    this.gallery.view(0);
  }
}
