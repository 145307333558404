import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxFormComponent, DxFormModule, DxPopupModule, DxTextAreaModule, DxValidatorModule } from 'devextreme-angular';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SharedUiModule } from '../shared-ui.module';

@Component({
  selector: 'nida-web-ui-shared-request-popup',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiModule,
    DxButtonModule,
    DxPopupModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxFormModule,
    TranslocoModule,
  ],
  templateUrl: './ui-shared-request-popup.component.html',
  styleUrl: './ui-shared-request-popup.component.scss',
})
export class UiSharedRequestPopupComponent {
  @Input() popupVisible: boolean;
  @Output() popupVisibleChange = new EventEmitter<boolean>();
  @Input() requestDescriptionText: string;
  @Input() maxInputLength = 65535; // MySQL TEXT field limit
  @Output() requestSubmitted = new EventEmitter<string>();

  @ViewChild('reasonForm', { static: false }) reasonForm: DxFormComponent;

  public requestReasonFormData: { requestReason?: string } = { requestReason: '' };
  public submitButtonOptions: Record<string, unknown>;
  public cancelButtonOptions: Record<string, unknown>;
  public submitButtonDisabled = true;

  constructor(private translocoService: TranslocoService) {
    this.submitButtonOptions = {
      text: this.translocoService.translate('Request Access'),
      type: 'default',
      stylingMode: 'contained',
      elementAttr: { id: 'viewRequestSubmitButton' },
      onClick: () => this.onSubmit(),
    };

    this.cancelButtonOptions = {
      text: this.translocoService.translate('Cancel'),
      type: 'normal',
      elementAttr: { id: 'viewRequestCancelButton' },
      onClick: () => {
        this.popupVisible = false;
      },
    };
  }

  openPopup(): void {
    this.popupVisible = true;
  }

  onSubmit(): void {
    this.requestSubmitted.emit(this.requestReasonFormData.requestReason);
    this.popupVisible = false;
  }

  onPopupHidden(): void {
    this.popupVisibleChange.emit(false);
    this.reasonForm.instance.clear();
  }

  validateForm() {
    if (this.reasonForm?.instance.validate().isValid) {
      this.submitButtonDisabled = false;
    } else {
      this.submitButtonDisabled = true;
    }
  }
}
