import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { EcgLead } from '@nida-web/api/generic-interfaces/ecg-viewer';
import { VisualRange } from '../models/visual-range';
import { ZoomRange } from '../models/zoom-range';
import { TickIntervals } from '../models/tick-intervals';
import { DetailPointStorage } from '../models/detail-point-storage';
import { RangeValues } from '../models/range-values';
import { CrosshairPoint } from '../models/crosshair-point';
import { EcgLeadMetaDetail } from '../models/ecg-lead-meta-detail';
import { EcgLeadDataDetail } from '../models/ecg-lead-data-detail';
import { DxChartComponent } from 'devextreme-angular';

interface leftRightPoint {
  leftPoint: { y1: number; x1: number };
  rightPoint: { y2: number; x2: number };
}

@Component({
  selector: 'nida-web-ecg-detail',
  templateUrl: './ecg-detail.component.html',
  styleUrls: ['./ecg-detail.component.scss'],
})
export class EcgDetailComponent implements OnInit {
  @ViewChild('testSelector', { static: false }) dataGrid: any;
  @ViewChild('channels', { static: false }) chart1: DxChartComponent;

  @Input() channel: string;
  @Input() data: EcgLead;
  @Input() visualRange: VisualRange;
  @Input() selectedRangeArguments: VisualRange;
  @Input() zoomRange: ZoomRange;
  @Input() intervals: TickIntervals;
  @Input() detailMode: number;
  @Input() clickedPoints: DetailPointStorage;

  @Output() point: EventEmitter<any> = new EventEmitter();
  @Output() range: EventEmitter<any> = new EventEmitter();

  metaData: EcgLeadMetaDetail;
  chartData: Array<EcgLeadDataDetail>;
  scaleOptions: object;
  pointToTimeFactor: number | null;
  selectedRangeValues: RangeValues;
  lastHoveredPoint: CrosshairPoint;
  detailPointStorage: DetailPointStorage;
  mVFactor: number;

  crosshairPointColor: string;
  chartColor: string;
  shutterOptions: object;
  minorTicks: object;
  majorTicks: object;

  loaded = false;

  constructor(private zone: NgZone) {
    this.channel = '';
    this.data = {} as EcgLead;
    this.visualRange = {} as VisualRange;
    this.selectedRangeArguments = {} as VisualRange;
    this.zoomRange = {} as ZoomRange;
    this.intervals = {} as TickIntervals;
    this.detailMode = 0;
    this.clickedPoints = {
      point0: new CrosshairPoint(),
      point1: new CrosshairPoint(),
    };

    this.metaData = {
      refined: false,
      timePerSample: 0,
      valueMin: 0,
      valueMax: 0,
      valueBaseline: 0,
      length: 0,
    };

    this.chartData = [];
    this.scaleOptions = {};
    this.pointToTimeFactor = null;
    this.selectedRangeValues = {} as VisualRange;
    this.lastHoveredPoint = {} as CrosshairPoint;
    this.detailPointStorage = {
      point0: { argument: null, value: null },
      point1: { argument: null, value: null },
    };
    this.mVFactor = 80;

    this.crosshairPointColor = 'rgb(240, 91, 65)';
    this.chartColor = 'cornflowerblue';
    this.shutterOptions = { opacity: 0.4 };
    this.minorTicks = { color: 'black', opacity: 0.1 };
    this.majorTicks = { color: 'black', opacity: 0.15 };
  }

  ngOnInit() {
    this.getAndSetData();
    this.definePointToTimeFactor();
    this.triggerPointCompletion();
    this.triggerRangeManagement();
  }
  point0IsValid() {
    return this.detailPointStorage.point0.argument !== null && this.detailPointStorage.point0.value !== null;
  }
  point1IsValid() {
    return this.detailPointStorage.point1.argument !== null && this.detailPointStorage.point1.value !== null;
  }

  getAndSetData() {
    this.metaData = { ...this.data.metadata };
    this.chartData = this.data.data ? this.data.data : this.chartData;
    this.setMVFactor();
    this.prepareData();

    this.loaded = true;
  }

  setMVFactor() {
    this.mVFactor = this.data.data ? this.data.data[0].gainControl : this.mVFactor;
  }

  prepareData() {
    if (!this.metaData.refined) {
      for (const dataPoint of this.chartData) {
        dataPoint.id = dataPoint.id * 25;
        dataPoint.value = dataPoint.value / this.mVFactor;
        dataPoint.bottomLine = 3.0;
        dataPoint.topLine = 4.0;
        if (dataPoint.synd) {
          dataPoint.qrs = 2.8;
        } else {
          dataPoint.qrs = null;
        }
      }
      this.metaData.refined = true;
    }
  }

  definePointToTimeFactor() {
    this.pointToTimeFactor = this.metaData.timePerSample / 1000;
  }

  triggerPointCompletion() {
    this.setAllPointValuesByArgument();
    this.setAllRangeValuesByArguments();
  }

  setAllPointValuesByArgument() {
    Object.keys(this.detailPointStorage).forEach((point: string) => {
      const argument = this.clickedPoints[point].argument;

      if (argument !== null) {
        const pointIndex = this.getLeftPointByValue(argument);

        const timeValue = this.getArgumentValue(pointIndex);

        this.setSelectedPoint(point, argument, timeValue);
      } else {
        this.setSelectedPoint(point, null, null);
      }
    });
  }

  setAllRangeValuesByArguments() {
    this.setRangeValuesByArguments();
  }

  setRangeValuesByArguments() {
    const startArgument = this.selectedRangeArguments.startValue;
    const endArgument = this.selectedRangeArguments.endValue;
    // ...selectedRange = x values provided by the sliders
    // ...if changed: setRangeValuesByArguments;

    try {
      this.selectedRangeValues.startValue = this.calculateExactPointValue(startArgument);
    } catch {
      this.selectedRangeValues.startValue = NaN;
    }

    try {
      this.selectedRangeValues.endValue = this.calculateExactPointValue(endArgument);
    } catch {
      this.selectedRangeValues.endValue = NaN;
    }
  }

  calculateExactPointValue(rawVal: number) {
    const pointsArray = this.createPointsArray(rawVal);

    const m: number = (pointsArray.rightPoint.y2 - pointsArray.leftPoint.y1) / (pointsArray.rightPoint.x2 - pointsArray.leftPoint.x1);
    const c: number = pointsArray.leftPoint.y1 - m * pointsArray.leftPoint.x1;
    // ...m = slope
    // ...c = constant

    return Math.round((rawVal * m + c) * 100) / 100;
  }

  createPointsArray(rawVal: number): leftRightPoint {
    const leftDataPoint = this.getLeftPointByValue(rawVal);
    const leftPoint = this.chartData[leftDataPoint].id;

    let rightPoint: number;
    let rightDataPoint: number = leftDataPoint + 1;

    try {
      rightPoint = this.chartData[rightDataPoint].id;
    } catch {
      rightPoint = leftPoint + 1; // ...+ 1, to prevent x1 - x2 becoming 0 at the last point (dividing with 0 => NaN)
      rightDataPoint = leftDataPoint;
    }

    return {
      leftPoint: { x1: leftPoint, y1: this.getArgumentValue(leftDataPoint) },
      rightPoint: { x2: rightPoint, y2: this.getArgumentValue(rightDataPoint) },
    };
  }

  getLeftPointByValue(ms: number) {
    /*
      ms = miliseconds (e.g. 99975)
      ms / 25 = array index (99975 / 25 = 3999)
      => the y value for the 99975 ms point of time can be found in 3999th element of the data array)
     */
    let returnValue: number;

    if (this.pointToTimeFactor !== null) {
      const rawPointValue = ms / this.pointToTimeFactor;
      returnValue = Math.floor(rawPointValue);
    } else {
      returnValue = Math.floor(ms);
    }

    return returnValue;
    // ...return e.g. 400
  }

  getArgumentValue(value: number) {
    return this.chartData[value].value;
  }

  setSelectedPoint(point: string, argument: number | null, value: number | null) {
    this.detailPointStorage[point].argument = argument;
    this.detailPointStorage[point].value = value;
  }

  resetAllPoints() {
    Object.keys(this.detailPointStorage).forEach((point: any) => {
      this.resetSinglePoint(point);
    });
  }

  resetSinglePoint(pointName: string) {
    this.detailPointStorage[pointName] = { argument: null, value: null };
  }

  triggerRangeManagement() {
    this.selectedRangeArguments = this.visualRange;
    this.setRangeValuesByArguments();
  }
  getDetailMode() {
    return this.detailMode;
  }

  customizeTextSliderMarker = (value: any) => {
    let returnValue = `${(value.value / 10000).toFixed(2)} sec\n`;
    try {
      const pointValue: number = this.calculateExactPointValue(value.value);
      returnValue = `${returnValue}${pointValue} mV`;
    } catch {
      returnValue = `${returnValue}-.-- mV`;
    }
    return returnValue;
  };

  customizeTextArgumentAxisLabel = (value: any) => {
    try {
      return (value.value / 10000).toFixed(2);
    } catch {
      return NaN;
    }
  };

  refineMaxValue(maxVal: number) {
    const correctionFactor = 11;
    /*
     On top of chart additionally space added: 1/11 (9,09%)
     Max minus 1/11 of distance
     */
    return maxVal - (maxVal - this.zoomRange.bottom) / correctionFactor;
  }

  customizeHorizontalCrosshairLabel = (value: any) => `${value.point.data.value}`;

  setHoveredPoint(e: any) {
    this.lastHoveredPoint = e.target;
  }

  triggerPointEmission(argument?: number | null | undefined) {
    /*
     For crosshair approach (!): emitting chosen point
     */

    if (argument === undefined) {
      if (this.lastHoveredPoint.argument !== undefined) {
        this.point.emit(this.lastHoveredPoint.argument);
      } else {
        // pass
      }
    } else {
      this.point.emit(argument);
    }
  }

  triggerRangeEmission() {
    /*
     For range slider approach (!): emitting chosen range
     */
    this.range.emit(this.selectedRangeArguments);
  }

  // ...............
  // basic functions
  // ...............

  forceRefreshing() {
    /*
     forces the rerendering of the datagrid
     */
    this.zone.run(() => {});
  }
}
