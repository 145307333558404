import { Component, Input, OnInit } from '@angular/core';
import { DateService } from '@nida-web/core';
import type { Patient, PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'nida-web-patient-personal-data',
  templateUrl: './patient-personal-data.component.html',
  styleUrls: ['./patient-personal-data.component.scss'],
})
export class PatientPersonalDataComponent implements OnInit {
  @Input() loaded: boolean; // TODO: unused; delete propably?

  @Input() patientData: PatientView;
  @Input() detailData: Patient;

  constructor(protected dateService: DateService, private translocoService: TranslocoService) {}

  ngOnInit(): void {}

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }

  concatAddress(): string {
    // join all elements to one string, but only join them when they are filled, so displayNan Pipe can do it's job
    return [this.detailData.street, this.detailData.houseNumber, this.detailData.postcode, this.detailData.city].filter(Boolean).join(' ');
  }

  formatAgeInformation(): string {
    let ageString = '';
    if (this.patientData.age && this.patientData.ageType) {
      ageString = [this.patientData.age, this.translocoService.translate(this.patientData.ageType)].join(' ');
    }
    return ageString;
  }
}
