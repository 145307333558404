import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class REDSettingsService {
  private redConfigSubj: Subject<number | undefined>;

  constructor() {
    this.redConfigSubj = new ReplaySubject<number | undefined>(1);
  }

  public updateMaxMissions(maxNumberOfMissions: number | undefined): void {
    this.redConfigSubj.next(maxNumberOfMissions);
  }

  public getMaxMissions(): Observable<number | undefined> {
    return this.redConfigSubj.asObservable();
  }
}
