import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Kis } from '../models/kis.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { KisStoreService } from '../services/store/kis.store.service';
import { ColumnEntry, ColumnProviderService, DateService, ScreenService } from '@nida-web/core';
import dayjs from 'dayjs';
import { ExportToExcelFileService } from '@nida-web/shared/utils';

@Component({
  selector: 'nida-web-kis-list',
  templateUrl: './kis-list.component.html',
  styleUrls: ['./kis-list.component.scss'],
})
export class KisListComponent implements OnInit {
  @ViewChild('kisGrid', { static: false }) dataGrid: DxDataGridComponent | undefined;
  @Input() insuredNumber: string | undefined;
  @Input() patientFirstName: string | undefined;
  @Input() patientLastName: string | undefined;
  @Input() patientBirthdate: string | undefined;
  @Input() nidaId: string | undefined;
  @Input() protocolId: number | undefined;

  // Need to use any because of strange error:
  // Type {exportAll?: string, exportSelectedRows?: string, exportTo?: string}
  // is not assignable to type {exportAll?: string, exportSelectedRows?: string, exportTo?: string}
  // public exportTexts: {
  //   exportAll?: string;
  //   exportSelectedRows?: string;
  //   exportTo?: string;
  // };
  public exportTexts: any;
  public filter: boolean;
  public data: Kis[];
  public columns: ColumnEntry[];
  public columnHovered: Record<string, unknown>;
  public tooltip: boolean;
  public resetTooltipVisible: boolean;
  private filtersApplied: boolean;

  constructor(
    protected dateService: DateService,
    private columnService: ColumnProviderService,
    protected kisListStoreService: KisStoreService,
    private screenService: ScreenService,
    protected zone: NgZone,
    public exportToExcelFileService: ExportToExcelFileService
  ) {
    this.data = [];
    this.columns = [];
    this.columnHovered = {};
    this.tooltip = false;
    this.filter = false;
    this.filtersApplied = false;
    this.insuredNumber = '';
    this.patientFirstName = '';
    this.patientLastName = '';
    this.resetTooltipVisible = false;
    this.exportTexts = {
      exportAll: '',
    };
    this.columnService.buildTableColumns('KisColumns', true);
  }

  ngOnInit(): void {
    this.data = this.kisListStoreService.getKisList(true);
    this.getColumns();
    this.initializeColumnHovered();
  }
  getScreen(): string {
    return this.screenService.screenSize;
  }

  getColumns(): void {
    this.columnService.kisColumnsLoaded.subscribe((loaded) => {
      if (loaded) {
        const columns = this.columnService.getTableColumns('KisColumns');
        columns.forEach((column) => (column.filterValue = undefined));
        this.columns = columns;
      }
    });
    this.applyFilter2Column();
  }

  applyFilter2Column(): void {
    // replace with if insurance number is known/filled
    if (this.insuredNumber && this.insuredNumber.length > 0) {
      this.filter = true;
      const insuranceColumn = this.columns.find((column) => column.name === 'insuredNumber');
      if (insuranceColumn) {
        insuranceColumn.filterValue = this.insuredNumber;
      }
    } else if (this.patientFirstName || this.patientLastName || this.patientBirthdate) {
      this.filter = true;
      const firstNameColumn = this.columns.find((column) => column.name === 'patientFirstName');
      if (firstNameColumn && this.patientFirstName && this.patientFirstName.length > 0) {
        firstNameColumn.filterValue = this.patientFirstName;
      }
      const lastNameColumn = this.columns.find((column) => column.name === 'patientLastName');
      if (lastNameColumn && this.patientLastName && this.patientLastName.length > 0) {
        lastNameColumn.filterValue = this.patientLastName;
      }
      const birthdateColumn = this.columns.find((column) => column.name === 'patientBirthdate');
      const date = dayjs(this.patientBirthdate, 'YYYY-MM-DD', true);
      if (birthdateColumn && this.patientBirthdate && date.isValid()) {
        birthdateColumn.filterValue = this.patientBirthdate;
      }
    }
  }

  changeFilterVisibility(): void {
    /*
     Opens / closes filter row above data grid
     */
    this.filter = !this.filter;
    this.initializeColumnHovered();
  }

  changeColumnVisibility(): void {
    /*
     Opens / closes column chooser above data grid
     */
    this.tooltip = !this.tooltip;
    this.setChooserVisibility();
  }

  initializeColumnHovered(): void {
    /*
     creates an object with all columns (as int, e.g. 0)
     sets every int in object to a bool (default: false)
     when column 0 is hovered: sets 0: true in columnHovered object;
     */
    const tempDict = {};
    let index: number;
    for (index = 0; index < this.columns.length; index++) {
      tempDict[index] = false;
    }

    this.columnHovered = tempDict;
  }

  setChooserVisibility(): void {
    /*
     manages the columnChooserVisibility:
     true => false
     false => true
     */
    if (this.dataGrid) {
      const instance = this.dataGrid.instance;
      if (this.tooltip) {
        instance.showColumnChooser();
      } else {
        instance.hideColumnChooser();
      }
    }
  }

  resetListState(kisGrid: DxDataGridComponent): void {
    kisGrid.instance.state({});
  }

  applyFilterToState(kisGrid: DxDataGridComponent): void {
    if (!this.filtersApplied) {
      this.filtersApplied = true;
      const state = kisGrid.instance.state();
      const columns = state.columns;

      if (this.insuredNumber && this.insuredNumber.length > 0) {
        this.filter = true;
        const insuranceColumn = columns.find((column: any) => column.dataField === 'insuredNumber');
        if (insuranceColumn) {
          insuranceColumn.filterValue = this.insuredNumber;
        }
      } else if (this.patientFirstName || this.patientLastName || this.patientBirthdate) {
        this.filter = true;
        const firstNameColumn = columns.find((column: any) => column.dataField === 'patientFirstName');
        if (firstNameColumn && this.patientFirstName && this.patientFirstName.length > 0) {
          firstNameColumn.filterValue = this.patientFirstName;
        }
        const lastNameColumn = columns.find((column: any) => column.dataField === 'patientLastName');
        if (lastNameColumn && this.patientLastName && this.patientLastName.length > 0) {
          lastNameColumn.filterValue = this.patientLastName;
        }
        const birthdateColumn = columns.find((column: any) => column.dataField === 'patientBirthdate');
        const date = dayjs(this.patientBirthdate, 'YYYY-MM-DD', true);
        if (birthdateColumn && this.patientBirthdate && date.isValid()) {
          birthdateColumn.filterValue = this.patientBirthdate;
        }
      }
      kisGrid.instance.state(state);
    }
  }

  toggleResetTooltip() {
    this.resetTooltipVisible = !this.resetTooltipVisible;
  }

  forceRerender(): void {
    /*
     forces the rendering of the data grid
     => hovering does not seem to activate Angular rendering
     */
    this.zone.run(() => {});
  }

  // DevExpress does not provide types for Events, sadly :-(
  onToolbarPreparing(e: any): void {
    /*
     Function creates a toolbar with specific adjustments;
     Function is called one, when data grid is build;
     */
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'newToolbar',
    });
  }

  // DevExpress does not provide types for Events, sadly :-(
  onRowExpanding(e: any): void {
    e.component.collapseAll(-1);
  }

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }
}
