import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { PatientView, PatientListServable } from '@nida-web/api/generic-interfaces/patient-management';
import { MQTTSubscribeListenerService } from '@nida-web/api-mqtt-wrapper';
import { ModuleSettingsService } from '@nida-web/core';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { NotificationBrokerService } from '@nida-web/shared/notifications';

@Injectable({
  providedIn: 'root',
})
export class PatientListStoreService {
  public patientsLoaded: BehaviorSubject<boolean>;

  private patients: PatientView[];
  private refreshTimer?: Observable<any>;
  private timerSubscription?: Subscription;
  private userAuthenticated: boolean;

  private renewListSubject: Subject<boolean>;

  private autoUpdate: boolean;

  constructor(
    private mqttSubscribeListenerService: MQTTSubscribeListenerService,
    private notificationServable: NotificationBrokerService,
    private patientListServable: PatientListServable,
    private sessionManager: SessionManagerService,
    private moduleSettingsService: ModuleSettingsService
  ) {
    this.renewListSubject = new ReplaySubject<boolean>(1);
    this.patients = [];
    this.autoUpdate = false;
    this.moduleSettingsService.getSettings().subscribe((conf) => {
      this.autoUpdate = conf.autoUpdatePatientlist;
    });
    this.userAuthenticated = false;
    this.patientsLoaded = new BehaviorSubject(false);
    this.sessionManager.getSessionInformation().subscribe((userObject) => {
      if (userObject.loggedIn) {
        this.userAuthenticated = true;
        this.refreshTimer = timer(0);
        this.timerSubscription = this.refreshTimer.subscribe(() => {
          this.renewList();
        });
      } else {
        this.patients = [];
        this.userAuthenticated = false;
      }
    });

    this.mqttSubscribeListenerService.getMqttConfigLoadedObservable().subscribe((configLoaded) => {
      if (configLoaded) {
        for (const topic of this.mqttSubscribeListenerService.getTopics()) {
          console.log('Listening on topic', topic);
          this.mqttSubscribeListenerService.registerTopic(topic).subscribe(() => {
            console.log('on topic', topic);
            this.renewList();
          });
        }
      }
    });
  }

  public getPatientList(): PatientView[] {
    return this.patients;
  }

  public renewList(): void {
    if (!this.userAuthenticated || !this.autoUpdate) {
      return;
    }
    this.patientListServable.getPatientList(0).subscribe({
      next: (patientList) => {
        if (this.timerSubscription !== undefined) {
          this.timerSubscription.unsubscribe();
        }
        let protocolIdBorder = -1;
        if (patientList.length === 0) {
          this.patients.splice(0, this.patients.length);
        }
        for (const newPatient of patientList) {
          // alreadyIn is false bis patient in bereits geladenen Daten per NIDA ID gefunden wird
          let alreadyIn = false;
          // skip the entry if patient is marked for archive list
          if (newPatient.archived === true) {
            const indexOfArchivedPatient = this.patients.findIndex((patient) => patient.protocolId === newPatient.protocolId);
            if (indexOfArchivedPatient >= 0) {
              this.patients.splice(indexOfArchivedPatient, 1);
            }
            continue;
          }
          // protocolIdBorder identifizieren des neuest/höchsten Protkolls
          if (protocolIdBorder === -1 || protocolIdBorder > newPatient.protocolId) {
            protocolIdBorder = newPatient.protocolId;
          }
          for (let i = 0; i < this.patients.length; i++) {
            // compare already loaded with those comming from rest by comparing nidaId
            if (this.patients[i].nidaId === newPatient.nidaId) {
              const attachments = this.patients[i].attachments;
              alreadyIn = true;
              this.patients[i].hl7ExportAt = newPatient.hl7ExportAt;
              if (this.patients[i].protocolId < newPatient.protocolId) {
                if (!newPatient.seen) {
                  if (this.patients[i].seen) {
                    this.notificationServable.addToCounter();
                  }
                }
                this.patients[i] = newPatient;
              } else if (this.patients[i].protocolId === newPatient.protocolId && this.patients[i].seen !== newPatient.seen) {
                this.patients[i] = newPatient;
                if (newPatient.seen) {
                  this.notificationServable.remFromCounter();
                }
              } else if (
                (newPatient.attachments !== undefined &&
                  attachments !== undefined &&
                  this.patients[i].protocolId === newPatient.protocolId &&
                  attachments.picture !== newPatient.attachments.picture) ||
                (attachments !== undefined &&
                  newPatient.attachments !== undefined &&
                  this.patients[i].protocolId === newPatient.protocolId &&
                  attachments.pdf !== newPatient.attachments.pdf) ||
                (attachments !== undefined &&
                  newPatient.attachments !== undefined &&
                  attachments.pdfStatus !== newPatient.attachments.pdfStatus)
              ) {
                this.patients[i] = newPatient;
              }
            }
          }
          // patient kommt neu über Rest an die App
          if (!alreadyIn) {
            // patient in Liste aufnehmen
            this.patients.push(newPatient);
            // seen === false heißt was: Protokoll wurde noch nicht geöffnete (Detail Seite)
            if (!newPatient.seen) {
              // counter ++ Roter Kreis
              this.notificationServable.addToCounter();
            }
          }
        }
        for (let i = 0; i < this.patients.length; i++) {
          // alle patieten die vor dem Rest schon in der Liste waren
          if (this.patients[i].protocolId < protocolIdBorder) {
            // seen false heißt was: Protokoll wurde noch nicht geöffnete (Detail Seite)
            if (!this.patients[i].seen) {
              // neue Protkolle welche noch nicht geöffnet wurden vom counter entfernen
              this.notificationServable.remFromCounter();
            }

            this.patients.splice(i, 1);
            i -= 1;
            continue;
          }
          // Delete archived items
          if (patientList.find((pEl) => pEl.nidaId === this.patients[i].nidaId) === undefined) {
            console.log('Archived Element delete');
            this.patients.splice(i, 1);
            i -= 1;
          }
        }
        this.orderPatientsByHighestProtocolId();
        this.renewListSubject.next(true);
        this.refreshTimer = timer(1000 * 60);
        this.timerSubscription = this.refreshTimer.subscribe(() => this.renewList());
        if (!this.patientsLoaded.value) {
          this.patientsLoaded.next(true);
        }
      },
      error: () => {
        this.refreshTimer = timer(5000);
        this.timerSubscription = this.refreshTimer.subscribe(() => this.renewList());
      },
    });
  }

  public getDetailInformation(id: string): PatientView {
    if (this.getPatientsLoaded()) {
      const patient: PatientView = this.patients.filter((element) => element.nidaId === id)[0];
      if (patient && !patient.seen) {
        patient.seen = true;
        this.notificationServable.remFromCounter();
        this.patientListServable.setReadProtocol(patient.nidaId, patient.protocolId).subscribe((result) => {
          if (!result) {
            throw new Error('Error on set Protocol read.');
          }
        });
      }
      return patient;
    } else {
      return new PatientView('', -1);
    }
  }

  public getPatientsLoaded(): boolean {
    return this.patientsLoaded.value;
  }

  public getRenewInformation(): Observable<boolean> {
    return this.renewListSubject.asObservable();
  }

  private orderPatientsByHighestProtocolId() {
    this.patients.sort((a: PatientView, b: PatientView) => {
      if (a.protocolId < b.protocolId) {
        return 1;
      }
      if (a.protocolId > b.protocolId) {
        return -1;
      }
      return 0;
    });
  }
}
