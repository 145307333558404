<div class="row basic-flex-container">
  <div class="col-md-6 mb-1 mb-md-0">
    <div class="basic-sub-card-title">
      {{ 'Pre-registration diagnosis' | transloco }}
    </div>
    @if (patientData) {
    <div class="basic-font-large-bold break-word">
      {{ patientData.diagnosis ? patientData.diagnosis : ('' | transloco | translocoWord | displayNan) }}
    </div>
    }
  </div>
  <div class="col-md-3 col-6">
    <div class="basic-sub-card-title">
      {{ 'Arrival' | transloco }}
    </div>

    <div class="basic-font-large-important-bold arrival">
      {{ formatMoment(patientData.arrivalTime, 'time') | displayNan }}
    </div>
    @if (arrivalIsInFuture) {
    <div class="basic-font-medium-bold arrival">
      <nida-web-countdown [end]="patientData.arrivalTime"></nida-web-countdown>
    </div>
    } @if (!arrivalIsInFuture) {
    <div class="basic-font-medium arrival">
      {{ formatMoment(patientData.arrivalTime, 'date') | displayNan }}
    </div>
    }
  </div>
  <div class="col-md-3 col-6">
    @if (patientData.status !== -1) {
    <div class="basic-sub-card-title">
      {{ 'Priority' | transloco }}
    </div>
    <div class="basic-font-medium">
      @if (patientData.status === 0) {
      <div>
        <div class="basic-padding-small basic-rounded-corners priority-0 font-weight-bold">
          {{ 'Normal' | transloco }}
        </div>
      </div>
      } @if (patientData.status === 1) {
      <div>
        <div class="basic-padding-small basic-rounded-corners priority-1 font-weight-bold">
          {{ 'Urgent' | transloco }}
        </div>
      </div>
      } @if (patientData.status === 2) {
      <div>
        <div class="basic-padding-small basic-rounded-corners priority-2 font-weight-bold">
          {{ 'Immediately' | transloco }}
        </div>
      </div>
      }
    </div>
    }
  </div>
</div>

<div class="row mx-0 p-0 mt-2">
  <div class="col-md-12 col-lg-12 col-xl-12 m-0 p-0">
    <div class="row">
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Breathing / Ventilation (A/B)' | transloco }}
        </div>
        <div class="basic-font-medium">
          @switch (findingData.preregistration_respiratory) { @case ('unbeeinträchtigt') {
          <span class="intubation-value">{{ 'unimpared' | transloco }}</span>
          } @case ('beeinträchtigt') {
          <span class="attention-text intubation-value">{{ 'impared' | transloco }}</span>
          } @case ('nicht-invasiv beatmet') {
          <span class="attention-text intubation-value">{{ 'non-invasively ventilated' | transloco }}</span>
          } @case ('invasiv beatmet') {
          <span class="attention-text intubation-value">{{ 'invasively ventilated' | transloco }}</span>
          } @default {
          <span class="intubation-value">{{ findingData.preregistration_respiratory | displayNan }}</span>
          } }
        </div>
      </div>
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Circulation (C)' | transloco }}
        </div>
        <div class="basic-font-medium">
          @switch (findingData?.preregistration_circulation) { @case ('stabil') {
          <span class="circulation-value">{{ 'Stable_lowercase' | transloco }}</span>
          } @case ('stabil unter Therapie') {
          <span class="circulation-value">{{ 'stable under therapy' | transloco }}</span>
          } @case ('kontinuierliche Katecholamintherapie') {
          <span class="attention-text circulation-value">{{ 'continuous catecholamine therapy' | transloco }}</span>
          } @case ('instabil unter Therapie') {
          <span class="attention-text circulation-value">{{ 'unstable under therapy' | transloco }}</span>
          } @case ('instabil') {
          <span class="attention-text circulation-value">{{ 'Unstable_lowercase' | transloco }}</span>
          } @default {
          <span class="circulation-value">{{ findingData?.preregistration_circulation | displayNan }} </span>

          } }
        </div>
      </div>
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Consciousness (D)' | transloco }}
        </div>
        <div class="basic-font-medium">
          @switch (findingData?.preregistration_consciousness) { @case ('wach') {
          <span>{{ 'awake' | transloco }}</span>
          } @case ('getrübt') {
          <span class="attention-text">{{ 'clouded' | transloco }}</span>
          } @case ('bewusstlos') {
          <span class="attention-text">{{ 'unconscious' | transloco }}</span>
          } @case ('sediert / narkotisiert') {
          <span class="attention-text">{{ 'sedated / narcotized' | transloco }}</span>
          } @default {
          <span>{{ findingData?.preregistration_consciousness | displayNan }} </span>
          } }
        </div>
      </div>
    </div>
  </div>
  @if (findingData !== undefined) {
  <div class="col-md-12 col-lg-12 col-xl-12 m-0 p-0">
    <div class="row mt-2 mt-md-2">
      <div class="col-1">
        <div class="basic-sub-card-title">{{ 'GCS' | transloco }}</div>
        <div class="basic-font-medium" data-cy="gcs-value" [class.attention-text]="initialGcs && initialGcs <= 8 && initialGcs >= 0">
          {{ findingData.initial_gcs | displayNan }}
        </div>
      </div>
      <div class="col-1">
        <div class="basic-sub-card-title">{{ 'Gcseyes' | transloco }}</div>
        <div class="basic-font-medium">{{ findingData?.initial_gcs_eyes | displayNan }}</div>
      </div>
      <div class="col-1">
        <div class="basic-sub-card-title">{{ 'Gcsmotorics' | transloco }}</div>
        <div class="basic-font-medium">{{ findingData?.initial_gcs_motoric | displayNan }}</div>
      </div>
      <div class="col-3">
        <div class="basic-sub-card-title">{{ 'Gcsverbal' | transloco }}</div>
        <div class="basic-font-medium">{{ findingData?.initial_gcs_verbal | displayNan }}</div>
      </div>
      <div class="col-2">
        <div class="basic-sub-card-title">
          {{ 'I-Status' | transloco }}
        </div>
        <div class="basic-font-medium">
          @if(findingData?.preregistration_infection_status === 'ja') {
          <span class="attention-text"> {{ findingData?.preregistration_infection_status }}</span>
          } @else {
          {{ findingData?.preregistration_infection_status }}
          }
        </div>
      </div>
      @if (findingData?.preregistration_emergency_room) {
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Emergency Room' | transloco }}
        </div>

        <div class="basic-font-medium">
          @switch (findingData.preregistration_emergency_room) { @case ('Ja') {
          <span class="attention-text">{{ 'Yes' | transloco }}</span>
          } @case ('Nein') {
          <span>{{ 'No' | transloco }}</span>
          } @default {
          <div class="attention-text">{{ findingData.preregistration_emergency_room | displayNan }}</div>
          } }
        </div>
      </div>
      } @if (findingData?.preregistration_acs_transfer_point) {
      <div class="col-4">
        <div class="basic-sub-card-title">
          {{ 'Transfer Point' | transloco }}
        </div>

        <div class="basic-font-medium">
          @switch (findingData.preregistration_acs_transfer_point) { @case ('Nein') {
          <span>{{ findingData.preregistration_acs_transfer_point }}</span>
          } @default {
          <div class="attention-text">{{ findingData.preregistration_acs_transfer_point | displayNan }}</div>
          } }
        </div>
      </div>
      }
    </div>
    <div class="row mt-2 mt-md-2">
      <div class="col-12">
        <div class="basic-sub-card-title">
          {{ 'Registration text' | transloco }}
        </div>
        @if (findingData) {
        <div class="basic-font-medium">
          {{ findingData.supplement1value | displayNan }}
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>

@if (this.findingData?.preregistration_pediatrics_weight) {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">{{ 'Pediatric information' | transloco }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mb-1">
      <div>Gewicht</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_pediatrics_weight | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @switch (patientData.diagnosis) { @case ('Schlaganfall') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
          @if (timeSinceFindingText) {
          <span>(Seit {{ timeSinceFindingText }})</span>
          }
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Anticoagulants' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_anticoagulants | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Antiplatelet drug' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_platelet_aggregation_inhibitors | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Begin of symptoms' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ eventTime }}
          @if (findingData?.preregistration_event_time_unknown) {
          {{ findingData?.preregistration_event_time_unknown }}
          }
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Last-Seen-Well' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ lastSeenWellTime }}
          @if (findingData?.preregistration_stroke_last_seen_well_text) {
          {{ findingData?.preregistration_stroke_last_seen_well_text }}
          }
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Prä-mRS' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_prae_mrs | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Injuries / Surgeries' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_injuries | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Medical history' | transloco }}</div>
      <div class="basic-font-medium">
        @if (findingData?.preregistration_stroke_history_afib) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_afib }}
        </div>
        } @if (findingData?.preregistration_stroke_history_epilepsy) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_epilepsy }}
        </div>
        } @if (findingData?.preregistration_stroke_history_migraine) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_migraine }}
        </div>
        } @if (findingData?.preregistration_stroke_history_diabetes) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_diabetes }}
        </div>
        } @if (findingData?.preregistration_stroke_history_hypertension) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_hypertension }}
        </div>
        } @if (findingData?.preregistration_stroke_history_coronar_artery_disease) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_coronar_artery_disease }}
        </div>
        } @if (findingData?.preregistration_stroke_history_heart_failure) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_heart_failure }}
        </div>
        } @if (findingData?.preregistration_stroke_history_pavk) {
        <div class="text-black">
          {{ findingData.preregistration_stroke_history_pavk }}
        </div>
        }
      </div>
    </div>
  </div>
</div>
} @case ('Krampfanfall') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-1">
      <div>{{ 'Condition' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_seizure_state | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @case ('ACS') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
          @if (timeSinceFindingText) {
          <span>(Seit {{ timeSinceFindingText }})</span>
          }
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Begin of pain' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ eventTime }}
          @if (findingData?.preregistration_event_time_unknown) {
          {{ findingData?.preregistration_event_time_unknown }}
          }
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Pain' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.pain | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Pain radiation' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_pain_radiation | displayNan }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'ACS Spectrum' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_spectrum | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 mb-1">
      <div>{{ 'Anticoagulants' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_anticoagulants | displayNan }}
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1">
          {{ 'Measures' | transloco }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Intubation' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_intubated | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Catecholamine dose' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_catecholamine | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Reanimation' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_reanimation | displayNan }}
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1">
          {{ 'Coronary heart disease history' | transloco }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Hearth catheter' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_heart_catheter | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Bypass' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_heart_bypass | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 mb-1">
      <div>{{ 'Heart attack' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_acs_heart_attack | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @case ('Herzstillstand') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Suspected cause' | transloco }}</div>
      <div class="basic-font-medium">
        @if (findingData?.preregistration_cardiac_arrest_myocardial_infarction) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_myocardial_infarction }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_hypoxia) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_hypoxia }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_pulmonary_embolism) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_pulmonary_embolism }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_hypovolemia) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_hypovolemia }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_glycemia) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_glycemia }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_pericardial_tamponade) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_pericardial_tamponade }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_intoxication) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_intoxication }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_pneumothorax) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_pneumothorax }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_unknown) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_unknown }}
        </div>
        } @if (findingData?.preregistration_cardiac_arrest_other) {
        <div class="text-black">
          {{ findingData.preregistration_cardiac_arrest_other }}
        </div>
        }
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Initial rhythm' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_cardiac_arrest_initial_rhythm | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 mb-1">
      <div>{{ 'Condition' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_cardiac_arrest_condition | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @case ('Polytrauma') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-1">
      <div>{{ 'Internal injuries' | transloco }}</div>
      <div class="basic-font-medium">
        @if (findingData?.preregistration_poly_internal_efast) {
        <div class="text-black">
          {{ findingData.preregistration_poly_internal_efast }}
        </div>
        } @if (findingData?.preregistration_poly_internal_shock_index) {
        <div class="text-black">
          {{ findingData.preregistration_poly_internal_shock_index }}
        </div>
        } @if (findingData?.preregistration_poly_internal_other) {
        <div class="text-black">
          {{ findingData.preregistration_poly_internal_other }}
        </div>
        }
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Visible injuries' | transloco }}</div>
      <div class="basic-font-medium">
        @if (findingData?.preregistration_poly_visible_unstable_thorax) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_unstable_thorax }}
        </div>
        } @if (findingData?.preregistration_poly_visible_unstable_pelvis) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_unstable_pelvis }}
        </div>
        } @if (findingData?.preregistration_poly_visible_trunk_neck) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_trunk_neck }}
        </div>
        } @if (findingData?.preregistration_poly_visible_amputation) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_amputation }}
        </div>
        } @if (findingData?.preregistration_poly_visible_spinal_injury) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_spinal_injury }}
        </div>
        } @if (findingData?.preregistration_poly_visible_intervention) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_intervention }}
        </div>
        } @if (findingData?.preregistration_poly_visible_fracture) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_fracture }}
        </div>
        } @if (findingData?.preregistration_poly_visible_burn) {
        <div class="text-black">
          {{ findingData.preregistration_poly_visible_burn }}
        </div>
        }
      </div>
    </div>
  </div>
</div>
} @case ('SHT') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mb-1">
      <div>{{ 'Relevant symptoms' | transloco }}</div>
      <div class="basic-font-medium">
        @if (findingData?.preregistration_tbi_symptom_brain_pressure) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_brain_pressure }}
        </div>
        } @if (findingData?.preregistration_tbi_symptom_pupil_difference) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_pupil_difference }}
        </div>
        } @if (findingData?.preregistration_tbi_symptom_accompanying) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_accompanying }}
        </div>
        } @if (findingData?.preregistration_tbi_symptom_amnesia) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_amnesia }}
        </div>
        } @if (findingData?.preregistration_tbi_symptom_visual_disturbance) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_visual_disturbance }}
        </div>
        } @if (findingData?.preregistration_tbi_symptom_unknown) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_unknown }}
        </div>
        } @if (findingData?.preregistration_tbi_symptom_other) {
        <div class="text-black">
          {{ findingData.preregistration_tbi_symptom_other }}
        </div>
        }
      </div>
    </div>
  </div>
</div>
} @case ('gastrointestinale Blutung') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
          @if (timeSinceFindingText) {
          <span>({{ 'Since' | transloco }} {{ timeSinceFindingText }})</span>
          }
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mb-1">
      <div>{{ 'Begin of symptoms' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ eventTime }}
          @if (findingData?.preregistration_event_time_unknown) {
          {{ findingData?.preregistration_event_time_unknown }}
          }
        </div>
      </div>
    </div>
    <div class="col-sm-12 mb-1">
      <div>{{ 'Specific symptoms' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_gastrointestinal_bleeding_symptom_type | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @case ('Sepsis') {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ patientData.diagnosis }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mb-1">
      <div>{{ 'Suspected sources of sepsis' | transloco }}</div>
      <div class="basic-font-medium">
        @if (findingData?.preregistration_sepsis_skin) {
        <div class="text-black">{{ findingData.preregistration_sepsis_skin }}</div>
        } @if (findingData?.preregistration_sepsis_urinary_and_reproductive_organs) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_urinary_and_reproductive_organs }}
        </div>
        } @if (findingData?.preregistration_sepsis_umbilical_newborns) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_umbilical_newborns }}
        </div>
        } @if (findingData?.preregistration_sepsis_ent_area) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_ent_area }}
        </div>
        } @if (findingData?.preregistration_sepsis_intestine) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_intestine }}
        </div>
        } @if (findingData?.preregistration_sepsis_biliary_tract) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_biliary_tract }}
        </div>
        } @if (findingData?.preregistration_sepsis_heart) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_heart }}
        </div>
        } @if (findingData?.preregistration_sepsis_unknown) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_unknown }}
        </div>
        } @if (findingData?.preregistration_sepsis_other) {
        <div class="text-black">
          {{ findingData.preregistration_sepsis_other }}
        </div>
        }
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ 'qSOFA' | transloco }} {{ 'Sum' | transloco }} {{ findingData?.preregistration_qsofa_sum | displayNan }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mb-1">
      <div>{{ 'Altered consciousness GCS < 15' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">{{ findingData?.preregistration_qsofa_altered_consciousness | displayNan }}</div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Respiratory rate' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">{{ findingData?.preregistration_qsofa_respiratory_rate | displayNan }}</div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Blood pressure' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">{{ findingData?.preregistration_qsofa_blood_pressure | displayNan }}</div>
      </div>
    </div>
  </div>
</div>
} } @if (patientData.diagnosis === 'Krampfanfall' && findingData?.pees_sum >= 0) {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">{{ 'Prehospital epilepsy emergency score (pees) sum' | transloco }} {{ findingData?.pees_sum }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-1">
      <div>{{ 'Eyes opened during seizure' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_eyes_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_eyes | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Turn of eyes' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_view_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_view | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'One-sided stretched, stiff arm and/or leg' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_arm_leg_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_arm_leg | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'One-sided twitching (no shivering)' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_twitching_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_twitching | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Nervous fidging, narrow lips and confusion' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_mouth_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_mouth | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Extremities stretched on both sides with twitches' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_extremities_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_extremities | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Duration of seizure' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_duration_seizure_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_duration_seizure | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Duration of consciousness' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_duration_consciousness_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_duration_consciousness | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Tongue bite' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_tongue_bite_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_tongue_bite | displayNan }}
        </span>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Epilepsy / Known seizure' | transloco }}</div>
      <div class="basic-font-medium">
        <span class="text-black mr-3">
          {{ findingData?.pees_epilepsy_value | displayNan }}
        </span>
        <span>
          {{ findingData?.pees_epilepsy | displayNan }}
        </span>
      </div>
    </div>
  </div>
</div>
} @if (findingData?.preregistration_stroke_4iss_sum >= 0) {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ '4I-SS' | transloco }}
          @if (findingData?.preregistration_stroke_4iss_sum) {
          <span>{{ 'Sum' | transloco }} {{ findingData?.preregistration_stroke_4iss_sum }}</span>
          }
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-1">
      <div>{{ 'Vigilanz' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_4iss_vigilance | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Head and gaze deviation' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_4iss_turn | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Hemiparesis' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_4iss_hemiparese | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Hemiparesis side' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_4iss_hemiparese_side | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Speech disorder' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_4iss_speech_disorder | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Age' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.preregistration_stroke_4iss_age | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @if (findingData?.stroke_snihss_sum >= 0) {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ 'sNIHSS-EMS' | transloco }}
          @if (findingData?.stroke_snihss_sum) {
          <span>{{ 'Sum' | transloco }} {{ findingData?.stroke_snihss_sum }}</span>
          }
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-1">
      <div>{{ 'State of consciousness' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_snihss_consciousness_nr | displayNan }} {{ findingData?.stroke_snihss_consciousness_text | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Facial paralysis' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_snihss_face_nr | displayNan }} {{ findingData?.stroke_snihss_face_text | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Motor skills' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_snihss_motorics_nr | displayNan }} {{ findingData?.stroke_snihss_motorics_text | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Sensory disturbances' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_snihss_sensitivity_nr | displayNan }} {{ findingData?.stroke_snihss_sensitivity_text | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Speech disorder' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_snihss_speech_impediment_nr | displayNan }}
          {{ findingData?.stroke_snihss_speech_impediment_text | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Slurred speech' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_snihss_washed_out_nr | displayNan }} {{ findingData?.stroke_snihss_washed_out_text | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
} @if (findingData?.stroke_face2ad_sum >= 0) {
<div class="basic-sub-card-title mt-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="basic-card-title">
        <p class="mb-1 text-black">
          {{ 'FACE2AD' | transloco }}
          @if (findingData?.stroke_face2ad_sum) {
          <span>{{ 'Sum' | transloco }} {{ findingData?.stroke_face2ad_sum }}</span>
          }
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-1">
      <div>{{ 'Facial paralysis' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_face_nr | displayNan }} {{ findingData?.stroke_face2ad_face | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Arm paresis' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_parese_arm_nr | displayNan }} {{ findingData?.stroke_face2ad_parese_arm | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Consciousness disorder' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_consciousness_nr | displayNan }} {{ findingData?.stroke_face2ad_consciousness | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Eye deviation / Gaze deviation' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_stoveview_nr | displayNan }} {{ findingData?.stroke_face2ad_stoveview | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Known atrial fibrillation' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_atrialfibrillation_nr | displayNan }}
          {{ findingData?.stroke_face2ad_atrialfibrillation | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Diastolic blood pressure < 85 mmHg' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_rr_dia_85_nr | displayNan }} {{ findingData?.stroke_face2ad_rr_dia_85 | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Leg paresis' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_parese_bein | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Aphasie' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_aphasie | displayNan }}
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-1">
      <div>{{ 'Affected side' | transloco }}</div>
      <div class="basic-font-medium">
        <div class="text-black">
          {{ findingData?.stroke_face2ad_side | displayNan }}
        </div>
      </div>
    </div>
  </div>
</div>
}
