export const environment = {
  defaultApiUrl: 'https://localhost:1443/',
  product: 'NIDAtracker',
  jenkinsDisplayName: 'Production',
  baseUrl: 'http://localhost:4200',
  production: true,
  versionCheckURL: 'version.json',
  configbasepath: './assets/config/', // + APP + KEY
  customer: 'default',
  authType: 'nida',
  baseHref: '',
  serverBaseHref: '',
  Logos: {
    meddv: true,
    ztm: true,
    rescuetrack: false,
  },
};
