<div class="content-block">
  <div class="responsive-paddings">
    <form [formGroup]="resetForm" (submit)="submitForm()">
      <div class="row">
        <div class="col-12 login-header">
          <ng-container *ngFor="let logo of logoSettings; let noBorder = last">
            <img *ngIf="noBorder" alt="{{ logo }}" class="{{ logo }}-logo" src="./assets/img/logos/{{ logo }}.png" style="height: 50px" />
            <img *ngIf="!noBorder" alt="{{ logo }}" class="{{ logo }}-logo logo-border" src="./assets/img/logos/{{ logo }}.png" />
          </ng-container>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="title">{{ 'Reset password' | transloco }}</div>
        </div>
        <div class="row justify-content-center">
          <div class="login-subheader">{{ 'Please enter tenant and user name to reset your password' | transloco }}</div>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="col-lg-4 col-md-8 col-sm-12">
            <div class="dx-field">
              <dx-select-box
                [dataSource]="tenantList"
                [searchEnabled]="true"
                formControlName="clientId"
                placeholder="{{ 'Facility' | transloco }}"
                displayExpr="name"
                valueExpr="id"
              >
                <dx-validator>
                  <dxi-validation-rule message="{{ 'Setup is necessary' | transloco }}" type="required"></dxi-validation-rule>
                </dx-validator>
              </dx-select-box>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="col-lg-4 col-md-8 col-sm-12">
            <div class="dx-field">
              <dx-text-box
                [inputAttr]="{ autofocus: 'autofocus', id: 'loginUserNameInputField' }"
                class="textbox"
                formControlName="username"
                placeholder="{{ 'Username' | transloco }}"
              >
                <dx-validator>
                  <dxi-validation-rule message="{{ 'Username is required' | transloco }}" type="required"></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pb-2">
          <div class="col-lg-4 col-md-8 col-sm-12">
            <dx-button [useSubmitBehavior]="true" text="{{ 'Reset password' | transloco }}" type="normal" width="100%"></dx-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
