import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BloodPressure,
  BloodPressureData,
  BloodSugar,
  BloodSugarData,
  EtCo2,
  HeartRate,
  HeartRateData,
  Hemoglobin,
  Pulse,
  PulseData,
  PulseRegular,
  RespiratoryRate,
  RespiratoryRateData,
  SpCo,
  SpO2,
  SpO2Data,
  Temperature,
  TemperatureData,
  VitalData,
  VitalSignsServable,
} from '@nida-web/api/generic-interfaces/patient-management';
import { isNumber } from '@jsverse/transloco';
import { VitalSignTiles } from '../models/vitalSignTiles.model';
import { VitalSignRefactored } from '../models/vitalSignRefactored.model';
import { DateService } from '@nida-web/core';
import { PulseRegularList } from '@nida-web/api/rest/patient-management';

type allVitalData = RespiratoryRate | BloodSugar | BloodPressure | SpCo | SpO2 | EtCo2 | Hemoglobin | HeartRate | Pulse | Temperature;

@Component({
  selector: 'nida-web-missions-vital-data',
  templateUrl: './patient-vital-data.component.html',
  styleUrls: ['./patient-vital-data.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientVitalDataComponent implements OnChanges {
  @Input() vitalObservable?: Observable<VitalData>;
  @Input() protocolId?: number;

  public bloodPressure: VitalSignTiles;
  public bloodSugar: VitalSignTiles;
  //public etCo2: VitalSignTiles;
  public heartRate: VitalSignTiles;
  //public hemoglobin: VitalSignTiles;
  public pulse: VitalSignTiles;
  public respiratoryRate: VitalSignTiles;
  //public spCo: VitalSignTiles;
  public spO2: VitalSignTiles;
  public temperature: VitalSignTiles;

  constructor(private vitalSignsServable: VitalSignsServable, private dateService: DateService) {
    this.bloodPressure = {
      name: 'Blood pressure',
    };
    this.bloodSugar = {
      name: 'Blood sugar',
    };
    this.heartRate = {
      name: 'Heart rate',
    };
    this.pulse = {
      name: 'Pulse',
    };
    this.respiratoryRate = {
      name: 'Respiratory rate',
    };
    this.spO2 = {
      name: 'SpO2',
    };
    this.temperature = {
      name: 'Temperature',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['protocolId']) {
      const protocolId: number = changes['protocolId'].currentValue;
      if (protocolId && isNumber(protocolId)) {
        this.getData();
      }
    }
  }

  getData(): void {
    if (this.protocolId) {
      this.vitalSignsServable.getAfByProtocolId(this.protocolId).subscribe((data: RespiratoryRateData) => {
        const refinedData: VitalSignRefactored = this.refineRawData(data.data);
        const initialFinding: RespiratoryRate | null = refinedData.initialFinding;
        const lastFinding: RespiratoryRate | null = refinedData.lastFinding;

        this.respiratoryRate = {
          name: 'Respiratory rate',
          unit: initialFinding?.unit,
          initialFinding: {
            firstValue: initialFinding?.value,
            timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
          },
          lastFinding: {
            firstValue: lastFinding?.value,
            timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
          },
          //list: this.filterEntriesByTime(refinedData.list),
        };
      });
      this.vitalSignsServable.getBzByProtocolId(this.protocolId).subscribe((data: BloodSugarData) => {
        const refinedData: VitalSignRefactored = this.refineRawData(data.data);
        const initialFinding: BloodSugar | null = refinedData.initialFinding;
        const lastFinding: BloodSugar | null = refinedData.lastFinding;

        this.bloodSugar = {
          name: 'Blood sugar',
          unit: initialFinding?.unit,
          initialFinding: {
            // valueFloat should always be preferred, because the value is more accurate
            firstValue: initialFinding?.valueFloat ? initialFinding.valueFloat : initialFinding?.value,
            timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
          },
          lastFinding: {
            firstValue: lastFinding?.valueFloat ? lastFinding.valueFloat : lastFinding?.value,
            timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
          },
          //list: this.filterEntriesByTime(refinedData.list),
        };
      });
      this.vitalSignsServable.getBdByProtocolId(this.protocolId).subscribe((data: BloodPressureData) => {
        const refinedData: VitalSignRefactored = this.refineRawBloodPressureData(data);
        const initialFinding: BloodPressure | null = refinedData.initialFinding;
        const lastFinding: BloodPressure | null = refinedData.lastFinding;

        this.bloodPressure = {
          name: 'Blood pressure',
          unit: initialFinding?.unit,
          initialFinding: {
            firstValue: initialFinding?.sys,
            secondValue: initialFinding?.dia,
            timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
          },
          lastFinding: {
            firstValue: lastFinding?.sys,
            secondValue: lastFinding?.dia,
            timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
          },
          //list: this.filterEntriesByTime(refinedData.list),
        };
      });
      /*
      this.vitalSignsServable.getCoByProtocolId(this.protocolId).subscribe((data: SpCoData) => {
        if (data === null) {
          data = { data: [] };
        }
        const refinedData: Array<SpCo> = this.refineRawData(data.data);
        this.rawData['sp_co'] = this.filterEntriesByTime(refinedData, 'latest');
      });
      */
      this.vitalSignsServable.getSpo2ByProtocolId(this.protocolId).subscribe((data: SpO2Data) => {
        const refinedData: VitalSignRefactored = this.refineRawData(data.data);
        const initialFinding: SpO2 | null = refinedData.initialFinding;
        const lastFinding: SpO2 | null = refinedData.lastFinding;

        this.spO2 = {
          name: 'SpO2',
          unit: initialFinding?.unit,
          initialFinding: {
            lowerRightValue: initialFinding?.o2Administration === 'ja' ? 'mit O₂' : initialFinding?.value ? 'ohne O₂' : '',
            firstValue: initialFinding?.value,
            timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
          },
          lastFinding: {
            lowerRightValue: lastFinding?.o2Administration === 'ja' ? 'mit O₂' : lastFinding?.value ? 'ohne O₂' : '',
            firstValue: lastFinding?.value,
            timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
          },
        };
      });
      this.vitalSignsServable.getHfByProtocolId(this.protocolId).subscribe((data: HeartRateData) => {
        const refinedData: VitalSignRefactored = this.refineRawData(data.data);
        const initialFinding: HeartRate | null = refinedData.initialFinding;
        const lastFinding: HeartRate | null = refinedData.lastFinding;
        let initialUpperRightValue = '';
        let lastUpperRightValue = '';

        if (this.protocolId) {
          this.vitalSignsServable.getPulseRegularByProtocolId(this.protocolId).subscribe((data: PulseRegularList) => {
            const refinedData: VitalSignRefactored = this.refineRawData(data.data);
            const initialPulseRegular: PulseRegular | null = refinedData.initialFinding;
            const lastPulseRegular: PulseRegular | null = refinedData.lastFinding;
            initialUpperRightValue = initialPulseRegular?.value ? initialPulseRegular?.value.toString() : '';
            lastUpperRightValue = lastPulseRegular?.value ? lastPulseRegular?.value.toString() : '';

            this.heartRate = {
              name: 'Heart rate',
              unit: initialFinding?.unit,
              initialFinding: {
                lowerRightValue: initialUpperRightValue === 'ja' ? 'regelmäßig' : '',
                firstValue: initialFinding?.value,
                timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
              },
              lastFinding: {
                lowerRightValue: lastUpperRightValue === 'ja' ? 'regelmäßig' : '',
                firstValue: lastFinding?.value,
                timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
              },
            };
          });
        }
      });
      this.vitalSignsServable.getPulsByProtocolId(this.protocolId).subscribe((data: PulseData) => {
        const refinedData: VitalSignRefactored = this.refineRawData(data.data);
        const initialFinding: Pulse | null = refinedData.initialFinding;
        const lastFinding: Pulse | null = refinedData.lastFinding;

        this.pulse = {
          name: 'Pulse',
          unit: initialFinding?.unit,
          initialFinding: {
            firstValue: initialFinding?.value,
            timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
          },
          lastFinding: {
            firstValue: lastFinding?.value,
            timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
          },
        };
      });
      this.vitalSignsServable.getTempByProtocolId(this.protocolId).subscribe((data: TemperatureData) => {
        const refinedData: VitalSignRefactored = this.refineRawData(data.data);
        const initialFinding: Temperature | null = refinedData.initialFinding;
        const lastFinding: Temperature | null = refinedData.lastFinding;

        this.temperature = {
          name: 'Temperature',
          unit: initialFinding?.unit,
          initialFinding: {
            firstValue: initialFinding?.value,
            timestamp: initialFinding?.timestamp ? this.dateService.formatMoment(initialFinding?.timestamp, 'time') : null,
          },
          lastFinding: {
            firstValue: lastFinding?.value,
            timestamp: lastFinding?.timestamp ? this.dateService.formatMoment(lastFinding?.timestamp, 'time') : null,
          },
        };
      });
    }
  }

  refineRawData(data: Array<allVitalData>): VitalSignRefactored {
    const list: Array<allVitalData> = [];
    let initialFinding: allVitalData | null = null;
    let lastFinding: allVitalData | null = null;

    data.forEach((i: allVitalData) => {
      /*
      'eb' and 'ub' has no date. 'verlauf' has always a date.
      it could be possible that multiple 'eb' and 'ub' exist.
      when multiple 'eb' and 'ub' exist, then the one with the higer id number gets set as initialFinding/lastFinding

      the initial finding needs to have description === 'eb'
       */
      switch (i.description) {
        case 'eb': {
          if ((i.id && !initialFinding) || (initialFinding && i.id && initialFinding.id && i.id > initialFinding.id)) {
            initialFinding = i;
          }
          break;
        }
        case 'verlauf': {
          list.push(i);
          if (
            (i.id && !lastFinding) ||
            (lastFinding && lastFinding.description !== 'ue' && i.id && lastFinding.id && i.id > lastFinding.id)
          ) {
            lastFinding = i;
          }
          break;
        }
        // Deactivated because of Roland Richters news: "Letzter im Verlauf, wie der aktuelle NIDAtracker - UE nicht beachten
        // case 'ue': {
        //   if ((i.id && !lastFinding) || lastFinding?.description === 'verlauf' || (i.id && lastFinding?.id && i.id > lastFinding?.id)) {
        //     lastFinding = i;
        //   }
        //   break;
        // }
      }
    });

    return {
      initialFinding,
      lastFinding,
      list,
    };
  }

  refineRawBloodPressureData(data: BloodPressureData): VitalSignRefactored {
    const list: Array<allVitalData> = [];
    let initialFinding: allVitalData | null = null;
    let lastFinding: allVitalData | null = null;

    data.data.forEach((i: BloodPressure) => {
      /*
      'eb' and 'ub' has no date. 'verlauf' has always a date.
      it could be possible that multiple 'eb' and 'ub' exist.
      when multiple 'eb' and 'ub' exist, then the one with the higer id number gets set as initialFinding/lastFinding

      the initial finding needs to have description === 'eb'
       */
      switch (i.description) {
        case 'eb': {
          if (
            (i.id && i.type === 'NIBP' && !initialFinding) ||
            (initialFinding && i.id && i.type === 'NIBP' && initialFinding.id && i.id > initialFinding.id)
          ) {
            initialFinding = i;
          }
          break;
        }
        case 'verlauf': {
          list.push(i);
          if (
            (i.id && !lastFinding) ||
            (lastFinding && lastFinding.description !== 'ue' && i.id && lastFinding.id && i.id > lastFinding.id)
          ) {
            lastFinding = i;
          }
          break;
        }
        // Deactivated because of Roland Richters news: "Letzter im Verlauf, wie der aktuelle NIDAtracker - UE nicht beachten
        // case 'ue': {
        //   if (
        //     (i.id && i.type === 'NIBP' && !lastFinding) ||
        //     lastFinding?.description === 'verlauf' ||
        //     (i.id && i.type === 'NIBP' && lastFinding?.id && i.id > lastFinding?.id)
        //   ) {
        //     lastFinding = i;
        //   }
        //   break;
        // }
      }
    });

    return {
      initialFinding,
      lastFinding,
      list,
    };
  }

  /*
  filterEntriesByTime(array: Array<allVitalData>): allVitalData[] {
    let returnValue: Array<allVitalData> = [];

    returnValue = array.sort(
      (a: allVitalData, b: allVitalData) =>
        new Date(b.timestamp ? b.timestamp : new Date()).getTime() - new Date(a.timestamp ? a.timestamp : new Date()).getTime()
    );

    return returnValue;
  }
  */
}
