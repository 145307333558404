import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { Endpoints } from '../models/endpoints';
import { ServerVersion } from '../models/serverVersion';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusService {
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(private httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl;
    });
    this.subscription.add(configSub);
  }

  public getServerStatus(observe?: 'response'): Observable<HttpResponse<string>> {
    return this.httpClient.request('get', `${this.apiPrefix}serverinfo/health`, {
      responseType: 'text' as const,
      observe: observe,
    });
  }

  /**
   * Returns the server version.
   * Gets the server version in json format.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getServerVersion(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<ServerVersion>;
  public getServerVersion(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<ServerVersion>>;
  public getServerVersion(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<ServerVersion>>;
  public getServerVersion(
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<ServerVersion>(`${this.apiPrefix}serverinfo/version`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Returns the endpoints.
   * Returns all available endpoints that the user is authorized to consume.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAvailableEndpoints(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<Endpoints>;
  public getAvailableEndpoints(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Endpoints>>;
  public getAvailableEndpoints(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Endpoints>>;
  public getAvailableEndpoints(
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Endpoints>(`${this.apiPrefix}serverinfo/available-endpoints`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
