import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxPopupModule, DxTemplateModule } from 'devextreme-angular';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@Component({
  selector: 'nida-web-ui-shared-pdf-popup',
  standalone: true,
  imports: [CommonModule, DxPopupModule, DxTemplateModule, PdfJsViewerModule],
  templateUrl: './ui-shared-pdf-popup.component.html',
  styleUrl: './ui-shared-pdf-popup.component.scss',
})
export class UiSharedPdfPopupComponent {
  @Input() public allowDownload: boolean;
  @Input() public downloadFileName: string;
  @Input() public currentBlob: Blob;
  @Input() public popupTitle: string;
  @Input() public showPopup: boolean;

  @Output() public showPopupChange: EventEmitter<boolean> = new EventEmitter();

  public pdfViewerVisible: boolean;

  constructor() {
    this.popupTitle = '';
  }

  showPDFViewer(): void {
    this.pdfViewerVisible = true;
  }

  hidePDFViewer(): void {
    this.showPopupChange.emit(false);
    this.pdfViewerVisible = false;
  }
}
