import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject, Subscription, take, timer } from 'rxjs';
import { AuthenticationServable, LoginCredential } from '@nida-web/api/generic-interfaces/authentication';
import { LoginInterceptorService, ServerStatusService } from '@nida-web/api/rest/authentication';
import { LogoSettingsService, ModuleSettingsService } from '@nida-web/core';
import { AppInfoService } from '@nida-web/shared/data-access';
import { GetTenantsService } from '../../services/get-tenants.service';
import { NavAppInfoService } from '@nida-web/navigation';

@Component({
  selector: 'nida-web-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  serverStatus$: Subject<string>;
  serverStatusTimer: Subscription;

  public appTitle: string;

  /** The login form */
  public loginForm!: UntypedFormGroup;
  /** The origin route to which the user is returned after the login */
  public returnUrl: string;

  public apikey?: string;
  public multiTenancy: boolean;
  public multiLang: boolean;
  public showTenantList: boolean;
  public resetUserPassword: boolean;
  public setNidaMobilePassword: boolean;
  public logoSettings: string[];
  public lastClient: string;
  public lastKnownTenant: number | null;

  private clientid: number | null;
  private username: string;
  private password: string;

  private subscriptionArray: Array<Subscription>;

  constructor(
    // private fb: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthenticationServable,
    // private ls: LoginRESTService,
    private ss: ServerStatusService,
    private moduleSettingsService: ModuleSettingsService,
    private logoSettingsService: LogoSettingsService,
    private appInfo: AppInfoService,
    public getTenantService: GetTenantsService,
    public navAppInfoService: NavAppInfoService,
    public loginInterceptorService: LoginInterceptorService
  ) {
    this.appTitle = this.appInfo.title;
    this.multiTenancy = false;
    this.multiLang = false;
    this.setNidaMobilePassword = false;
    const lastKnownLS = localStorage.getItem('lastKnownClient');
    if (lastKnownLS) {
      this.lastClient = lastKnownLS;
    } else {
      this.lastClient = '';
    }
    this.logoSettings = [];
    this.subscriptionArray = [];

    this.serverStatus$ = new ReplaySubject<string>(1);

    this.serverStatus$.next('loading');
    this.getServerStat();
    this.serverStatusTimer = timer(10000, 20000).subscribe(() => {
      this.getServerStat();
    });
  }

  // LIFECYCLE HOOKS

  public ngOnInit(): void {
    // Get return url from route parameters or default to '/'.
    this.initFormGroup();
    this.getSettings();
    this.getLogoInformation();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getLastKnownTenant();
    if (this.lastKnownTenant) {
      this.updateForm('clientid', this.lastKnownTenant);
    }

    if (this.showTenantList) {
      this.loginForm.patchValue({ clientid: this.lastKnownTenant });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptionArray.forEach((subscription) => subscription.unsubscribe());
    this.serverStatusTimer.unsubscribe();
  }

  // LIFECYCLE HOOKS END

  private initFormGroup(): void {
    this.loginForm = new UntypedFormGroup({
      clientid: new UntypedFormControl(this.clientid),
      username: new UntypedFormControl(this.username),
      password: new UntypedFormControl(this.password),
    });
  }

  private getSettings() {
    this.subscriptionArray.push(
      this.moduleSettingsService.getSettings().subscribe((settings) => {
        this.multiTenancy = settings.multiTenancy;
        this.multiLang = settings.multiLang;
        this.showTenantList = settings.showTenantList;
        if (this.showTenantList) {
          this.getTenantService.loadTenants();
        }
        this.resetUserPassword = settings.resetUserPassword;
        this.setNidaMobilePassword = settings.setNidaMobilePassword;
      })
    );
  }

  private getLogoInformation() {
    this.subscriptionArray.push(
      this.logoSettingsService.getSettings().subscribe((settings) => {
        for (const [logoName, show] of Object.entries(settings)) {
          if (show) {
            this.logoSettings.push(logoName);
          }
        }
      })
    );
  }

  private getLastKnownTenant() {
    const rawStorage = localStorage.getItem('lastKnownClient');
    if (rawStorage !== null) {
      this.lastKnownTenant = Number(rawStorage);
    } else {
      // set client to -100 - this id for a client ain't possible because id is the primary key in db

      this.lastKnownTenant = null;
    }
  }

  submitForm() {
    this.nidaLogin(this.loginForm.value.username, this.loginForm.value.password, this.loginForm.value.clientid);
  }

  private nidaLogin(username: string, password: string, tenantId: string) {
    if (!this.multiTenancy && !this.showTenantList) {
      tenantId = '';
    }

    this.auth.realLogin(
      new LoginCredential(tenantId + (this.multiTenancy || this.showTenantList ? ':' : '') + username, password),
      this.returnUrl,
      tenantId,
      this.setNidaMobilePassword
    );
  }

  updateForm(key: string, value: string | number | null) {
    this.loginForm.patchValue({ [key]: value });
  }

  submitByEnter(event: KeyboardEvent) {
    if (event.key !== undefined && event.key === 'Enter') {
      this.submitForm();
    }
  }

  getServerStat() {
    this.ss
      .getServerStatus('response')
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.serverStatus$.next('success');
        },
        error: () => {
          this.serverStatus$.next('danger');
        },
      });
  }
}
