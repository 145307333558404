<nida-web-ui-shared-pdf-list
  [pdfListData]="this.pdfList"
  [pageSize]="this.pageSize"
  [disableDescriptionColumn]="this.disableDescriptionColumn"
  (rowClickEvent)="onRowClick($event)"
>
</nida-web-ui-shared-pdf-list>

<nida-web-ui-shared-pdf-popup
  [allowDownload]="download"
  [downloadFileName]="downloadFileName"
  [currentBlob]="currentBlob"
  [(showPopup)]="showPopup"
>
</nida-web-ui-shared-pdf-popup>
<nida-web-ui-shared-request-popup
  [(popupVisible)]="showRequestPdfPopup"
  (requestSubmitted)="getPdfWithReason($event)"
></nida-web-ui-shared-request-popup>
