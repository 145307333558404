import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { CoreModule } from '@nida-web/core';
import { TranslocoModule } from '@jsverse/transloco';
import { LegendPopupComponent } from './legend-popup/legend-popup.component';

@NgModule({
  imports: [CoreModule, CommonModule, DxButtonModule, DxPopupModule, TranslocoModule],
  declarations: [ButtonGroupComponent, AlertBoxComponent, LegendPopupComponent],
  exports: [ButtonGroupComponent, AlertBoxComponent, LegendPopupComponent],
})
export class CapacitySharedUiModule {}
