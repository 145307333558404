import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@nida-web/shared/ui';
import { DxRadioGroupModule, DxTextBoxModule } from 'devextreme-angular';
import { SessionInformation, SessionManagerService, TwoFactorAuthenticationRestService } from '@nida-web/api/rest/authentication';
import { Observable } from 'rxjs';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { map } from 'rxjs/operators';
import { ReactiveFormsModule } from '@angular/forms';
import { DxRadioGroupTypes } from 'devextreme-angular/ui/radio-group';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'nida-web-two-fa-management',
  standalone: true,
  imports: [CommonModule, SharedUiModule, DxRadioGroupModule, DxTextBoxModule, TranslocoModule, ReactiveFormsModule],
  templateUrl: './two-fa-management.component.html',
  styleUrls: ['./two-fa-management.component.scss'],
})
export class TwoFaManagementComponent implements OnInit {
  @Input() userId: number;

  secret: boolean | undefined;
  twoFaEnforced: boolean | undefined;
  twoFaProhibited: boolean | undefined;
  showTwoFaCard = false;
  showTwoFaSettings = false;

  yesNoSelection: string;

  public useTwoFa: Array<{
    value: string;
    assignment: string;
  }>;

  constructor(
    private sessionManagerService: SessionManagerService,
    private router: Router,
    private twoFAAuthenticationRestService: TwoFactorAuthenticationRestService,
    private translocoService: TranslocoService
  ) {
    this.useTwoFa = [
      {
        value: this.translocoService.translate('billing.details.yes'),
        assignment: 'UsingTwoFa',
      },
      {
        value: this.translocoService.translate('billing.details.no'),
        assignment: 'NotUsingTwoFa',
      },
    ];
  }

  ngOnInit(): void {
    this.get2FaInfos().subscribe((settings) => {
      this.secret = settings.twoFaSecret;
      this.twoFaEnforced = settings.twoFaEnforcement;
      this.twoFaProhibited = settings.twoFaProhibition;
      this.showTwoFaCard = settings.twoFaEnabled;
      if (this.twoFaProhibited === false && this.twoFaEnforced === false) {
        this.showTwoFaSettings = true;
        this.setOptionTwoFaSelection(settings.twoFaSecret);
      }
    });
  }

  get2FaInfos(): Observable<{
    twoFaEnabled: boolean;
    twoFaSecret: boolean | undefined;
    twoFaEnforcement: boolean | undefined;
    twoFaProhibition: boolean | undefined;
  }> {
    return this.sessionManagerService.getSessionInformation().pipe(
      map((sessionInfo: SessionInformation) => {
        if (
          sessionInfo &&
          !(
            sessionInfo.twoFaSecret === undefined &&
            sessionInfo.twoFaEnforcement === undefined &&
            sessionInfo.twoFaProhibition === undefined
          )
        ) {
          return {
            twoFaEnabled: true,
            twoFaSecret: sessionInfo.twoFaSecret,
            twoFaEnforcement: sessionInfo.twoFaEnforcement,
            twoFaProhibition: sessionInfo.twoFaProhibition,
          };
        } else {
          return { twoFaEnabled: false, twoFaSecret: undefined, twoFaEnforcement: undefined, twoFaProhibition: undefined };
        }
      })
    );
  }

  setOptionTwoFaSelection(secret: boolean | undefined) {
    if (secret === true) {
      this.yesNoSelection = 'UsingTwoFa';
    } else {
      this.yesNoSelection = 'NotUsingTwoFa';
    }
  }

  onValueChanged({ value }: DxRadioGroupTypes.ValueChangedEvent) {
    if (value === 'UsingTwoFa') {
      this.router.navigate(['activate-second-factor']).then();
    } else {
      this.twoFAAuthenticationRestService.deactivate2fa().subscribe({
        next: () => {
          notify({
            message: this.translocoService.translate('user.management.twoFaIsActive'),
            type: 'Success',
            displayTime: 5000,
          });
        },
        error: () => {
          notify({
            message: this.translocoService.translate('user.management.errorMessageTwoFaDeactivation'),
            type: 'Error',
            displayTime: 5000,
          });
        },
      });
    }
  }
}
