import { Injectable } from '@angular/core';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { throwError } from 'rxjs';
import { PatientMapping } from '../models/patient-mapping.model';
import { MappingConfigServable } from '../interfaces/mapping-config-servable.interface';
import { MappingFunctionsProviderService } from './mapping-functions-provider.service';

@Injectable({
  providedIn: 'root',
})
export class PatientMappingService {
  private static patientMapping?: PatientMapping;

  constructor(private mappingConfigServable: MappingConfigServable) {
    this.loadPatientMapping();
  }

  public static getPatientMapping() {
    return PatientMappingService.patientMapping;
  }

  private loadPatientMapping() {
    this.mappingConfigServable.getMappingConfig().subscribe((newMappingConf) => {
      if (newMappingConf.patientMapping !== undefined) {
        PatientMappingService.patientMapping = newMappingConf.patientMapping;
      }
    });
  }

  public mapEntry(item: any): PatientView {
    const mappedPatient: PatientView = new PatientView('-1', -1);

    if (PatientMappingService.patientMapping === undefined) {
      throwError('Mapping not Loaded! Critical!');
    } else {
      let firstName = '';
      let lastName = '';

      mappedPatient.attachments = { picture: '', ekg: '', pdf: '', video: '' };

      for (const el of Object.entries(PatientMappingService.patientMapping)) {
        // TODO make mappingFunctionsProviderService static
        const $methodName = 'mapping' + el[1].mappingFunction;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const mV = MappingFunctionsProviderService.prototype[$methodName](item[el[1].sourceItem]);
        switch (el[0]) {
          case 'lastName':
            lastName = mV;
            mappedPatient.lastName = lastName;
            break;
          case 'firstName':
            firstName = mV;
            mappedPatient.firstName = firstName;
            break;
          case 'attachmentPicture':
            mappedPatient.attachments.picture = mV;
            break;
          case 'attachmentVideo':
            mappedPatient.attachments.video = mV;
            break;
          case 'attachmentPdf':
            mappedPatient.attachments.pdf = mV;
            break;
          case 'attachmentEkg':
            mappedPatient.attachments.ekg = mV;
            break;
          case 'attachmentPdfStatus':
            mappedPatient.attachments.pdfStatus = mV;
            break;
          case 'age':
            mappedPatient.age = mV === undefined || mV === null || mV.length === 0 ? undefined : parseInt(mV);
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mappedPatient[el[0]] = mV;
        }
      }

      mappedPatient.name = lastName === null ? '' : lastName + (firstName === null ? '' : ', ' + firstName);
      const restAge = mappedPatient.age;
      if ((restAge === undefined || restAge === null) && mappedPatient.date && mappedPatient.birthday) {
        const age = MappingFunctionsProviderService.prototype['calculateAge'](mappedPatient.date, mappedPatient.birthday);
        if (age) {
          mappedPatient.age = age;
        }
      }
      mappedPatient.settings = { clicked: false, visible: true };
    }
    return mappedPatient;
  }

  mapFieldsToRest(appFields: string[]): string[] {
    const restFields: string[] = [];
    const mapping = PatientMappingService.patientMapping;

    if (mapping === undefined) {
      throwError(() => new Error('Mapping not Loaded! Critical!'));
    } else {
      appFields.forEach((appField) => {
        if (mapping[appField]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          restFields.push(mapping[appField].sourceItem);
        }
      });
    }
    return restFields;
  }
}
