import { Injectable } from '@angular/core';
import { AttachmentsServable, PDFElement } from '@nida-web/api/generic-interfaces/attachments';
import { Observable, ReplaySubject } from 'rxjs';
import { PDFItemMappingService } from '../mapping/pdfitem-mapping.service';
import { AttachmentsV2Service } from '../actions/attachmentsV2.service';
import { PdfList } from '../models/pdfList';
import { PdfListItem } from '../models/pdfListItem';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsAdapterService implements AttachmentsServable {
  private _pdfList: ReplaySubject<PDFElement[]> = new ReplaySubject<PDFElement[]>(1);
  public readonly pdfList: Observable<PDFElement[]> = this._pdfList.asObservable();
  private _lastPdfId: ReplaySubject<number | null> = new ReplaySubject<number | null>(1);
  public readonly lastPdfId: Observable<number | null> = this._lastPdfId.asObservable();

  constructor(private attachmentsV2Service: AttachmentsV2Service, private pdfItemMappingService: PDFItemMappingService) {}

  getPDFList(nidaId: string): Observable<PDFElement[]> {
    this.attachmentsV2Service.getPdfsByNidaId(nidaId).subscribe((PDFList: PdfList) => {
      if (PDFList && PDFList.data) {
        const latestId = this.getLatestPdfId(PDFList.data);
        this._lastPdfId.next(latestId);
        this._pdfList.next(this.pdfItemMappingService.mapPDFEntries(PDFList));
      }
    });

    return this.pdfList;
  }

  getLatestPdfId(list: Array<PdfListItem>) {
    let latestId: number | null = null;
    let latestTime = 0;

    list.forEach((element) => {
      if (element && element.id && element.timeStamp) {
        const elementTime = new Date(element.timeStamp).getTime();
        if (elementTime > latestTime) {
          latestTime = elementTime;
          latestId = element.id;
        }
      }
    });
    return latestId;
  }

  resetLastPdfId() {
    console.log('>>> resetLastPdfIf');
    this._lastPdfId.next(null);
  }
}
