import { Component, Input, NgZone, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Ecg, EcgLead, EcgServable } from '@nida-web/api/generic-interfaces/ecg-viewer';
import { SpeedStep } from '../models/speed-step';
import { VisualRange } from '../models/visual-range';
import { TickIntervals } from '../models/tick-intervals';
import { EcgDetailComponent } from '../ecg-detail/ecg-detail.component';
import { DetailPointStorage } from '../models/detail-point-storage';
import { CrosshairPoint } from '../models/crosshair-point';
import { ModeOption } from '../models/mode-option';
import { RangeValues } from '../models/range-values';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { getMarkup } from 'devextreme/viz/export';
import { DatePipe, formatDate } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Protocol, ProtocolService } from '@nida-web/api/rest/nidaserver/protocol';
import { DateService } from '@nida-web/core';
import { Patient, PatientServable } from '@nida-web/api/generic-interfaces/patient-management';
import { AvailableEndpointsStoreService } from '@nida-web/shared/feature';

interface ZoomStep {
  value: number;
  text: string;
  range: {
    bottom: number;
    top: number;
    tick: number;
  };
}

enum DetailMode {
  pointMode = 0,
  rangeMode = 1,
}

@Component({
  selector: 'nida-web-ecg-overview-detail',
  templateUrl: './ecg-overview-detail.component.html',
  styleUrls: ['./ecg-overview-detail.component.scss'],
})
export class EcgOverviewDetailComponent implements OnInit {
  @ViewChildren(EcgDetailComponent) detailArray!: QueryList<EcgDetailComponent>;

  @Input() chosenChannels: Array<string>; // ['I']
  @Input() ecgId: number;
  @Input() nidaId: string;
  @Input() protocolId?: number;
  @Input() timestamp: Date;

  protocol: Protocol;
  patient: Patient;
  visualRange: VisualRange;
  intervals: TickIntervals;
  zoomSteps: ZoomStep[];
  zoomStep: ZoomStep;
  speedSteps: Array<SpeedStep>;
  speedStep: SpeedStep;
  modeOptions: Array<ModeOption>;
  modeOption: ModeOption;
  defaultPoint: CrosshairPoint;
  clickedPointStorage: DetailPointStorage;
  pdfGenerationEnabled: boolean;

  selectedRangeArguments: RangeValues;
  selectedRangeValues: Array<number>;

  previewData: Array<object> = [];
  detailMode: DetailMode;

  public subject: BehaviorSubject<{ startValue: number; endValue: number } | boolean> = new BehaviorSubject(false);
  public restEcgLead: EcgLead = new EcgLead();
  public restEcg: Ecg = new Ecg(this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead, this.restEcgLead);

  loaded: boolean;

  constructor(
    private zone: NgZone,
    private ecgViewerService: EcgServable,
    private translocoService: TranslocoService,
    private protocolService: ProtocolService,
    private datePipe: DatePipe,
    private dateService: DateService,
    private patientServable: PatientServable,
    private availableEndpointsStoreService: AvailableEndpointsStoreService
  ) {
    this.chosenChannels = [];
    this.ecgId = 0;
    this.nidaId = '';
    this.visualRange = { startValue: 0, endValue: 0 };
    this.intervals = { tickInterval: 0, minorTickInterval: 0 };
    this.zoomSteps = [
      { value: 2, text: '200 %', range: { bottom: 2.5, top: 4.5, tick: 0.25 } },
      { value: 1, text: '100 %', range: { bottom: 1.5, top: 5.5, tick: 0.5 } },
      // { value: 0, text: '50 %', range: { bottom: -0.5, top: 7.5, tick: 1 } },
    ];
    this.zoomStep = this.zoomSteps[0];
    this.speedSteps = [
      {
        value: 0,
        text: '50 mm/s',
        sliderRange: { startValue: 0, endValue: 30000 },
        intervals: { tickInterval: 1000, minorTickInterval: 200 },
      },
      {
        value: 1,
        text: '25 mm/s',
        sliderRange: { startValue: 0, endValue: 60000 },
        intervals: { tickInterval: 2000, minorTickInterval: 400 },
      },
      {
        value: 2,
        text: '12,5 mm/s',
        sliderRange: { startValue: 0, endValue: 90000 },
        intervals: { tickInterval: 3000, minorTickInterval: 600 },
        // for 0<->120 000: intervals: { tickInterval: 4000, minorTickInterval: 800 },
      },
    ];
    this.speedStep = this.speedSteps[0];
    this.modeOptions = [
      {
        value: 0,
        icon: 'icon icon-cursor-target',
      },
      {
        value: 1,
        icon: 'icon icon-expand-horizontal',
      },
    ];
    this.modeOption = this.modeOptions[0];
    this.defaultPoint = { argument: null, value: null };
    this.clickedPointStorage = {
      point0: { argument: null, value: null },
      point1: { argument: null, value: null },
    };

    this.selectedRangeArguments = { startValue: 0, endValue: 0 };
    this.selectedRangeValues = [];

    this.previewData = [];
    this.detailMode = DetailMode.pointMode;

    this.loaded = false;
  }

  ngOnInit() {
    this.setRangeAndIntervals(0);
    if (this.nidaId && this.nidaId.length === 0) {
      notify({
        message: this.translocoService.translate('Sorry, no NIDA ID and / or protocol ID given'),
        type: 'Warning',
        displayTime: 5000,
      });
      this.loaded = true;
    } else {
      this.checkFunctionality();
    }
  }

  setRangeAndIntervals(index: number) {
    this.visualRange = this.speedSteps[index].sliderRange;
    this.intervals = this.speedSteps[index].intervals;
  }

  getECGData() {
    if (this.pdfGenerationEnabled && this.protocolId) {
      this.protocolService.getProtocolById(this.protocolId.toString()).subscribe((protocol) => {
        this.protocol = protocol;
      });
      this.patientServable.getPatientByProtocolId(Number(this.protocolId)).subscribe((patient: Patient) => {
        this.patient = patient;
      });
    }
    this.ecgViewerService.getEcg(this.nidaId, this.ecgId, 'max').subscribe((ecg) => {
      this.restEcg = ecg;
      this.setPreviewData(ecg);
      this.loaded = true;
    });
  }

  checkFunctionality() {
    this.availableEndpointsStoreService.getEndpoints().subscribe((endpoints) => {
      this.pdfGenerationEnabled = AvailableEndpointsStoreService.endpointsContained(endpoints, [
        {
          path: '/dispatch/protocols/{id}',
          methods: ['get'],
        },
        {
          path: '/patient/byProtocolId/{protocolId}',
          methods: ['get'],
        },
      ]);

      this.getECGData();
    });
  }

  setPreviewData(ecgData: Ecg) {
    const currentArray = ecgData.I.data;

    for (const rawDataPoint of currentArray) {
      const dataPoint = Object.assign({}, rawDataPoint);
      dataPoint.id = dataPoint.id * 25;
      dataPoint.value = dataPoint.value / 80; // TODO get the 80 dynamically (based on metaData?)
      dataPoint['bottomLine'] = 3;
      this.previewData.push(dataPoint);
    }

    const singleTempObj = {
      id: 200000,
      bottomLine: 3,
    };

    this.previewData.push(singleTempObj);
  }

  triggerPointClick(argument: number) {
    const slotEqualsPoint: boolean = this.checkStorageForPoint(argument);

    if (!slotEqualsPoint) {
      const slotEqualsNull = this.checkStorageForNull(argument);

      if (!slotEqualsNull) {
        this.transformStorage(argument);
      }
    }

    this.triggerPointCompletion();
    this.forceRefreshing();
  }

  checkStorageForPoint(argument: number) {
    /*
     Checks if a clicked point already exits;
     Checks if argument-values (= x-values) of given points are equal;
     If so: returns true for ongoing checks + functions;
     */
    let returnValue = false;

    for (const storedPoint in this.clickedPointStorage) {
      if (Object.prototype.hasOwnProperty.call(this.clickedPointStorage, storedPoint)) {
        if (this.clickedPointStorage[storedPoint].argument === argument) {
          this.clickedPointStorage[storedPoint] = { argument: null, value: null };
          returnValue = true;
        }
      }
    }

    return returnValue;
  }

  checkStorageForNull(argument: number) {
    /*
     Checks if any of the 2 slots (point0, point1) equals 'null';
     If so: return true for ongoing checks + functions;
     */
    let returnValue = false;

    for (const storedPoint in this.clickedPointStorage) {
      if (Object.prototype.hasOwnProperty.call(this.clickedPointStorage, storedPoint)) {
        if (this.clickedPointStorage[storedPoint].argument === null) {
          this.clickedPointStorage[storedPoint].argument = argument;
          returnValue = true;
          break;
        }
      }
    }

    return returnValue;
  }

  transformStorage(argument: number) {
    /*
     Rearranges the points within the storage;
     Overwrites existing points, when 3rd point was clicked;
     */

    // this.clickedPointStorage.point0 = this.clickedPointStorage.point1;
    // this.clickedPointStorage.point1 = { argument: null, value: null };
    //
    // this.clickedPointStorage.point1.argument = argument;

    this.clickedPointStorage.point0.argument = argument;
    this.clickedPointStorage.point1 = { argument: null, value: null };
  }

  resetSelectedPoints() {
    this.clickedPointStorage = {
      point0: { argument: null, value: null },
      point1: { argument: null, value: null },
    };

    this.detailArray.forEach((detail) => {
      detail.resetAllPoints();
    });
  }

  resetRangeArguments(index: number) {
    this.selectedRangeArguments = this.speedSteps[index].sliderRange;
  }

  setZoomStep(index: number) {
    this.zoomStep = this.zoomSteps[index];
  }

  setSpeedStep(index: number) {
    this.speedStep = this.speedSteps[index];
  }

  // ........................
  // arriving / outgoing calls (HTML)
  // ........................

  customizeTextArgumentAxisLabel = (value: { value: number; valueText: string }): string | number => {
    try {
      return (value.value / 10000).toFixed(2);
    } catch {
      return NaN;
    }
  };

  onValueChanged(e: { value: [number, number] }) {
    this.subject.next({ startValue: e.value[0], endValue: e.value[1] });
  }

  onZoomStepClick(e: { itemData: ZoomStep }) {
    this.zoomStep = e.itemData;
    this.forceRefreshing();
  }

  onSpeedStepClick(e: { itemData: SpeedStep }) {
    this.visualRange = e.itemData.sliderRange;
    this.intervals = e.itemData.intervals;
    this.speedStep = e.itemData;
    this.forceRefreshing();
  }

  changeCurrentDetailMode(e: {
    itemData: {
      value: DetailMode;
      icon: string;
    };
  }) {
    this.detailMode = e.itemData.value;
    this.forceRefreshing();
  }

  triggerPointStorage(argument: number) {
    /*
     EVENT ABSORBER
     */
    this.triggerPointClick(argument);
  }

  triggerRangeStorage(e: { startValue: number; endValue: number }) {
    /*
     EVENT ABSORBER
     */
    this.selectedRangeArguments = e;
  }

  triggerPointCompletion() {
    /*
     triggers the creation for points in all detail children;
     */
    this.detailArray.forEach((detail) => {
      detail.triggerPointCompletion();
    });
  }

  undoMajorChanges() {
    if (this.detailMode === DetailMode.pointMode) {
      this.resetSelectedPoints();
    } else if (this.detailMode === DetailMode.rangeMode) {
      this.resetRangeArguments(0);
    }

    this.setRangeAndIntervals(0);
    this.setZoomStep(0);
    this.setSpeedStep(0);
  }

  // ...............
  // basic functions
  // ...............

  forceRefreshing() {
    // ...forces the rerendering of the datagrid
    // ...=> hovering does not seem to activate Angular's rerendering
    this.zone.run(() => {
      // TODO: nothing happens here!
    });
  }

  exportMultipleCharts(): Array<{
    channel: string;
    svg: string;
  }> {
    const channels: Array<{
      channel: string;
      svg: string;
    }> = [];
    this.detailArray.forEach((detail: EcgDetailComponent) => {
      const channel = detail.channel;
      const chartInstance = detail.chart1.instance;
      channels.push({
        channel: channel,
        svg: getMarkup(chartInstance),
      });
    });
    return channels;
  }

  generatePdf() {
    this.printPdf();
  }

  private printPdf() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const channels: Array<{
      channel: string;
      svg: string;
    }> = this.exportMultipleCharts();

    const missionDate =
      this.protocol && this.protocol.missionDate ? this.dateService.formatMoment(this.protocol.missionDate.toString(), 'date') : '';
    const birthdate =
      this.patient && this.patient.birthdate ? this.dateService.formatMoment(this.patient.birthdate.toString(), 'date') : '';

    const ts = this.translocoService;

    const docDefinition: {
      content: Array<
        | string
        | { svg: string; width?: number; height?: number; fit?: [number, number] }
        | { layout: string; table: { widths: Array<string | number>; body: Array<Array<string>> } }
        | { text: string; pageBreak?: string; fontSize?: number; bold?: boolean }
      >;
      footer: {};
      defaultStyle: {};
      pageOrientation: string;
      pageSize: string;
    } = {
      content: [
        {
          layout: 'noBorders',
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                this.translocoService.translate('Mission number') + ': ' + this.protocol.missionNumber,
                this.translocoService.translate('Patient data') + ':',
              ],
              [
                this.translocoService.translate('Protocol number') + ': ' + this.protocol.missionNumberControlCenter,
                this.translocoService.translate('Gender') + ': ' + this.mapGender(this.patient.gender),
              ],
              [
                this.translocoService.translate('Transport date') + ': ' + missionDate,
                this.translocoService.translate('Date of birth') + ': ' + birthdate,
              ],
              [
                this.translocoService.translate('Date') + ': ' + this.datePipe.transform(this.timestamp, 'dd.MM.YYYY hh:mm:ss'),
                this.translocoService.translate('Notes') + ':',
              ],
              [
                this.translocoService.translate('speed') + ': ' + this.speedStep.text,
                '_______________________________________________________________',
              ],
              [
                this.translocoService.translate('zoom') + ': ' + this.zoomStep.text,
                '_______________________________________________________________',
              ],
            ],
          },
        },
      ],
      footer: function (currentPage: number, pageCount: number) {
        return {
          text: currentPage.toString() + ts.translate('From_lowercase') + pageCount,
          alignment: 'right',
          style: 'normalText',
          margin: [0, 20, 50, 0],
        };
      },
      defaultStyle: {
        lineHeight: 1.8,
      },
      pageOrientation: 'landscape',
      pageSize: 'A4',
    };

    channels.forEach((channel, index) => {
      if (index % 2 === 1) {
        docDefinition.content.push({ text: channel.channel, fontSize: 18, bold: true, pageBreak: 'before' });
      } else {
        docDefinition.content.push({ text: channel.channel, fontSize: 18, bold: true });
      }
      docDefinition.content.push({
        svg: channel.svg,
      });
    });

    pdfMake
      .createPdf(docDefinition)
      .download(formatDate(new Date(), 'yyyyMMdd_', 'de-DE') + this.translocoService.translate('Resting ecg') + '.pdf');
  }

  private mapGender(gender: string | undefined): string {
    let genderText = '';

    switch (gender) {
      case 'f':
        genderText = this.translocoService.translate('Female');
        break;
      case 'w':
        genderText = this.translocoService.translate('Female');
        break;
      case 'm':
        genderText = this.translocoService.translate('Male');
        break;
      case 'd':
        genderText = this.translocoService.translate('Diverse');
        break;
    }
    return genderText;
  }
}
