import { Injectable } from '@angular/core';
import { FontServable } from './font.servable';
import { Location } from '@angular/common';
import pdfMake from 'pdfmake';

@Injectable({
  providedIn: 'root',
})
export class FontHTTPLoaderService extends FontServable {
  constructor(private location: Location) {
    super();
  }
  buildFonts(): pdfMake.FontFaceSet {
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + '//' + getUrl.host;

    return {
      Arial: {
        normal: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/arial.ttf'),
        bold: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/arialbd.ttf'),
        italics: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/ariali.ttf'),
        bolditalics: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/arialbi.ttf'),
      },
      Sans: {
        normal: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/times.ttf'),
        bold: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/timesbd.ttf'),
        italics: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/timesi.ttf'),
        bolditalics: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/timesbi.ttf'),
      },
      Roboto: {
        normal: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/roboto.ttf'),
        bold: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/robotobd.ttf'),
        italics: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/robotoi.ttf'),
        bolditalics: baseUrl + this.location.prepareExternalUrl('/assets/fonts/pdf_fonts/robotobi.ttf'),
      },
    };
  }
}
