<dx-popup
  [visible]="this.showPopup"
  [title]="'Connection issues detected' | transloco"
  [showTitle]="true"
  [position]="'center'"
  [showCloseButton]="false"
  (onInitialized)="onInit($event)"
  maxWidth="90vw"
  minWidth="40vw"
  width="auto"
  minHeight="20vh"
  maxHeight="90vh"
  height="auto"
>
  <div class="d-flex justify-content-center">
    <nida-web-alert-box
      class="width-40vw"
      [showTrashCan]="false"
      [priority]="2"
      [displayValue]="'<p>' + ('Connection issues long explanation' | transloco) + '</p>'"
    ></nida-web-alert-box>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{
      text: 'Try again' | transloco,
      stylingMode: 'contained',
      type: 'default',
      onClick: reloadPage
    }"
  >
  </dxi-toolbar-item>
</dx-popup>
