<dx-popup
  [width]="400"
  [height]="380"
  [(visible)]="popupVisible"
  (onHidden)="onPopupHidden()"
  [showTitle]="true"
  [title]="'missions.viewRequest.viewRequestPopupTitle' | transloco"
  container=".dx-viewport"
  [showCloseButton]="true"
>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="cancelButtonOptions"></dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="submitButtonOptions"
    [disabled]="submitButtonDisabled"
  ></dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <div>
      <p>{{ requestDescriptionText }}</p>
    </div>
    <div>
      <dx-form #reasonForm labelLocation="top" [formData]="requestReasonFormData" (onFieldDataChanged)="validateForm()">
        <dxi-item
          dataField="requestReason"
          [editorType]="'dxTextArea'"
          [editorOptions]="{
            minHeight: 90,
            autoResizeEnabled: true,
            maxLength: maxInputLength,
            valueChangeEvent: 'keyup'
          }"
        >
          <dxo-label [text]="'missions.viewRequest.viewRequestTextInputLable' | transloco"></dxo-label>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dx-form>
    </div>
  </div>
</dx-popup>
