import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DatePipe, formatDate } from '@angular/common';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Injectable({
  providedIn: 'root',
})
export class ExportToExcelFileService {
  export(eventFromOnExporting: ExportingEvent, fileName: string): void {
    const workbook = new Workbook();
    const currentWorksheet = workbook.addWorksheet(fileName);

    exportDataGrid({
      component: eventFromOnExporting.component,
      worksheet: currentWorksheet,
      customizeCell(options) {
        const { gridCell, excelCell } = options;
        if (gridCell && gridCell.column && gridCell.rowType === 'data' && gridCell.column.cellTemplate === 'date') {
          const dp = new DatePipe('de');
          excelCell.value = dp.transform(excelCell.value, 'dd.MM.yyyy');
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.generateFileName(fileName));
      });
    });
    eventFromOnExporting.cancel = true;
  }

  generateFileName(fileName: string): string {
    return formatDate(new Date(), 'yyyyMMdd_', 'de-DE') + fileName + '.xlsx';
  }
}
