/**
 * NIDA PatientModel Rest-Api
 * API Beschreibung für die NIDA Patiententabelle.
 *
 * OpenAPI spec version: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';

import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { Patient, PatientList } from '@nida-web/api/generic-interfaces/patient-management';
import { AccessReason } from '../models/accessReason';

@Injectable({
  providedIn: 'root',
})
export class PatientRestService {
  static readonly module = 'patient';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private basePath: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.basePath = '';
    const configSub = this.configSubject.getRESTURL().subscribe((basePath) => {
      this.basePath = basePath + PatientRestService.module;
    });
    this.subscription.add(configSub);
  }

  /**
   * Add new PatientModel
   *
   * @param body PatientModel Object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addNewPatient(body: Patient, observe?: 'body', reportProgress?: boolean): Observable<Patient>;
  public addNewPatient(body: Patient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Patient>>;
  public addNewPatient(body: Patient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Patient>>;
  public addNewPatient(body: Patient, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addNewPatient.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Patient>('post', `${this.basePath}/new`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Delete PatientModel with the given id
   *
   * @param id the patient id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePatientById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deletePatientById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deletePatientById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deletePatientById(id: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deletePatientById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('delete', `${this.basePath}/by/${encodeURIComponent(String(id))}`, {
      // @ts-ignore
      responseType: 'text',
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all Patients
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllPatients(observe?: 'body', reportProgress?: boolean): Observable<PatientList>;
  public getAllPatients(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientList>>;
  public getAllPatients(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientList>>;
  public getAllPatients(observe: any = 'body', reportProgress = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<PatientList>('get', `${this.basePath}/list`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get PatientModel By Id
   *
   * @param id the patient id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientById(id: string, observe?: 'body', reportProgress?: boolean): Observable<Patient>;
  public getPatientById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Patient>>;
  public getPatientById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Patient>>;
  public getPatientById(id: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getPatientById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Patient>('get', `${this.basePath}/by/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get one patient data set by it&#x27;s protocol_id
   * Get one listed patient data set by it&#x27;s protocol_id
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientByProtocolId(protocolId: string, observe?: 'body', reportProgress?: boolean): Observable<Patient>;
  public getPatientByProtocolId(protocolId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Patient>>;
  public getPatientByProtocolId(protocolId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Patient>>;
  public getPatientByProtocolId(protocolId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getInsuranceByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Patient>('get', `${this.basePath}/byProtocolId/${encodeURIComponent(String(protocolId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get one patient data set by it\&#39;s protocol_id after insert a reason
   * Get one listed patient data set by it\&#39;s protocol_id after insert a reason
   * @param protocolId
   * @param accessReason Protocols crew object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientByProtocolIdWithReason(
    protocolId: string,
    accessReason: AccessReason,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<Patient>;
  public getPatientByProtocolIdWithReason(
    protocolId: string,
    accessReason: AccessReason,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpResponse<Patient>>;
  public getPatientByProtocolIdWithReason(
    protocolId: string,
    accessReason: AccessReason,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpEvent<Patient>>;
  public getPatientByProtocolIdWithReason(
    protocolId: string,
    accessReason: AccessReason,
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getPatientByProtocolIdWithReason.');
    }
    if (accessReason === null || accessReason === undefined) {
      throw new Error('Required parameter accessReason was null or undefined when calling getPatientByProtocolIdWithReason.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<Patient>(`${this.basePath}/byProtocolId/${encodeURIComponent(String(protocolId))}`, accessReason, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * SavePatient
   *
   * @param body PatientModel Object
   * @param id the patient id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public savePatient(body: Patient, id: string, observe?: 'body', reportProgress?: boolean): Observable<Patient>;
  public savePatient(body: Patient, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Patient>>;
  public savePatient(body: Patient, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Patient>>;
  public savePatient(body: Patient, id: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling savePatient.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling savePatient.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Patient>('put', `${this.basePath}/by/${encodeURIComponent(String(id))}`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
}
