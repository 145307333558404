/**
 * NIDA Attachments Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { ECGPdfList } from '../models/ecgPdfList';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsECGService {
  protected basePath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}attachments`;
    });
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get a specific ECG of a certain nidaId by its documentId
   * Gets BLOB data of a certain ECG pdf, identified by its documentId.
   * @param nidaId
   * @param documentId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getECGPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<Blob>;
  public getECGPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<HttpResponse<Blob>>;
  public getECGPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<HttpEvent<Blob>>;
  public getECGPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getECGPdfByDocumentId.');
    }
    if (documentId === null || documentId === undefined) {
      throw new Error('Required parameter documentId was null or undefined when calling getECGPdfByDocumentId.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/pdf', 'application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get(
      `${this.basePath}/ecg/pdfs/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(documentId))}`,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all ECG PDFs attached to the specified nidaId
   * Gets meta information (no BLOB data) for all ECG PDFs attached to the specified nidaId.
   * @param nidaId
   * @param idAfter The id after which rows will be fetched (seek pagination)
   * @param skip The number of queried rows to skip before returning results to the client (offset pagination)
   * @param take The number of elements to query (seek and offset pagination)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getECGPdfsByNidaId(
    nidaId: string,
    idAfter?: number,
    skip?: number,
    take?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<ECGPdfList>;
  public getECGPdfsByNidaId(
    nidaId: string,
    idAfter?: number,
    skip?: number,
    take?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpResponse<ECGPdfList>>;
  public getECGPdfsByNidaId(
    nidaId: string,
    idAfter?: number,
    skip?: number,
    take?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpEvent<ECGPdfList>>;
  public getECGPdfsByNidaId(
    nidaId: string,
    idAfter?: number,
    skip?: number,
    take?: number,
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getECGPdfsByNidaId.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (idAfter !== undefined && idAfter !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>idAfter, 'id_after');
    }
    if (skip !== undefined && skip !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>skip, 'skip');
    }
    if (take !== undefined && take !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>take, 'take');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<ECGPdfList>(`${this.basePath}/ecg/pdfs/${encodeURIComponent(String(nidaId))}`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
