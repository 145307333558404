<dx-popup
  [disabled]="false"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  [fullScreen]="true"
  [visible]="showPopup"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  (onShown)="showPDFViewer()"
  (onHiding)="hidePDFViewer()"
>
  <ng-container *dxTemplate="let data of 'content'">
    @if (pdfViewerVisible) {
    <ng2-pdfjs-viewer
      [openFile]="false"
      [download]="allowDownload"
      [downloadFileName]="downloadFileName"
      [print]="true"
      [pdfSrc]="currentBlob"
    ></ng2-pdfjs-viewer>
    }
  </ng-container>
</dx-popup>
