import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AlertBoxComponent {
  @Input() priority: number;
  @Input() showTrashCan: boolean;
  // TODO: Sanitise HTML Tags (Allow only strong, p, etc.)
  @Input() displayValue: string;
  @Input() footerText?: string;
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();

  onDeleteClick() {
    this.deleteClick.emit();
  }
}
