import { Component, Input, OnInit } from '@angular/core';
import { Kis } from '../models/kis.model';
import { KisListServable } from '../interfaces/kis-list-servable.interface';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { ActivatedRoute, Router } from '@angular/router';
import { DateService } from '@nida-web/core';
import { KisStoreService } from '../services/store/kis.store.service';

@Component({
  selector: 'nida-web-kis-link',
  templateUrl: './kis-link.component.html',
  styleUrls: ['./kis-link.component.scss'],
})
export class KisLinkComponent implements OnInit {
  @Input() nidaId: string;
  @Input() protocolId: number;
  public kis: Kis | undefined;
  public kisId: number | undefined;
  public loaded = false;
  public noData = false;
  public kisData?: Kis[];

  constructor(
    protected dateService: DateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected kisListStoreService: KisStoreService,
    protected kisListServable: KisListServable,
    private translocoService: TranslocoService
  ) {
    this.nidaId = '';
  }

  ngOnInit(): void {
    this.kisListServable.getKisByProtocolId(this.protocolId.toString()).subscribe((kis) => {
      this.kis = kis;
      this.kisId = kis.id;
      this.loaded = true;
    });
  }

  unlinkKisMapping(): void {
    if (this.kisId) {
      this.kisListServable.unmapKis(this.kisId).subscribe((kis: Kis) => {
        notify({
          message: this.translocoService.translate('Patient successfully unlinked from his data set'),
          type: 'Success',
          displayTime: 5000,
        });
        this.kisId = kis.id;
        this.triggerNavigation();
      });
    }
  }

  triggerNavigation(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/kisPage']);
    });
  }

  formatMoment(value: string | Date | undefined, type: string) {
    if (value) {
      return this.dateService.formatMoment(value.toString(), type);
    } else {
      return '';
    }
  }
}
