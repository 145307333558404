<dx-data-grid
  (onRowClick)="onRowClick($event)"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [dataSource]="this.ecgList"
  [rowAlternationEnabled]="true"
  [showBorders]="!responsive"
  [showColumnLines]="true"
  [showRowLines]="true"
  [wordWrapEnabled]="true"
  [repaintChangesOnly]="false"
  noDataText="{{ 'No data' | transloco }}"
  [width]="responsive ? '100%' : 'auto'"
  [height]="responsive ? 'calc(100% -  ' + topOffset + 'px)' : 'auto'"
>
  <dxo-paging [enabled]="false" [pageIndex]="0" [pageSize]="this.pageSize"></dxo-paging>
  <dxo-state-storing [enabled]="true" [savingTimeout]="500"></dxo-state-storing>

  <dxi-column [caption]="'Leads' | transloco" alignment="left" dataField="leads" dataType="number"></dxi-column>
  <dxi-column
    [caption]="'Date' | transloco"
    dataField="timestamp"
    dataType="datetime"
    width="135"
    cellTemplate="recordingTemplate"
  ></dxi-column>
  <div *dxTemplate="let data of 'recordingTemplate'">
    {{ data.value | date: 'dd.MM.yyyy, HH:mm' }}
  </div>
</dx-data-grid>
