import { Injectable } from '@angular/core';
import { Findings, PatientDetailServable, Picture, PictureList, Video } from '@nida-web/api/generic-interfaces/patient-management';
import { Findings as FindingsRaw } from '../models/findings';
import { BehaviorSubject, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { PatientDetailRESTService } from '../actions/patient-detail.rest.service';
import { FindingMappingService } from '../mapping/finding-mapping.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Finding } from '../models/finding';
import { AudioRecordList } from '../models/audioRecordList';

@Injectable({
  providedIn: 'root',
})
export class PatientDetailRESTAdapterService implements PatientDetailServable {
  constructor(
    private patientDetailRESTService: PatientDetailRESTService,
    private findingMappingService: FindingMappingService,
    private sanitizer: DomSanitizer
  ) {}

  getDetailFinding(revision: string, findingType?: 'voranmeldung' | 'eb_atmung' | 'eb_neuro' | 'eb_kreislauf'): Observable<Findings> {
    return this.patientDetailRESTService.getDetailBefund(revision).pipe(
      map((rawFindings: FindingsRaw) => {
        let cleanedUpRawFindings: Finding[] = rawFindings.data;
        if (findingType) {
          cleanedUpRawFindings = cleanedUpRawFindings.filter((rawFinding: Finding) => rawFinding['befund.befund_art'] === findingType);
        }
        return this.findingMappingService.mapValues(cleanedUpRawFindings);
      })
    );
  }

  getDetailVideos(): Observable<Video> {
    // Not supported yet
    return new BehaviorSubject<Video>(new Video()).asObservable();
  }

  getPatientPicture(nidaId: string, pictureId: string): Observable<Picture> {
    return this.patientDetailRESTService.getDetailSinglePicture(nidaId, pictureId).pipe(
      map((rawPicture: ArrayBuffer) => {
        if (rawPicture === undefined || rawPicture === null || rawPicture.byteLength <= 0) {
          throw new Error('Picture not loaded!');
        }
        const picture = new Picture();
        picture.isVideo = false;
        picture.srcUrl = this.transform(URL.createObjectURL(new Blob([rawPicture])));
        picture.blob = new Blob([rawPicture]);
        return picture;
      })
    );
  }

  getPictureList(nidaId: string): Observable<PictureList> {
    return this.patientDetailRESTService.getDetailPictures(nidaId).pipe(
      switchMap((rawList) => {
        if (rawList === null || rawList.data === undefined || rawList.data === null) {
          return of(new PictureList()); // return empty list
        }

        const pictureObservables = rawList.data.map((rawPicture) => {
          return this.getPicture(nidaId, rawPicture);
        });

        return forkJoin(pictureObservables).pipe(
          map((pictures) => {
            return pictures as PictureList;
          })
        );
      })
    );
  }

  private getPicture(nidaId: string, rawPicture: any): Observable<Picture> {
    const checkedPicture = this.checkRawPictureIntegrity(rawPicture);
    return this.getPatientPicture(nidaId, checkedPicture.id).pipe(
      map((picture) => {
        if (checkedPicture.date) {
          picture.date = new Date(checkedPicture.date);
        } else {
          picture.date = undefined;
        }

        if (checkedPicture['protokoll_bilder.beschreibung']) {
          picture.description = checkedPicture['protokoll_bilder.beschreibung'];
        }

        if (checkedPicture['protokoll_bilder.dateiname']) {
          picture.filename = checkedPicture['protokoll_bilder.dateiname'];
        }

        return picture;
      })
    );
  }

  getAudioList(protocolId: number): Observable<AudioRecordList> {
    return this.patientDetailRESTService.getAudioRecords(protocolId);
  }

  getAudioRecordById(protocolId: number, id: number): Observable<Blob> {
    return this.patientDetailRESTService.getAudioRecordById(protocolId, id);
  }

  checkRawPictureIntegrity(rawPicture: any) {
    if (rawPicture['protokoll_bilder.id'] !== undefined) {
      rawPicture.id = rawPicture['protokoll_bilder.id'];
    }
    if (rawPicture['protokoll_bilder.datum_zeit_aufnahme'] !== undefined) {
      rawPicture.date = new Date(rawPicture['protokoll_bilder.datum_zeit_aufnahme']);
      rawPicture['protokoll_bilder.datum_zeit_aufnahme'] = rawPicture.date;
    }

    return rawPicture;
  }

  private transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
