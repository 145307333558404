/**
 * Describes a PDF-Element.
 */
export interface PDFElement {
  /**
   * Id of the PDF (needed for marking)
   */
  id?: number;
  /**
   * Id of the corresponding mission
   */
  nidaId?: string;
  /**
   *  Name of the pdf
   */
  name?: string;
  /**
   * Optional clear description
   */
  description?: string;
  /**
   * Optional clear description
   */
  pdfType?: PDFTypes;
  /**
   * Time of creation
   */
  timeStamp?: Date;
  /**
   * PDF Mark Status (0 or null = Unread/New, 1 = Read)
   */
  status?: string;
  /**
   * PDF Blob
   */
  content?: string;
}

export enum PDFTypes {
  Protocol,
  ProtocolComplete,
  ProtocolBillable,
  ProtocolSubmitted,
  TraumaRegister,
  ProtocolShortMission,
  DisinfectionProtocol,
  Unknown,
  TransportProtocol,
  SurveyQuestionnaireM,
  SurveyQuestionnaireT,
  ECG,
  Billing,
  Anonymized,
  PatientClarification,
  RoutineDisinfectionProtocol,
}
