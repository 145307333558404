import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateService } from '@nida-web/core';
import { Insurance, InsuranceServable, Patient, PatientServable } from '@nida-web/api/generic-interfaces/patient-management';
import { isNumber } from '@jsverse/transloco';

@Component({
  selector: 'nida-web-patient-insurance',
  templateUrl: './patient-insurance.component.html',
  styleUrls: ['./patient-insurance.component.scss'],
})
export class PatientInsuranceComponent implements OnInit, OnChanges {
  @Input() protocolId: number;

  public insuranceData?: Insurance;
  public patientData?: Patient;
  public loaded: boolean;

  constructor(
    protected dateService: DateService,
    protected insuranceServable: InsuranceServable,
    protected patientServable: PatientServable
  ) {
    this.loaded = false;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['protocolId']) {
      const protocolId: number = changes['protocolId'].currentValue;
      if (protocolId && isNumber(protocolId)) {
        this.fetchInsuranceData(protocolId);
        this.fetchPatientData(protocolId);
      }
    }
  }

  fetchInsuranceData(protocolId: number): void {
    this.insuranceServable.getInsuranceByProtocolId(protocolId).subscribe((insurance) => {
      this.insuranceData = insurance;
    });
  }

  fetchPatientData(protocolId: number): void {
    this.patientServable.getPatientByProtocolId(protocolId).subscribe((patient) => {
      this.patientData = patient;
      this.loaded = true;
    });
  }
}
