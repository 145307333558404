import { ErrorHandler, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private sentryErrorHandler: SentryErrorHandler) {}

  handleError(error: any): void {
    if (error.message && error.message.includes('ChunkLoadError')) {
      if (localStorage.getItem('OIDCLogin') !== null) {
        return;
      }

      console.log('ChunkLoadError detected, reloading page...');
      window.location.reload();
    } else if (error?.status === 0 && error?.statusText === 'Unknown Error') {
      console.log('Http failure response: Status 0 - Unknown Error');
      return;
    } else {
      this.sentryErrorHandler.handleError(error);
    }
  }
}
