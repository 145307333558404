import { Component, Input, OnInit } from '@angular/core';

import { CoreModule, DateService } from '@nida-web/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { Findings, MissionData, PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { Finding } from '../models/finding.model';
import dayjs from 'dayjs';
import { DateHelperService } from '@nida-web/shared/utils';

@Component({
  selector: 'nida-web-patient-detail-preregistration-information-version-2',
  standalone: true,
  imports: [CoreModule, TranslocoModule, TranslocoTranslationModule],
  templateUrl: './patient-detail-preregistration-information-version-2.component.html',
  styleUrl: './patient-detail-preregistration-information-version-2.component.scss',
})
export class PatientDetailPreregistrationInformationVersion2Component implements OnInit {
  @Input() patientData: PatientView;
  @Input() missionData: MissionData;
  @Input() findingData: Findings;
  @Input() arrivalIsInFuture: boolean;

  public findings: Array<Finding>;
  public findingName: string;
  public initialGcs?: number;

  public timeSinceFindingText?: string;
  public eventTime?: string;
  public lastSeenWellTime?: string;

  constructor(protected dateService: DateService) {
    this.findings = [];
  }

  ngOnInit() {
    const currentDate = new Date();
    const datetime: number | string | undefined = this.findingData.event_time ? this.findingData.event_time : this.findingData.event_date;
    if (datetime) {
      const days: number = dayjs(currentDate).diff(datetime, 'days');
      if (days <= 1) {
        this.timeSinceFindingText = DateHelperService.getTimeDiffStringDE(currentDate, dayjs(datetime).toDate());
      }
    }
    this.eventTime = this.combineDateTimeForDisplay(this.findingData.event_date, this.findingData.event_time);
    this.initialGcs = this.findingData.initial_gcs === undefined ? this.findingData.initial_gcs : +this.findingData.initial_gcs;
    if (this.findingData?.preregistration_stroke_last_seen_well_time) {
      this.lastSeenWellTime = this.combineDateTimeForDisplay(
        this.findingData.preregistration_stroke_last_seen_well_time,
        this.findingData.preregistration_stroke_last_seen_well_time
      );
    }
  }

  combineDateTimeForDisplay(datePart: string | number | undefined, timePart: string | number | undefined) {
    let returnString: string;
    const divideString = ' ';

    if (datePart === undefined && timePart === undefined) {
      returnString = '';
    } else {
      if (datePart === undefined) {
        datePart = '';
      }
      if (timePart === undefined) {
        timePart = '';
      }
      returnString = new Date(datePart).toLocaleDateString() + divideString + new Date(timePart).toLocaleTimeString();
    }

    return returnString;
  }

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }
}
