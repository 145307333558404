import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavAppInfoService } from '@nida-web/navigation';
import { FormBuilder, Validators } from '@angular/forms';
import { catchError, delay, Subscription, throwError } from 'rxjs';
import { LogoSettingsService } from '@nida-web/core';
import { AppInfoService } from '@nida-web/shared/data-access';
import { AuthenticationServable } from '@nida-web/api/generic-interfaces/authentication';
import { TwoFactorQrCodeUriCreator } from '@nida-web/api/rest/authentication';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DxFormComponent } from 'devextreme-angular';
import { TranslocoService } from '@jsverse/transloco';
import { Location } from '@angular/common';

export interface AuthenticatorApp {
  appName: string;
  iosLink?: string;
  androidLink?: string;
  desktopLink?: string;
}

@Component({
  selector: 'nida-web-two-factor-auth-prompt',
  templateUrl: './two-factor-auth-prompt.component.html',
  styleUrls: ['./two-factor-auth-prompt.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          overflow: 'hidden',
          opacity: 1,
        })
      ),
      transition('hidden => visible', [animate('0.2s')]),
      transition('visible => hidden', [animate('0.2s')]),
    ]),
  ],
})
export class TwoFactorAuthPromptComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: false }) codeFormElement: DxFormComponent;
  private subscriptionArray: Array<Subscription>;
  public logoSettings: string[];
  public appTitle: string;
  public codePattern = /^\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*$/;
  public twoFactorQrCodeUri: string;
  public twoFactorSecret: string;
  public showAuthenticatorAppInfo = false;
  public successVisible = false;
  public failedVisible = false;
  public valueChangeEvent = 'change';
  public successButtonOptions: {
    width?: number;
    text: string;
    type: string;
    stylingMode: string;
    onClick: () => void;
  };
  public failedButtonOptions: {
    width?: number;
    text: string;
    type: string;
    stylingMode: string;
    onClick: () => void;
  };
  public cancelButtonOptions: {
    width?: number;
    text: string;
    type: string;
    stylingMode: string;
    onClick: () => void;
  };

  public codeForm = this.fb.group({
    code: ['', Validators.required],
  });

  public apps: AuthenticatorApp[] = [
    {
      appName: 'Authy',
      iosLink: 'https://apps.apple.com/de/app/twilio-authy/id494168017',
      androidLink: 'https://play.google.com/store/apps/details?id=com.authy.authy',
      desktopLink: 'https://authy.com/download/',
    },
    {
      appName: 'Google Authenticator',
      iosLink: 'https://apps.apple.com/de/app/google-authenticator/id388497605',
      androidLink: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    },
    {
      appName: 'Microsoft Authenticator',
      iosLink: 'https://apps.apple.com/de/app/microsoft-authenticator/id983156458',
      androidLink: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
    },
  ];

  constructor(
    private auth: AuthenticationServable,
    public navAppInfoService: NavAppInfoService,
    private logoSettingsService: LogoSettingsService,
    private appInfo: AppInfoService,
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private _location: Location
  ) {
    this.logoSettings = [];
    this.subscriptionArray = [];
    this.appTitle = this.appInfo.title;

    this.successButtonOptions = {
      width: 300,
      text: this.translocoService.translate('twoFactorLogin.continue'),
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        this.handleSuccessOkClick();
      },
    };

    this.failedButtonOptions = {
      text: this.translocoService.translate('twoFactorLogin.restartActivation'),
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        this.handleFailedOkClick();
      },
    };

    this.cancelButtonOptions = {
      text: this.translocoService.translate('twoFactorLogin.cancel'),
      type: 'normal',
      stylingMode: 'contained',
      onClick: () => {
        this.failedVisible = false;
        this.cancel2FAForm();
      },
    };
  }

  public ngOnInit(): void {
    this.getLogoInformation();
    this.get2FASecretFromServer();
  }

  ngOnDestroy() {
    this.subscriptionArray.forEach((sub) => sub.unsubscribe());
  }

  validateCodeInput() {
    this.valueChangeEvent = 'keyup';
  }

  showSuccessDialog() {
    this.successVisible = true;
  }

  showFailedDialog() {
    this.failedVisible = true;
  }

  private get2FASecretFromServer(timeout = 0) {
    this.auth
      .get2FASecret()
      .pipe(delay(timeout))
      .subscribe((secret) => {
        this.twoFactorSecret = secret;
        this.twoFactorQrCodeUri = TwoFactorQrCodeUriCreator.createTwoFactorUri(secret, this.appTitle);
      });
  }

  private getLogoInformation() {
    this.subscriptionArray.push(
      this.logoSettingsService.getSettings().subscribe((settings) => {
        for (const [logoName, show] of Object.entries(settings)) {
          if (show) {
            this.logoSettings.push(logoName);
          }
        }
      })
    );
  }

  submitForm() {
    if (this.codeForm.value.code) {
      this.auth
        .save2FASecret(TwoFactorAuthPromptComponent.extractNumbersOnly(this.codeForm.value.code))
        .pipe(
          catchError((error) => {
            this.twoFactorSecret = '';
            this.twoFactorQrCodeUri = '';
            this.codeForm.reset();
            this.valueChangeEvent = 'change';
            this.showFailedDialog();
            return throwError(error);
          })
        )

        .subscribe(() => {
          this.showSuccessDialog();
        });
    }
  }

  handleSuccessOkClick() {
    this.successVisible = false;
    this.auth.startLogin();
  }

  handleFailedOkClick() {
    this.failedVisible = false;
    this.get2FASecretFromServer(300);
  }

  cancel2FAForm() {
    this._location.back();
  }

  public static extractNumbersOnly(inputString: string): string {
    return inputString.replace(/[^0-9]/g, '');
  }
}
