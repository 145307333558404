/**
 * NIDA Attachments Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * OpenAPI spec version: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';

import { Observable } from 'rxjs';

import { PatientClarificationPdfList } from '../models/patientClarificationPdfList';

import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsPatientClarificationService {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}attachments`;
    });
  }

  /**
   * Get a specific patient clarification of a certain nidaId by its documentId
   * Gets all information (including BLOB data) of a certain nidaId&#x27;s document, identified by its documentId
   * @param nidaId
   * @param documentId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientClarificationPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public getPatientClarificationPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public getPatientClarificationPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public getPatientClarificationPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPatientClarificationPdfByDocumentId.');
    }

    if (documentId === null || documentId === undefined) {
      throw new Error('Required parameter documentId was null or undefined when calling getPatientClarificationPdfByDocumentId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/pdf', 'application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request(
      'get',
      `${this.basePath}/patient-clarification/pdfs/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(documentId))}`,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all patient clarification PDFs attached to the specified nidaId
   * Gets meta information (no BLOB data) for all patient clarification PDFs attached to the specified nidaId
   * @param nidaId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientClarificationPdfsByNidaId(
    nidaId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PatientClarificationPdfList>;
  public getPatientClarificationPdfsByNidaId(
    nidaId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PatientClarificationPdfList>>;
  public getPatientClarificationPdfsByNidaId(
    nidaId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PatientClarificationPdfList>>;
  public getPatientClarificationPdfsByNidaId(nidaId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPatientClarificationPdfsByNidaId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<PatientClarificationPdfList>(
      'get',
      `${this.basePath}/patient-clarification/pdfs/${encodeURIComponent(String(nidaId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
