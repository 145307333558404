import { Injectable } from '@angular/core';
import {
  AsyncRule,
  CompareRule,
  CustomRule,
  EmailRule,
  NumericRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme/common';

import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class PasswordValidationService {
  constructor(private translocoService: TranslocoService) {}

  public getPasswordValidationRules(): Array<
    RequiredRule | NumericRule | RangeRule | StringLengthRule | CustomRule | CompareRule | PatternRule | EmailRule | AsyncRule
  > {
    const arrayReqired: Array<
      RequiredRule | NumericRule | RangeRule | StringLengthRule | CustomRule | CompareRule | PatternRule | EmailRule | AsyncRule
    > = [
      {
        type: 'required',
      },
    ];

    return arrayReqired.concat(this.getPasswordValidationRulesWithoutRequired());
  }

  public getPasswordValidationRulesWithoutRequired(): Array<
    RequiredRule | NumericRule | RangeRule | StringLengthRule | CustomRule | CompareRule | PatternRule | EmailRule | AsyncRule
  > {
    return [
      {
        type: 'stringLength',
        max: 100,
        message: this.translocoService.translate('validations.stringLength.max', { value: 100 }),
        ignoreEmptyValue: true,
      },
      {
        type: 'stringLength',
        min: 8,
        message: this.translocoService.translate('validations.password.minLengthValidation', { value: 8 }),
        ignoreEmptyValue: true,
      },
      {
        type: 'pattern',
        pattern: '(?=.*?[A-Z])',
        message: this.translocoService.translate('validations.password.capitalLetterValidation'),
        ignoreEmptyValue: true,
      },
      {
        type: 'pattern',
        pattern: '(?=.*?[a-z])',
        message: this.translocoService.translate('validations.password.lowerCaseLetterValidation'),
        ignoreEmptyValue: true,
      },
      {
        type: 'pattern',
        pattern: '(?=.*?[0-9])',
        message: this.translocoService.translate('validations.password.digitValidation'),
        ignoreEmptyValue: true,
      },
      {
        type: 'pattern',
        pattern: "(?=.*?[#$%^&*()_+\\-=;':,.<>\\/?!])",
        message: this.translocoService.translate('validations.password.specialCharacterValidation'),
        ignoreEmptyValue: true,
      },
      {
        type: 'pattern',
        pattern: "^[a-zA-Z 0-9\\#\\$\\%\\^\\&\\*\\(\\)\\_\\+\\-\\=\\;\\'\\:\\,\\.\\<\\>\\/\\?\\!]*$",
        message: this.translocoService.translate('validations.password.shouldOnlyContainSpecificCharacters'),
        ignoreEmptyValue: true,
      },
    ];
  }
}
