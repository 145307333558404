/**
 * Findings Datatype
 */
export class Findings {
  tisLocalisation?: number; // China?
  tisTraumaType?: number; // China?
  tisCirculation?: number; // China?
  tisBreathing?: number; // China?
  tisAwareness?: number; // China?

  circulation?: number | string;
  intubation?: number | string;
  priority?: number | string;
  arrival_time?: number | string;

  supplement1key?: number | string;
  supplement1value?: number | string;
  supplement2key?: number | string;
  supplement2value?: number | string;
  supplement3key?: number | string;
  supplement3value?: number | string;

  pain?: number | string;
  pain_radiation?: number | string;
  event_time?: number | string;
  event_date?: number | string;
  cardio_intubated?: number | string;
  cardio_catecholamins?: number | string;
  cardio_cpr?: number | string;
  cardio_stemi?: number | string;
  cardio_catheter?: number | string;
  cardio_bypass?: number | string;
  cardio_heartattack?: number | string;

  // TODO _____ HIER ____ HIER ____ HIER alles für V2

  initial_gcs?: number | string;
  initial_gcs_eyes?: number | string;
  initial_gcs_motoric?: number | string;
  initial_gcs_verbal?: number | string;

  // PEES Krampfanfall
  pees_eyes?: number | string;
  pees_eyes_value?: number | string;
  pees_view?: number | string;
  pees_view_value?: number | string;
  pees_arm_leg?: number | string;
  pees_arm_leg_value?: number | string;
  pees_twitching?: number | string;
  pees_twitching_value?: number | string;
  pees_mouth?: number | string;
  pees_mouth_value?: number | string;
  pees_extremities?: number | string;
  pees_extremities_value?: number | string;
  pees_duration_seizure?: number | string;
  pees_duration_seizure_value?: number | string;
  pees_duration_consciousness?: number | string;
  pees_duration_consciousness_value?: number | string;
  pees_tongue_bite?: number | string;
  pees_tongue_bite_value?: number | string;
  pees_epilepsy?: number | string;
  pees_epilepsy_value?: number | string;
  pees_sum?: number | string;

  speech_disorder?: number | string;

  stroke_4iss_vigilance?: number | string;
  stroke_4iss_vigilance_value?: number | string;
  stroke_4iss_hemiparese?: number | string;
  stroke_4iss_hemiparese_value?: number | string;
  stroke_4iss_hemiparese_side?: number | string;
  stroke_4iss_hemiparese_side_value?: number | string;
  stroke_4iss_turn?: number | string;
  stroke_4iss_turn_value?: number | string;

  // generic stroke new
  stroke_anticoagulation?: string; // stroke_gerinnungshemmung
  stroke_marcumar?: string; // stroke_marcumar
  stroke_last_seen_well_text?: string; // stroke_last_seen_well_text
  stroke_last_seen_well_time?: number | string; // stroke_last_seen_well_zeit
  stroke_last_seen_well_date?: number | string; // stroke_last_seen_well_datum

  stroke_prae_mrs?: string; // stroke_prae_mrs
  stroke_previousillness_atrialfibrillation?: string; // stroke_vorerkrankung_vorhofflimmern
  stroke_previousillness_epilepsy?: string; // stroke_vorerkrankung_epilepsie
  stroke_previousillness_migraine?: string; // stroke_vorerkrankung_migraene
  stroke_previousillness_diabetes?: string; // stroke_vorerkrankung_diabetes
  stroke_previousillness_hypertension?: string; // stroke_vorerkrankung_hypertonie
  stroke_injuries?: string; // stroke_verletzungen

  // 4I-SS
  preregistration_stroke_4iss_sum?: number; // 4iss_summe
  preregistration_stroke_4iss_age?: number; // 4iss_alter
  preregistration_stroke_4iss_vigilance?: string; // 4iss_vigilanz
  preregistration_stroke_4iss_turn?: string; // 4iss_wendung
  preregistration_stroke_4iss_hemiparese?: string; // 4iss_hemiparese
  preregistration_stroke_4iss_hemiparese_side?: string; // 4iss_hemiparese_seite
  preregistration_stroke_4iss_speech_disorder?: string; // 4iss_sprachstoerung

  // snihss
  stroke_snihss_consciousness_text?: string; // stroke_snihss_bewusstsein_text
  stroke_snihss_consciousness_nr?: number; // stroke_snihss_bewusstsein_nr
  stroke_snihss_face_text?: string; // stroke_snihss_gesicht_text
  stroke_snihss_face_nr?: number; // stroke_snihss_gesicht_nr
  stroke_snihss_motorics_text?: string; // stroke_snihss_motorik_text
  stroke_snihss_motorics_nr?: number; // stroke_snihss_motorik_nr
  stroke_snihss_sensitivity_text?: string; // stroke_snihss_sensibilitaet_text
  stroke_snihss_sensitivity_nr?: number; // stroke_snihss_sensibilitaet_nr
  stroke_snihss_speech_impediment_text?: string; // stroke_snihss_sprachstoerung_text
  stroke_snihss_speech_impediment_nr?: number; // stroke_snihss_sprachstoerung_nr
  stroke_snihss_washed_out_text?: string; // stroke_snihss_verschwaschen_text
  stroke_snihss_washed_out_nr?: number; // stroke_snihss_verschwaschen_nr
  stroke_snihss_sum?: number; // stroke_snihss_summe

  // face2ad
  stroke_face2ad_face?: string; // stroke_face2ad_gesicht
  stroke_face2ad_face_nr?: number; // stroke_face2ad_gesicht_nr
  stroke_face2ad_parese_arm?: string; // stroke_face2ad_parese_arm
  stroke_face2ad_parese_arm_nr?: number; // stroke_face2ad_parese_arm_nr
  stroke_face2ad_consciousness?: string; // stroke_face2ad_bewusstsein
  stroke_face2ad_consciousness_nr?: number; // stroke_face2ad_bewusstsein_nr
  stroke_face2ad_stoveview?: string; // stroke_face2ad_herdblick
  stroke_face2ad_stoveview_nr?: number; // stroke_face2ad_herdblick_nr
  stroke_face2ad_atrialfibrillation?: string; // stroke_face2ad_vorhofflimmern
  stroke_face2ad_atrialfibrillation_nr?: number; // stroke_face2ad_vorhofflimmern_nr
  stroke_face2ad_rr_dia_85?: string; // stroke_face2ad_rr_dia_85
  stroke_face2ad_rr_dia_85_nr?: number; // stroke_face2ad_rr_dia_85_nr
  stroke_face2ad_sum?: number; // stroke_face2ad_summe
  stroke_face2ad_parese_bein?: string; // stroke_face2ad_parese_bein
  stroke_face2ad_aphasie?: string; // stroke_face2ad_aphasie
  stroke_face2ad_side?: string; // stroke_face2ad_seite

  // Voranmeldung
  preregistration_event_time_unknown?: string; // voranmeldung_ereigniszeitpunkt_unbekannt

  preregistration_respiratory?: string; // voranmeldung_atmung
  preregistration_circulation?: string; // voranmeldung_kreislauf
  preregistration_consciousness?: string; // voranmeldung_bewusstsein
  preregistration_infection_status?: string; // voranmeldung_infektion
  preregistration_priority?: string; // voranmeldung_kategorie
  preregistration_emergency_room?: string; // voranmeldung_schockraum
  preregistration_arrival_time?: string; // voranmeldung_ankunft_zeit
  preregistration_arrival_date?: string; // voranmeldung_ankunft_datum

  // Voranmeldung Schlaganfall > Thrombozytenaggregationshemmer
  preregistration_stroke_platelet_aggregation_inhibitors?: string; // voranmeldung_stroke_gerinnungshemmung
  // Voranmeldung Schlaganfall > Antikoagulantien
  preregistration_stroke_anticoagulants?: string; // voranmeldung_schlaganfall_antikoagulantien
  // Voranmeldung Schlaganfall > Ereignis > Last-Seen-Well
  preregistration_stroke_last_seen_well_time?: string; // voranmeldung_stroke_last_seen_well_zeit
  preregistration_stroke_last_seen_well_text?: string; // stroke_last_seen_well_text
  // Voranmeldung Schlaganfall > Vorzustand > Prä-mRS
  preregistration_stroke_prae_mrs?: string; // voranmeldung_prae_mrs_value
  // Voranmeldung Schlaganfall > Vorzustand > Verletzungen / Operationen
  preregistration_stroke_injuries?: string; // voranmeldung_stroke_verletzungen
  // Voranmeldung Schlaganfall > Vorzustand > Vorerkrankungen
  preregistration_stroke_history_afib?: string; // voranmeldung_stroke_vorerkrankung_vorhofflimmern
  preregistration_stroke_history_epilepsy?: string; // voranmeldung_stroke_vorerkrankung_epilepsie
  preregistration_stroke_history_migraine?: string; // voranmeldung_stroke_vorerkrankung_migraene
  preregistration_stroke_history_diabetes?: string; // voranmeldung_stroke_vorerkrankung_diabetes
  preregistration_stroke_history_hypertension?: string; // voranmeldung_stroke_vorerkrankung_hypertonie
  preregistration_stroke_history_coronar_artery_disease?: string; // voranmeldung_stroke_vorerkrankung_khk
  preregistration_stroke_history_heart_failure?: string; // voranmeldung_stroke_vorerkrankung_herzinsuffizienz
  preregistration_stroke_history_pavk?: string; // voranmeldung_stroke_vorerkrankung_pavk

  // Krampfanfall
  preregistration_seizure_state?: string; // voranmeldung_krampfanfall_zustand

  // Voranmeldung ACS > Aktuelle Situation > Schmerzen
  preregistration_acs_pain_radiation?: string; /// voranmeldung_acs_schmerz_ausstrahlung
  // Voranmeldung ACS > Aktuelle Situation > Maßnahmen
  preregistration_acs_intubated?: string; // voranmeldung_acs_intubation
  preregistration_acs_catecholamine?: string; // voranmeldung_acs_katecholamingabe
  // Voranmeldung ACS > Aktuelle Situation > Reanimation
  preregistration_acs_reanimation?: string; // voranmeldung_acs_reanimation
  // Voranmeldung ACS > KHK Vorgeschichte
  preregistration_acs_heart_catheter?: string; // voranmeldung_acs_herzkatheter
  preregistration_acs_heart_bypass?: string; // voranmeldung_acs_bypass
  preregistration_acs_heart_attack?: string; // voranmeldung_acs_herzinfarkt
  // Voranmeldung ACS > ACS Spektrum
  preregistration_acs_spectrum?: string; // ACS Spektrum
  // Voranmeldung ACS > Antikoagulatien
  preregistration_acs_anticoagulants?: string; //voranmeldung_acs_antikoagulantien
  // Voranmeldung ACS > Übergabepunkt
  preregistration_acs_transfer_point?: string; // Noch Übergabepunkt // voranmeldung_acs_uebergabe

  // Voranmeldung Herzstillstand > Vermutete Ursache
  preregistration_cardiac_arrest_myocardial_infarction?: string; // voranmeldung_stillstand_myokardinfarkt
  preregistration_cardiac_arrest_hypoxia?: string; // voranmeldung_stillstand_hypoxie
  preregistration_cardiac_arrest_pulmonary_embolism?: string; // voranmeldung_stillstand_lungenembolie
  preregistration_cardiac_arrest_hypovolemia?: string; // voranmeldung_stillstand_hypovolaemie
  preregistration_cardiac_arrest_glycemia?: string; // voranmeldung_stillstand_glykaemie
  preregistration_cardiac_arrest_pericardial_tamponade?: string; // voranmeldung_stillstand_herzbeutel
  preregistration_cardiac_arrest_intoxication?: string; // voranmeldung_stillstand_intoxikation
  preregistration_cardiac_arrest_pneumothorax?: string; // voranmeldung_stillstand_pneumothorax
  preregistration_cardiac_arrest_unknown?: string; // voranmeldung_stillstand_unbekannt
  preregistration_cardiac_arrest_other?: string; // voranmeldung_stillstand_sonstige
  // Herzstillstand > Initialer Rhythmus
  preregistration_cardiac_arrest_initial_rhythm?: string; // voranmeldung_stillstand_rhythmus
  // Herzstillstand > Zustand
  preregistration_cardiac_arrest_condition?: string; // voranmeldung_stillstand_zustand

  // Voranmeldung Polytrauma > Sichtbare Verletzungen
  preregistration_poly_visible_unstable_thorax?: string; // voranmeldung_poly_sichtbar_thorax
  preregistration_poly_visible_unstable_pelvis?: string; // voranmeldung_poly_sichtbar_becken
  preregistration_poly_visible_trunk_neck?: string; // voranmeldung_poly_sichtbar_rumpf_hals
  preregistration_poly_visible_amputation?: string; // voranmeldung_poly_sichtbar_amputation
  preregistration_poly_visible_spinal_injury?: string; // voranmeldung_poly_sichtbar_wirbelsaeule
  preregistration_poly_visible_intervention?: string; // voranmeldung_poly_sichtbar_intervention
  preregistration_poly_visible_fracture?: string; // voranmeldung_poly_sichtbar_fraktur
  preregistration_poly_visible_burn?: string; // voranmeldung_poly_sichtbar_verbrennung
  // Voranmeldung Polytrauma > Innere Verletzungen
  preregistration_poly_internal_efast?: string; // voranmeldung_poly_innere_efast
  preregistration_poly_internal_shock_index?: string; // voranmeldung_poly_innere_schockindex
  preregistration_poly_internal_other?: string; // voranmeldung_poly_innere_sonstige

  // Voranmeldung SHT > Relevante Symptomatik
  preregistration_tbi_symptom_brain_pressure?: string; // voranmeldung_sht_symptom_hirndruck
  preregistration_tbi_symptom_pupil_difference?: string; // voranmeldung_sht_symptom_pupillen
  preregistration_tbi_symptom_accompanying?: string; // voranmeldung_sht_symptom_begleit
  preregistration_tbi_symptom_amnesia?: string; // voranmeldung_sht_symptom_amnesie
  preregistration_tbi_symptom_visual_disturbance?: string; // voranmeldung_sht_symptom_sehstoerung
  preregistration_tbi_symptom_unknown?: string; // voranmeldung_sht_symptom_unbekannt
  preregistration_tbi_symptom_other?: string; // voranmeldung_sht_symptom_sonstige

  // Voranmeldung GI-Blutung
  preregistration_gastrointestinal_bleeding_symptom_type?: string; // voranmeldung_giblutung_symptomart

  // Voranmeldung Paedeatrie
  preregistration_pediatrics_weight?: string; // voranmeldung_paediatrie_gewicht

  // Voranmeldung Sepsis > Vermutete Sepsisherde
  preregistration_sepsis_skin?: string; // voranmeldung_sepsis_haut
  preregistration_sepsis_urinary_and_reproductive_organs?: string; // voranmeldung_sepsis_geschlechtsorgane
  preregistration_sepsis_umbilical_newborns?: string; // voranmeldung_sepsis_nabel
  preregistration_sepsis_ent_area?: string; // voranmeldung_sepsis_hno
  preregistration_sepsis_intestine?: string; // voranmeldung_sepsis_darm
  preregistration_sepsis_biliary_tract?: string; // voranmeldung_sepsis_gallenwege
  preregistration_sepsis_heart?: string; // voranmeldung_sepsis_herz
  preregistration_sepsis_unknown?: string; // voranmeldung_sepsis_unbekannt
  preregistration_sepsis_other?: string; // voranmeldung_sepsis_sonstige

  // Voranmeldung Sepsis > qSOFA
  preregistration_qsofa_sum?: string; // voranmeldung_qsofa // qSOFA Summe
  preregistration_qsofa_blood_pressure?: string; // voranmeldung_qsofa // Blutdruck
  preregistration_qsofa_respiratory_rate?: string; // voranmeldung_qsofa // Atemfrequenz
  preregistration_qsofa_altered_consciousness?: string; // voranmeldung_qsofa // verändertes Bewusstsein	Glasgow Coma Score  < 15
}
