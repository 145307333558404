import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { GroupList, GroupServable, User, UserServable } from '@nida-web/api/generic-interfaces/user-management';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { HashCreator } from '../helper/HashCreator';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@jsverse/transloco';
import { DxListComponent } from 'devextreme-angular';
import { SessionInformation, SessionManagerService } from '@nida-web/api/rest/authentication';
import { PasswordValidationService } from '@nida-web/shared/utils';
import { ModuleSettingsService } from '@nida-web/core';

@Component({
  selector: 'nida-web-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserDetailComponent implements OnInit {
  @ViewChild('groupList', { static: false }) groupListView: DxListComponent | undefined;
  @Input() personal: User | undefined;
  @Input() currentUser: SessionInformation | undefined;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() updatedUser: EventEmitter<User> = new EventEmitter();

  public nidaAuthFlag = false;
  public personalForm: UntypedFormGroup;
  public userCredentialsForm: UntypedFormGroup;
  public newEntryMode = false;
  public groups: GroupList | undefined;
  public groupStorage: DataSource | undefined;
  public selectionModeValue = 'multiple';
  public preSelectedItemKeysStorage: number[] = [];
  public onlyPreSelectedItemKeys: number[] = [];
  public personalBody: User | undefined;
  public isLoaded: boolean | undefined;
  public showEmail: '' | 'none' = 'none';
  private readyForGroupChanges = new BehaviorSubject(false);

  public passwordValidationRules: Array<any>;
  passwordButton;
  passwordMode: 'password' | 'text' = 'password';

  constructor(
    private fb: UntypedFormBuilder,
    private personalService: UserServable,
    private userGroupService: GroupServable,
    private translocoService: TranslocoService,
    private sessionManager: SessionManagerService,
    private passwordValidationService: PasswordValidationService,
    private moduleSettingsService: ModuleSettingsService
  ) {
    this.personalForm = this.fb.group({});
    this.nidaAuthFlag = this.setNidaAuthFlag();
    this.userCredentialsForm = new UntypedFormGroup({
      id: new UntypedFormControl(0),
      idMandanten: new UntypedFormControl(0),
      userName: new UntypedFormControl(null),
      firstName: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      userPassword: new UntypedFormControl(null),
    });
  }

  ngOnInit() {
    if (this.currentUser === undefined) {
      this.sessionManager.getSessionInformation().subscribe((session) => {
        this.currentUser = session;

        this.initComponent();
      });
    } else {
      this.initComponent();
    }

    this.passwordValidationRules = this.passwordValidationService.getPasswordValidationRulesWithoutRequired();
    if (this.newEntryMode) {
      this.passwordValidationRules.push({ type: 'required' });
    }

    this.moduleSettingsService.getSettings().subscribe((settings) => {
      if (settings.resetUserPassword) {
        this.showEmail = '';
      }
    });

    this.passwordButton = {
      icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
      type: 'default',
      onClick: () => {
        this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
      },
    };
  }

  private initComponent() {
    this.isLoaded = false;
    this.manageStatus();
    this.manageBuildForm();
    this.manageGroups();
    this.nidaAuthFlag = this.setNidaAuthFlag();
  }

  private setNidaAuthFlag() {
    if (!this.personal?.loginType) {
      return true;
    }
    //loginType 0 => NIDA Login
    else if (this.personal.loginType == 0) {
      return true;
    }
    //loginType 1 => LDAP login or 2 => OIDC login
    return false;
  }

  private manageStatus() {
    // ... a) check if component is for new entry (this.personal = undefined)
    // ... or if component is in master view / user-detail
    // ... b) get the currently logged in user (=> has permissions?)

    if (this.personal === undefined) {
      this.personal = new User();
      this.newEntryMode = true;
      if (this.currentUser !== undefined) {
        this.personal.idMandanten = this.currentUser.tenantId;
      }
    } else {
      this.userCredentialsForm = new UntypedFormGroup({
        userName: new UntypedFormControl(this.personal.userName),
        firstName: new UntypedFormControl(this.personal.firstName),
        lastName: new UntypedFormControl(this.personal.lastName),
        email: new UntypedFormControl(this.personal.email),
        userPassword: new UntypedFormControl(null),
      });
    }
  }

  getGroupsByPersonalId(id: number) {
    if (id !== null && id !== undefined) {
      const subscription = this.userGroupService.getGroupsByUserId(id).subscribe((groups: GroupList) => {
        const groupIds = this.extractSelectedGroupIds(groups);
        //ignore the groups that the currently logged-in user doesn't have as they can't add /remove them
        const filteredgroupIds = groupIds.filter((id) => (this.groups ? this.groups.some((group) => group.id === id) : false));
        this.onlyPreSelectedItemKeys = filteredgroupIds;
        this.preSelectedItemKeysStorage = filteredgroupIds;
        subscription.unsubscribe();
      });
    }
  }

  getLoaded() {
    return this.isLoaded;
  }

  extractSelectedGroupIds(groups: GroupList): number[] {
    // ... returns only an array of group-ids e.g. [1, 2, 3]
    // ... takes an array of groups from interface GroupList

    const groupIds: number[] = [];

    if (groups !== null && groups !== undefined) {
      for (const group of groups) {
        if (group === undefined || group.id === undefined) {
          throwError('A Group is NULL or not initalized correctly.');
          continue;
        }
        groupIds.push(group.id);
      }
    }
    return groupIds;
  }

  /**
   * @return true if Validation complete
   */
  public manageSaving(): Observable<boolean> {
    const result: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    this.generatePersonalBody();

    if (this.newEntryMode) {
      this.saveNewPerson();
    } else {
      this.savePersonChanges();
    }

    this.readyForGroupChanges.subscribe((isReady) => {
      if (isReady) {
        this.saveGroupChanges().subscribe((answer) => {
          if (answer) {
            result.next(true);
          } else {
            result.next(false);
          }
        });
      }
    });

    notify({
      message: this.translocoService.translate('saveSuccessfully'),
      type: 'Success',
      displayTime: 5000,
    });
    // result.next(true);
    return result;
  }

  generatePersonalBody() {
    if (this.personal === undefined) {
      throwError('Personal undefined');
      return;
    }

    this.personalBody = {
      ...this.userCredentialsForm.getRawValue(),
      id: this.personal.id,
      idMandanten: this.personal.idMandanten,
      userPassword:
        this.userCredentialsForm.getRawValue().userPassword === null
          ? null
          : HashCreator.createHash(this.userCredentialsForm.getRawValue().userPassword, '', false, 'MD5'),
    };
  }

  saveNewPerson(): void {
    if (this.personalBody) {
      this.personalService.addUser(this.personalBody).subscribe((newID) => {
        this.personal = this.personalBody;
        if (this.personal === undefined) {
          throwError('Bad Body');
          return;
        }
        if (newID) {
          this.personal.id = newID.id;
          this.personal.idMandanten = newID.idMandanten;
          console.log('Added Succeed');
          this.readyForGroupChanges.next(true);
        } else {
          console.log('Added failed!');
        }
      });
    }
  }

  savePersonChanges(): void {
    if (this.personalBody && this.personal !== undefined && this.personal.id) {
      if (this.personalBody.userPassword === null || this.personalBody.userPassword === '') {
        this.personalBody.userPassword = undefined;
      }

      this.personalService.updateUser(this.personalBody).subscribe((isUpdated: boolean) => {
        if (isUpdated) {
          this.personal = this.personalBody;
          this.updatedUser.emit(this.personal);
        }
        this.readyForGroupChanges.next(true);
      });
    }
  }

  saveGroupChanges(): Observable<boolean> {
    const oldGroups = this.preSelectedItemKeysStorage;
    console.log('Saving GroupChanges', oldGroups);
    const result: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    if (this.groupListView === undefined) {
      throwError('Couldnt find GrouplistView');
      result.next(false);
      return result.asObservable();
    }
    const newGroups = this.groupListView.instance.option('selectedItemKeys');

    if (newGroups) {
      const reductionList = this.extractGroupDifferences(oldGroups, newGroups);
      const additionList = this.extractGroupDifferences(newGroups, oldGroups);
      console.log('todelete', reductionList);
      console.log('toadd', additionList);

      if (this.personal === undefined || this.currentUser === undefined) {
        console.log('Fehler!!', this.personal, this.currentUser);
        throwError('Personal or CurrentUser not Found!');
        result.next(false);
        return result.asObservable();
      }

      let returned = 0;
      let successed = 0;
      console.log('Loops');
      this.loopForDeleting(reductionList, this.personal['id']).subscribe((answer) => {
        returned++;
        if (answer) {
          successed++;
        }

        if (returned === 2) {
          if (successed === 2) {
            result.next(true);
          } else {
            result.next(false);
          }
        }
      });
      this.loopForSaving(additionList).subscribe((answer) => {
        returned++;
        if (answer) {
          successed++;
        }

        if (returned === 2) {
          if (successed === 2) {
            result.next(true);
          } else {
            result.next(false);
          }
        }
      });
    } else {
      result.next(true);
    }
    return result.asObservable();
  }

  extractGroupDifferences(referenceList: number[], alteredList: number[]): number[] {
    const groupIds: number[] = [];
    for (const entry of referenceList) {
      if (!alteredList.includes(entry)) {
        groupIds.push(entry);
      }
    }
    return groupIds;
  }

  // ------------------------------------------------
  // --- Saving area --------------------------------
  // ------------------------------------------------

  loopForSaving(changedGroupsList: number[]): Observable<boolean> {
    // as of REST API definition personal.id can be undefined, so we have to check it here - it is useless to change group memberships
    // without personal.id
    const result: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    console.log('LoopSaving');
    if (this.personal !== undefined && this.personal.id !== undefined && this.personal.id >= 0) {
      let comeback = 0;

      if (changedGroupsList.length === 0) {
        result.next(true);
      }
      console.log('Saving Groups!', changedGroupsList);

      for (const groupId of changedGroupsList) {
        this.userGroupService.addUser(this.personal.id, groupId, this.personal.idMandanten).subscribe((isAdded) => {
          if (isAdded) {
            comeback++;

            if (comeback === changedGroupsList.length) {
              result.next(true);
            }
          } else {
            result.next(false);
          }
        });
      }
    } else {
      result.next(false);
    }
    return result.asObservable();
  }

  loopForDeleting(changedGroupsList: number[], personalId: number): Observable<boolean> {
    const result: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    let comeback = 0;

    if (changedGroupsList.length === 0) {
      result.next(true);
    }

    for (const groupId of changedGroupsList) {
      this.userGroupService.removeUser(personalId, groupId).subscribe((answer) => {
        if (answer) {
          comeback++;

          if (comeback === changedGroupsList.length) {
            result.next(true);
          }
        } else {
          result.next(false);
          throwError('Group deleting error');
        }
      });
    }
    return result.asObservable();
  }

  private manageBuildForm(): void {
    // ... working for both status
    // ... if new entry: personal.name === undefined = empty value
    if (this.personal === undefined) {
      this.personal = new User();
    }
    this.personalForm = this.fb.group({
      lastName: [this.personal.lastName, Validators.required],
      firstName: [this.personal.firstName, Validators.required],
      userName: [this.personal.userName, Validators.required],
      email: [this.personal.email],
      userPassword: [null],
    });
  }

  private manageGroups() {
    // ... managing groups in general;
    // ... if newEntryMode is false (== user detail for existing person):
    // ... getting chosen groups from displayed person (by id)
    // ... Subscribing to 'loaded' from userGroupService:
    // ... if loaded===true:
    // ... - getting all possible user groups
    // ... - setting up devextreme mandatory groupStorage

    this.userGroupService.getGroupsByUserId(this.currentUser?.userId !== undefined ? this.currentUser.userId : 0).subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        // ... !this.newEntryMode => false => existing entry
        if (!this.newEntryMode) {
          // as of REST API definition personal.id can be undefined, so we have to check it here - it is useless to set group memberships
          // without personal.id
          if (this.personal === undefined) {
            throwError('Actual User is not defined!');
            return;
          }
          if (this.personal.id) {
            this.getGroupsByPersonalId(this.personal.id);
          }
        }
        this.isLoaded = true;
        this.setAllGroups();
      }
    });
  }

  private setAllGroups() {
    this.groupStorage = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: this.groups,
      }),
    });

    this.groupStorage.sort('groupName');
  }

  triggerCancel() {
    this.cancel.emit();
  }

  triggerSave() {
    this.save.emit();
  }
}
