import { Injectable } from '@angular/core';
import { PDFElement, PDFTypes } from '@nida-web/api/generic-interfaces/attachments';
import { DisinfectionProtocolPdfList } from '../models/disinfectionProtocolPdfList';
import { TranslocoService } from '@jsverse/transloco';
import { DisinfectionProtocolPdf } from '../models/disinfectionProtocolPdf';
import { PdfList } from '../models/pdfList';
import { PdfListItem } from '../models/pdfListItem';
import { ECGPdfList } from '../models/ecgPdfList';
import { ECGPdfListItem } from '../models/ecgPdfListItem';
import { PatientClarificationPdfList } from '../models/patientClarificationPdfList';
import { PatientClarificationPdfListItem } from '../models/patientClarificationPdfListItem';
import { RoutineDisinfection2Pdf } from '../models/routineDisinfection2Pdf';
import { RoutineDisinfection2PdfList } from '../models/routineDisinfection2PdfList';

@Injectable({
  providedIn: 'root',
})
export class PDFItemMappingService {
  constructor(public transloco: TranslocoService) {}

  public mapDisinfectionEntries(itemList: DisinfectionProtocolPdfList | RoutineDisinfection2PdfList): PDFElement[] {
    const resultList: PDFElement[] = [];

    for (const apiPDF of itemList.data) {
      resultList.push(this.mapDisinfectionEntry(apiPDF));
    }
    return resultList;
  }

  public mapPDFEntries(itemList: PdfList): PDFElement[] {
    const resultList: PDFElement[] = [];

    if (itemList && itemList.data) {
      for (const apiPDF of itemList.data) {
        resultList.push(this.mapPDFEntry(apiPDF));
      }
      return resultList;
    }
    return resultList;
  }

  public mapEcgEntries(itemList: ECGPdfList): PDFElement[] {
    const resultList: PDFElement[] = [];

    for (const apiPDF of itemList.data) {
      resultList.push(this.mapEcgEntry(apiPDF));
    }
    return resultList;
  }

  public mapEcgEntry(apiPDF: ECGPdfListItem): PDFElement {
    const mappedPDF: PDFElement = {};
    mappedPDF.nidaId = apiPDF.nidaId ? apiPDF.nidaId : undefined;
    mappedPDF.timeStamp = apiPDF.timeStamp ? new Date(apiPDF.timeStamp) : undefined;
    mappedPDF.description = apiPDF.measurement ? apiPDF.measurement : undefined;
    mappedPDF.id = apiPDF.documentId ? apiPDF.documentId : undefined;
    mappedPDF.name = apiPDF.leads ? apiPDF.leads + '' : undefined;
    mappedPDF.pdfType = PDFTypes.ECG;
    mappedPDF.status = ' - ';

    // export_file_name="routinedesinfektion2_;$nida_id;.pdf  maybe more than one disinfection protocol exist on the pad
    if (!mappedPDF.description) {
      mappedPDF.description = this.transloco.translate('ECG');
    } else {
      console.log('Mapping error disinfection');
    }
    return mappedPDF;
  }

  public mapClarificationEntries(itemList: PatientClarificationPdfList): PDFElement[] {
    const resultList: PDFElement[] = [];

    for (const apiPDF of itemList.data) {
      resultList.push(this.mapPDFClarificationEntry(apiPDF));
    }
    return resultList;
  }
  public mapPDFClarificationEntry(apiPDF: PatientClarificationPdfListItem): PDFElement {
    const mappedPDF: PDFElement = {};
    mappedPDF.nidaId = apiPDF.nidaId ? apiPDF.nidaId : undefined;
    mappedPDF.timeStamp = apiPDF.timeStamp ? new Date(apiPDF.timeStamp) : undefined;
    mappedPDF.description = apiPDF.description ? apiPDF.description : undefined;
    mappedPDF.id = apiPDF.id ? apiPDF.id : undefined;
    mappedPDF.name = apiPDF.name ? apiPDF.name : undefined;
    mappedPDF.status = ' - ';
    mappedPDF.pdfType = PDFTypes.PatientClarification;
    mappedPDF.description = this.transloco.translate('Patient Clarification protocol');
    return mappedPDF;
  }

  public mapDisinfectionEntry(apiPDF: DisinfectionProtocolPdf | RoutineDisinfection2Pdf): PDFElement {
    const mappedPDF: PDFElement = {};
    mappedPDF.nidaId = apiPDF.nidaId ? apiPDF.nidaId : undefined;
    mappedPDF.timeStamp = apiPDF.timeStamp ? new Date(apiPDF.timeStamp) : undefined;
    mappedPDF.description = apiPDF.description ? apiPDF.description : undefined;
    mappedPDF.id = apiPDF.id ? apiPDF.id : undefined;
    mappedPDF.name = apiPDF.name ? apiPDF.name : undefined;
    mappedPDF.pdfType = PDFTypes.Unknown;
    mappedPDF.status = ' - ';

    // export_file_name="routinedesinfektion2_;$nida_id;.pdf  maybe more than one disinfection protocol exist on the pad
    if (!mappedPDF.description || mappedPDF.description === 'routinedesinfektion2_' || mappedPDF.description === 'desinfektprot') {
      mappedPDF.pdfType = PDFTypes.DisinfectionProtocol;
      mappedPDF.description = this.transloco.translate('Disinfection protocol');
    } else if (mappedPDF.description === 'routinedesinfektion2') {
      mappedPDF.pdfType = PDFTypes.RoutineDisinfectionProtocol;
      mappedPDF.description = this.transloco.translate('routineDisinfectionProtocol');
    } else {
      console.log('Mapping error disinfection');
    }
    return mappedPDF;
  }

  public mapPDFEntry(apiPDF: PdfListItem): PDFElement {
    const mappedPDF: PDFElement = {};

    mappedPDF.nidaId = apiPDF.nidaId;
    mappedPDF.timeStamp = apiPDF.timeStamp ? new Date(apiPDF.timeStamp) : undefined;
    mappedPDF.description = apiPDF.description;
    mappedPDF.id = apiPDF.id;
    mappedPDF.name = apiPDF.name;
    mappedPDF.pdfType = PDFTypes.Unknown;

    if (mappedPDF.description === null || mappedPDF.description === undefined) {
      mappedPDF.pdfType = PDFTypes.Protocol;
      mappedPDF.description = this.transloco.translate('Protocol');
    } else if (mappedPDF.description === 'vollstaendig_') {
      mappedPDF.pdfType = PDFTypes.ProtocolComplete;
      mappedPDF.description = this.transloco.translate('Protocol complete');
    } else if (mappedPDF.description === 'fakturierbar_') {
      // kvb created in web
      mappedPDF.pdfType = PDFTypes.ProtocolBillable;
      mappedPDF.description = this.transloco.translate('Protocol billable');
    } else if (mappedPDF.description === 'Protocol billable') {
      // kvb created in web
      mappedPDF.pdfType = PDFTypes.ProtocolBillable;
      mappedPDF.description = this.transloco.translate('Protocol billable');
    } else if (mappedPDF.description === 'eingereicht_') {
      // kvb created in web
      mappedPDF.pdfType = PDFTypes.ProtocolSubmitted;
      mappedPDF.description = this.transloco.translate('Protocol submitted');
    } else if (mappedPDF.description === 'Protocol submitted') {
      // kvb created in web
      mappedPDF.pdfType = PDFTypes.ProtocolSubmitted;
      mappedPDF.description = this.transloco.translate('Protocol submitted');
    } else if (mappedPDF.description === 'trauma_') {
      mappedPDF.pdfType = PDFTypes.TraumaRegister;
      mappedPDF.description = this.transloco.translate('Trauma register');
    } else if (mappedPDF.description === 'kurzeinsatzprotokoll_') {
      mappedPDF.pdfType = PDFTypes.ProtocolShortMission;
      mappedPDF.description = this.transloco.translate('Protocol short mission');
    } else if (mappedPDF.description === 'trapo_') {
      mappedPDF.pdfType = PDFTypes.TransportProtocol;
      mappedPDF.description = this.transloco.translate('Transport Document');
    } else if (mappedPDF.description === 'mcpr_') {
      // only Bayern
      mappedPDF.pdfType = PDFTypes.SurveyQuestionnaireM;
      mappedPDF.description = this.transloco.translate('Survey Questionnaire M-CPR');
    } else if (mappedPDF.description === 'tcpr_') {
      // only Bayern
      mappedPDF.pdfType = PDFTypes.SurveyQuestionnaireT;
      mappedPDF.description = this.transloco.translate('Survey Questionnaire T-CPR');
    } else if (mappedPDF.description === 'mitfahrverweigerung_') {
      mappedPDF.pdfType = PDFTypes.SurveyQuestionnaireT;
      mappedPDF.description = this.transloco.translate('Refuse transport');
    } else if (mappedPDF.description === 'DGT7ECG_' || (mappedPDF.description && mappedPDF.description.startsWith('CorpulsEkgPdf'))) {
      mappedPDF.pdfType = PDFTypes.ECG;
      mappedPDF.description = this.transloco.translate('ECG PDF');
    } else if (mappedPDF.description === 'abrechnung_') {
      mappedPDF.pdfType = PDFTypes.Billing;
      mappedPDF.description = this.transloco.translate('Billing');
    } else if (mappedPDF.description === '') {
      mappedPDF.pdfType = PDFTypes.Anonymized;
      mappedPDF.description = this.transloco.translate('Anonymized protocol');
    } else {
      mappedPDF.pdfType = PDFTypes.Unknown;
      console.log('PDF type mapping error', mappedPDF);
    }

    switch (apiPDF.printStatus) {
      case 1:
        mappedPDF.status = this.transloco.translate('Read');
        break;
      case undefined:
        mappedPDF.status = ' - ';
        break;
      case null:
      default:
        mappedPDF.status = this.transloco.translate('New');
        break;
    }
    return mappedPDF;
  }
}
