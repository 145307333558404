// noinspection SpellCheckingInspection

import { NavItem } from '@nida-web/core';

export const navigation: NavItem[] = [
  {
    text: 'Current patients',
    path: 'current-patients/overview',
    icon: 'bold-registration',
    permissions: ['klinik.protokoll'],
  },
  {
    text: 'Patients archive',
    path: 'patients/overview',
    icon: 'bold-patientlist',
    permissions: ['klinik.archiv'],
  },
  {
    text: 'User management',
    path: 'users',
    icon: 'bold-administration',
    permissions: ['klinik.admin'],
  },
  {
    text: 'Settings',
    path: 'settings',
    icon: 'bold-settings',
  },
  {
    text: 'Support',
    path: 'support',
    icon: 'bold-question-circle',
  },
  {
    text: 'Log out',
    path: 'logout',
    icon: 'bold-exit',
  },
];
