import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { PersonalRESTService } from '@nida-web/api/rest/user-management';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Environment } from '../models/Environment';
import { ModuleSettingsService } from '@nida-web/core';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordGuard {
  constructor(
    private router: Router,
    private sessionManagerService: SessionManagerService,
    private personalRESTService: PersonalRESTService,
    private moduleSettingsService: ModuleSettingsService,
    @Inject('environment') private environment: Environment
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const mustChangePassword = new ReplaySubject<boolean | UrlTree>(1);
    const authType = this.environment.authType;
    // force changing of password only when using nida (server) login
    if (authType !== 'nida') {
      mustChangePassword.next(true);
    } else {
      this.sessionManagerService
        .getSessionInformation()
        .pipe(take(1))
        .subscribe((sessionInfo) => {
          if (sessionInfo.loggedIn) {
            const userId = sessionInfo.userId;
            if (userId) {
              forkJoin({
                person: this.personalRESTService.getPersonById(String(userId)),
                settings: this.moduleSettingsService.getSettings().pipe(take(1)),
              }).subscribe(({ person, settings }) => {
                if ((person?.passwort_eintragsdatum || settings?.showInitialChangePasswordDialog === false) && person?.typ !== 3) {
                  // dont show change password dialog
                  mustChangePassword.next(true);
                } else {
                  mustChangePassword.next(this.router.parseUrl('changePassword'));
                }
              });
            } else {
              mustChangePassword.next(this.router.parseUrl('login'));
            }
          } else {
            mustChangePassword.next(this.router.parseUrl('login'));
          }
        });
    }
    return mustChangePassword;
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate();
  }
}
