import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiConfig } from '../models/ApiConfig';
import { ConfigRESTServable } from '@nida-web/api/generic-interfaces/config';
import {
  AuthTypeSettingsService,
  ModuleSettingsService,
  PatientSettingsService,
  REDSettingsService,
  StartPageSettingsService,
} from '@nida-web/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigSubjectService implements ConfigRESTServable {
  private apiurl = new ReplaySubject<string>(1);
  public readonly apiUrl = this.apiurl.asObservable();

  private apiconfig = new ReplaySubject<ApiConfig>(1);
  public readonly apiConfig = this.apiconfig.asObservable();

  private apiurlgateway = new ReplaySubject<string>(1);
  public readonly apiUrlGateway = this.apiurl.asObservable();

  constructor(
    private moduleSettingsService: ModuleSettingsService,
    private startPageSettingsService: StartPageSettingsService,
    private authTypeSettingsService: AuthTypeSettingsService,
    private patientSettingsService: PatientSettingsService,
    private redSettingsService: REDSettingsService
  ) {}

  public updateApiUrlSubject(apiUrl: string): void {
    this.apiurl.next(apiUrl);
  }

  public updateApiConfigSubject(apiConfig: ApiConfig): void {
    this.apiconfig.next(apiConfig);

    if (apiConfig.EnableModules === undefined || apiConfig.EnableModules === null) {
      console.warn('EnableModules Parameter is missing. All special modules are automatically deactivated!');
    }

    if (apiConfig.patientDetails) {
      this.patientSettingsService.updateSettings(apiConfig.patientDetails);
    }

    this.moduleSettingsService.updateSettings(apiConfig.EnableModules);

    this.startPageSettingsService.updateSettings(apiConfig.Pages);
    if (apiConfig.AuthType) this.authTypeSettingsService.updateSettings(apiConfig.AuthType);

    this.redSettingsService.updateMaxMissions(apiConfig.maxNumberOfConcurrentlyAcceptedMissions);
  }

  public getRESTURL(): Observable<string> {
    return this.apiurl.asObservable();
  }

  public updateApiUrlGatewaySubject(apiUrl: string): void {
    this.apiurlgateway.next(apiUrl);
  }

  public getRESTGatewayURL(): Observable<string> {
    return this.apiurlgateway.asObservable();
  }
}
