import { SafeResourceUrl } from '@angular/platform-browser';

export class Picture {
  srcUrl?: SafeResourceUrl;
  date?: Date;
  blob?: Blob;
  isVideo?: boolean;
  description?: string;
  filename?: string;
}
