import { NgModule } from '@angular/core';
import { LoginComponent } from './views/login/login.component';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { TranslocoModule } from '@jsverse/transloco';
import {
  DxButtonModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxValidationSummaryModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { ReactiveFormsModule } from '@angular/forms';
import {
  LogoutErrorInterceptorService,
  SessionManagerService,
  ToasterInterceptorService,
  TokenInterceptorService,
  LoginInterceptorService,
} from '@nida-web/api/rest/authentication';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LogoutComponent } from './views/logout/logout.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordPageComponent } from './views/changePassword/change-password-page.component';
import { UserManagementModule } from '@nida-web/user-management';
import { LanguageSelectModule } from '@nida-web/language-select';
import { ResetPasswordComponent } from './views/resetPassword/resetPassword.component';
import { TwoFactorAuthPromptComponent } from './views/two-factor-auth-prompt/two-factor-auth-prompt.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SecureCodeAuthComponent } from './views/secure-code-auth/secure-code-auth.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ChangePasswordPageComponent,
    ResetPasswordComponent,
    TwoFactorAuthPromptComponent,
    SecureCodeAuthComponent,
  ],
  imports: [
    TranslocoModule,
    TranslocoTranslationModule,
    DxTextBoxModule,
    DxFormModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxLoadIndicatorModule,
    DxButtonModule,
    ReactiveFormsModule,
    CommonModule,
    DxSelectBoxModule,
    AuthenticationRoutingModule,
    DxNumberBoxModule,
    LanguageSelectModule,
    UserManagementModule,
    QRCodeModule,
    ClipboardModule,
    BrowserAnimationsModule,
    DxPopupModule,
  ],
  exports: [LoginComponent, ChangePasswordPageComponent, ResetPasswordComponent, TwoFactorAuthPromptComponent, SecureCodeAuthComponent],
  providers: [
    SessionManagerService,
    { provide: HTTP_INTERCEPTORS, useClass: ToasterInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogoutErrorInterceptorService, multi: true },
  ],
})
export class AuthenticationModule {}
