import { Injectable } from '@angular/core';
import { AuthenticationServable, LoginCredential, LoginInformation } from '@nida-web/api/generic-interfaces/authentication';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthConfig, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { AuthTypeConfiguration, AuthTypeSettingsService, ModuleSettingsService } from '@nida-web/core';
import { Router } from '@angular/router';
import { LoginRESTService, SessionManagerService } from '@nida-web/api/rest/authentication';
import { PasswordAdapterService } from '@nida-web/api/rest/nidaserver/password3';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OidcAdapterService implements AuthenticationServable {
  // private readonly tempToken ='Token between check and OIDC Login process';
  private loggedIn: boolean;
  private authConfig!: AuthConfig;
  private authServiceDefined: Subject<boolean> = new ReplaySubject(1);
  private userId?: number;
  private setNidaMobilePassword: boolean;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    public authTypeSettingsService: AuthTypeSettingsService,
    private loginRESTService: LoginRESTService,
    private passwordMobileService: PasswordAdapterService,
    private moduleSettingsService: ModuleSettingsService,
    private sessionManagerService: SessionManagerService
  ) {
    this.setNidaMobilePassword = false;
    this.getSettings();
    // console.log('OidcAdapterService - constructor START');

    // this.oauthService.events.subscribe((event) => {
    //   console.log(event);
    // });

    this.oauthService.events.pipe(filter((e) => e.type === 'session_terminated')).subscribe((e) => {
      console.log('Your session has been terminated!', e);
      localStorage.removeItem('access_token');
      this.sessionManagerService.resetSession();
      if (this.authConfig.postLogoutRedirectUri) {
        window.location.href = this.authConfig.postLogoutRedirectUri;
      }
    });

    this.loggedIn = false;
    authTypeSettingsService.getSettings().subscribe((settings: AuthTypeConfiguration) => {
      // console.log('>>> OidcAdapterService, getSettings', settings);
      if (settings.authConfig) {
        this.authConfig = settings.authConfig;
        this.authConfig.redirectUri = window.location.origin + this.authConfig.redirectUri;
        // console.log(this.authConfig);
        // console.log('OidcAdapterService - configure');
        oauthService.configure(this.authConfig);
        // console.log('OidcAdapterService - setupAutomaticSilentRefresh');
        oauthService.setupAutomaticSilentRefresh();
        oauthService
          .loadDiscoveryDocument()
          .then((oAuthSuccessEvent: OAuthSuccessEvent) => {
            if (oAuthSuccessEvent) {
              this.authServiceDefined.next(true);
            }
          })
          .catch(() => {
            console.warn('No DiscoveryDocument, trying with config.');
            this.authServiceDefined.next(true);
          });
      } else {
        console.warn('No oauth2 oidc-config (authConfig) provided in config.json!');
      }
    });
  }

  getAuthState(): Observable<LoginInformation> {
    const result = new ReplaySubject<LoginInformation>(1);
    const resultLoginInfo = new LoginInformation();
    this.authServiceDefined.subscribe((value) => {
      if (value) {
        this.oauthService
          .tryLogin()
          .then(() => {
            // console.log('OidcAdapterService - getAuthState - PROMISE');

            const claims = this.oauthService.getIdentityClaims();
            // console.log('OidcAdapterService - getAuthState - CLAIMS ', claims);

            this.loggedIn = this.oauthService.hasValidIdToken();
            const idToken = this.oauthService.getIdToken();
            const accessToken = this.oauthService.getAccessToken();

            // console.log(this.oauthService.getIdentityClaims());
            // console.log(this.oauthService.getGrantedScopes());
            //
            // console.log('idToken: ', idToken);
            // console.log('accessToken: ', accessToken);
            // console.log('OidcAdapterService - getAuthState loggedIn: ' + this.loggedIn);

            // workaround for firefox to not reload the page on an ChunkLoadError
            localStorage.removeItem('OIDCLogin');

            if (this.loggedIn) {
              // console.log('getAuthState: LOGGED IN : TRUE');
              localStorage.setItem('access_token', accessToken);
              // console.log('set localStorage:access_token:', accessToken);

              this.loginRESTService.getCurrentUser().subscribe(
                (responseUser) => {
                  // console.log('responseUser: (from REST)', responseUser);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  resultLoginInfo.userName = claims.preferred_username ? claims.preferred_username : responseUser.userName;
                  resultLoginInfo.permissions = responseUser.permissions;
                  resultLoginInfo.loggedIn = this.loggedIn;
                  resultLoginInfo.loginToken = idToken;
                  resultLoginInfo.id = responseUser.id;
                  resultLoginInfo.clinics = responseUser.postfach;
                  resultLoginInfo.clientInfo = responseUser.clientInfo;
                  resultLoginInfo.idMandanten = responseUser.mandant;
                  this.userId = responseUser.id;
                  result.next(resultLoginInfo);
                  // console.log('LOGGED IN : TRUE : resultLoginInfo', resultLoginInfo);
                  if (this.setNidaMobilePassword) {
                    this.checkMobilePassword();
                  }
                },
                (error) => {
                  if (error.status === 401) {
                    this.logout();
                  } else {
                    throw error;
                  }
                }
              );
            } else {
              // console.log('getAuthState: LOGGED IN : FALSE');

              resultLoginInfo.userName = '';
              resultLoginInfo.loggedIn = false;
              resultLoginInfo.id = -1;
              this.userId = -1;

              resultLoginInfo.permissions = [];
              resultLoginInfo.clinics = [];

              result.next(resultLoginInfo);
              // console.log('LOGGED IN : FALSE : resultLoginInfo', resultLoginInfo);
            }
          })
          .catch((error) => {
            console.log('OidcAdapterService - getAuthState tryLogin ERROR ' + error);
            resultLoginInfo.userName = '';
            resultLoginInfo.loggedIn = false;
            resultLoginInfo.id = -1;
            this.userId = -1;
            resultLoginInfo.permissions = [];
            resultLoginInfo.clinics = [];
            // console.log('LOGGED IN : tryLogin ERROR : resultLoginInfo', resultLoginInfo);
            result.next(resultLoginInfo);
          });
      }
    });
    return result;
  }

  login(credential: LoginCredential): Observable<LoginInformation> {
    const result = new ReplaySubject<LoginInformation>(1);
    console.log(credential);
    return result;
  }

  logout(): Observable<LoginInformation> {
    localStorage.removeItem('access_token');
    const result = new ReplaySubject<LoginInformation>(1);
    this.oauthService.logOut();
    return result;
  }

  startLogin(): void {
    // const accessToken = localStorage.getItem('access_token');
    // if (!accessToken) {
    //   localStorage.setItem('access_token', this.tempToken);
    // }

    // console.log('OidcAdapterService - initLoginFlow START LOGIN');

    // workaround for firefox to not reload the page on an ChunkLoadError
    localStorage.setItem('OIDCLogin', 'started');

    this.oauthService.initLoginFlow();
  }

  startApp(): void {
    this.router.navigate(['/']).then();
  }

  public checkMobilePassword() {
    this.passwordMobileService.statusUserMobilePassword(this.userId ? this.userId : -1).subscribe((result) => {
      if (result.passwort_mobil && result.passwort_mobil > 0) {
        // this.router.navigate(['/']).then();
      } else {
        this.router.navigate(['users/set-initial-nidamobile-password']).then();
      }
    });
  }

  private getSettings() {
    this.moduleSettingsService.getSettings().subscribe((settings) => {
      this.setNidaMobilePassword = settings.setNidaMobilePassword;
    });
  }

  realLogin(credential: LoginCredential, returnUrl?: string, tenantId?: string, setNidaMobilePassword?: boolean) {
    console.log('realLogin', credential, returnUrl, tenantId, setNidaMobilePassword);
  }

  start2FAActivation(): void {
    console.log('start2FAActivation');
  }

  login2FA(code: string): Observable<boolean> {
    const result = new BehaviorSubject<boolean>(false);
    console.log('login2FA', code);
    result.complete();
    return result;
  }

  get2FASecret(): Observable<string> {
    console.log('get2FASecret');
    return new Observable<string>();
  }

  save2FASecret(code: string): Observable<boolean> {
    console.log('save2FASecret', code);
    return new Observable<boolean>();
  }

  navigateTo2FAActivation(): void {
    console.log('navigateTo2FAActivation');
  }
}
