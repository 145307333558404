/**
 * NIDA Attachments Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { PdfList } from '../models/pdfList';
import { ApiPDFStatus } from '../models/api-pdf-status';
import { ApiPDFItem } from '../models/api-pdf-item';
import { AccessReason } from '../models/accessReason';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsV2Service {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}attachments`;
    });
  }

  /**
   * Get a specific document of a certain nidaId by its documentId
   * Gets all information (including BLOB data) of a certain nidaId\&#39;s document, identified by its documentId
   * @param nidaId
   * @param documentId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public getPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<Blob>;
  public getPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Blob>>;
  public getPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Blob>>;
  public getPdfByDocumentId(
    nidaId: string,
    documentId: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPdfByDocumentId.');
    }
    if (documentId === null || documentId === undefined) {
      throw new Error('Required parameter documentId was null or undefined when calling getPdfByDocumentId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/pdf', 'application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get(`${this.basePath}/v2/pdfs/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(documentId))}`, {
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get a specific document of a certain nidaId by its documentId after insert a reason
   * Gets all information (including BLOB data) of a certain nidaId\&#39;s document, identified by its documentId after insert a reason
   * @param nidaId
   * @param documentId
   * @param accessReason Protocols crew object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public getPdfByDocumentIdWithAccessReason(
    nidaId: string,
    documentId: number,
    accessReason: AccessReason,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<Blob>;
  public getPdfByDocumentIdWithAccessReason(
    nidaId: string,
    documentId: number,
    accessReason: AccessReason,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<HttpResponse<Blob>>;
  public getPdfByDocumentIdWithAccessReason(
    nidaId: string,
    documentId: number,
    accessReason: AccessReason,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<HttpEvent<Blob>>;
  public getPdfByDocumentIdWithAccessReason(
    nidaId: string,
    documentId: number,
    accessReason: AccessReason,
    observe: any = 'body',
    reportProgress: boolean,
    options?: {
      httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain';
    }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPdfByDocumentIdWithAccessReason.');
    }
    if (documentId === null || documentId === undefined) {
      throw new Error('Required parameter documentId was null or undefined when calling getPdfByDocumentIdWithAccessReason.');
    }
    if (accessReason === null || accessReason === undefined) {
      throw new Error('Required parameter accessReason was null or undefined when calling getPdfByDocumentIdWithAccessReason.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/pdf', 'application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post(
      `${this.basePath}/v2/pdfs/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(documentId))}`,
      accessReason,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all PDFs attached to the specified nidaId
   * Gets meta information (no BLOB data) for all PDFs attached to the specified nidaId
   * @param nidaId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public getPdfsByNidaId(
    nidaId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<PdfList>;
  public getPdfsByNidaId(
    nidaId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<PdfList>>;
  public getPdfsByNidaId(
    nidaId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<PdfList>>;
  public getPdfsByNidaId(
    nidaId: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPdfsByNidaId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<PdfList>(`${this.basePath}/v2/pdfs/${encodeURIComponent(String(nidaId))}`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * marks a PDF as read
   * marks a PDF as read
   * @param body PDF Status Object
   * @param nidaId
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchPDFStatus(body: ApiPDFStatus, nidaId: string, id: number, observe?: 'body', reportProgress?: boolean): Observable<ApiPDFItem>;
  public patchPDFStatus(
    body: ApiPDFStatus,
    nidaId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiPDFItem>>;
  public patchPDFStatus(
    body: ApiPDFStatus,
    nidaId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ApiPDFItem>>;
  public patchPDFStatus(body: ApiPDFStatus, nidaId: string, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchPDFStatus.');
    }

    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling patchPDFStatus.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchPDFStatus.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ApiPDFItem>(
      'patch',
      `${this.basePath}/pdfs/${encodeURIComponent(String(nidaId))}/states/${encodeURIComponent(String(id))}`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }
}
