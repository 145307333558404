<ng-container *ngIf="showTwoFaCard">
  <nida-web-card class="mb-4" [headline]="'user.management.twoFactorAuthentication'">
    <div>{{ 'user.management.twoFaChanging' | transloco }}</div>

    <ng-container *ngIf="twoFaEnforced === true">
      {{ 'user.management.twoFaIsActive' | transloco }}
    </ng-container>

    <ng-container *ngIf="twoFaProhibited === true">
      {{ 'user.management.twoFaIsDisabled' | transloco }}
    </ng-container>

    <ng-container *ngIf="twoFaProhibited === false && twoFaEnforced === false">
      {{ 'user.management.twoFaIsOptional' | transloco }}
    </ng-container>

    <div class="pb-4 pt-4" *ngIf="showTwoFaSettings">
      <div>{{ 'user.management.iWantToUseTwoFA' | transloco }}</div>
      <dx-radio-group
        valueExpr="assignment"
        displayExpr="value"
        [items]="useTwoFa"
        [(value)]="yesNoSelection"
        (onValueChanged)="onValueChanged($event)"
      >
      </dx-radio-group>
    </div>
  </nida-web-card>
</ng-container>
