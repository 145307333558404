/**
 * NIDA Attachments Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { Observable } from 'rxjs';
import { RoutineDisinfection2Pdf } from '../models/routineDisinfection2Pdf';
import { RoutineDisinfection2PdfList } from '../models/routineDisinfection2PdfList';

@Injectable({
  providedIn: 'root',
})
export class RoutineDisinfection2Service {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}attachments`;
    });
  }

  /**
   * Gets the routine disinfection 2 pdf metadata of the given nidaId and the id.
   * @param nidaId The nidaId
   * @param id The id of the routine disinfection 2 pdf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public getRoutineDisinfection2Pdf(
    nidaId: string,
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<RoutineDisinfection2Pdf>;
  public getRoutineDisinfection2Pdf(
    nidaId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<RoutineDisinfection2Pdf>>;
  public getRoutineDisinfection2Pdf(
    nidaId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<RoutineDisinfection2Pdf>>;
  public getRoutineDisinfection2Pdf(
    nidaId: string,
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getRoutineDisinfection2Pdf.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRoutineDisinfection2Pdf.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options?.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected?.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<RoutineDisinfection2Pdf>(
      `${this.basePath}/pdfs/routine-disinfection2/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets the routine disinfection 2 pdf blob of the given nidaId and the id.
   * @param nidaId The nidaId
   * @param id The id of the routine disinfection 2 pdf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public getRoutineDisinfection2PdfBlob(
    nidaId: string,
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<Blob>;
  public getRoutineDisinfection2PdfBlob(
    nidaId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Blob>>;
  public getRoutineDisinfection2PdfBlob(
    nidaId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Blob>>;
  public getRoutineDisinfection2PdfBlob(
    nidaId: string,
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/pdf' | 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getRoutineDisinfection2PdfBlob.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRoutineDisinfection2PdfBlob.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options?.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/pdf', 'application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get(
      `${this.basePath}/pdfs/routine-disinfection2/${encodeURIComponent(String(nidaId))}/${encodeURIComponent(String(id))}/blob`,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets the routine disinfection 2 pdfs metadata of the given nidaId.
   * @param nidaId The nidaId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public getRoutineDisinfection2PdfList(
    nidaId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<RoutineDisinfection2PdfList>;
  public getRoutineDisinfection2PdfList(
    nidaId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<RoutineDisinfection2PdfList>>;
  public getRoutineDisinfection2PdfList(
    nidaId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<RoutineDisinfection2PdfList>>;
  public getRoutineDisinfection2PdfList(
    nidaId: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getRoutineDisinfection2PdfList.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options?.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected?.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<RoutineDisinfection2PdfList>(
      `${this.basePath}/pdfs/routine-disinfection2/${encodeURIComponent(String(nidaId))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
