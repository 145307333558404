import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MQTTSubscribeListenerService } from '@nida-web/api-mqtt-wrapper';
import { combineLatest, map, Subscription, timer } from 'rxjs';
import { MqttConnectionState } from '@meddv/ngx-mqtt';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { CapacitySharedUiModule } from '@nida-web/capacity/shared/ui';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'nida-web-mqtt-status-handler',
  standalone: true,
  imports: [CommonModule, DxPopupModule, CapacitySharedUiModule, TranslocoModule, DxButtonModule],
  templateUrl: './mqtt-status-handler.component.html',
  styleUrl: './mqtt-status-handler.component.scss',
})
export class MqttStatusHandlerComponent implements OnInit, OnDestroy {
  private mqttSubscription?: Subscription;
  private timerSubscription?: Subscription;

  showPopup = false;

  constructor(private mqttService: MQTTSubscribeListenerService, private sessionManager: SessionManagerService) {}

  ngOnInit(): void {
    this.mqttSubscription = combineLatest([
      this.sessionManager.onSessionInformationChanged(),
      this.mqttService.getConnectionStatusObservable(),
    ])
      .pipe(
        map((array) => {
          return { session: array[0], mqttState: array[1] };
        })
      )
      .subscribe((status) => {
        if (status.session.loggedIn) {
          switch (status.mqttState) {
            case MqttConnectionState.CLOSED:
            case MqttConnectionState.CONNECTING:
              if (this.timerSubscription) break;
              this.timerSubscription = timer(30000).subscribe(() => {
                this.showPopup = true;
              });
              break;
            case MqttConnectionState.CONNECTED:
              if (this.timerSubscription) this.timerSubscription.unsubscribe();
              this.timerSubscription = undefined;
              this.showPopup = false;
              break;
          }
        } else {
          if (this.timerSubscription) this.timerSubscription.unsubscribe();
          this.timerSubscription = undefined;
          this.showPopup = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.mqttSubscription) this.mqttSubscription.unsubscribe();
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
    this.timerSubscription = undefined;
    this.mqttSubscription = undefined;
  }

  reloadPage(): void {
    window.location.reload();
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function (arg) {
      arg.stopPropagation();
    });
  }
}
