import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFListComponent } from './pdf-list/pdf-list.component';
import { TranslocoModule } from '@jsverse/transloco';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { DxDataGridModule, DxPopupModule } from 'devextreme-angular';
import { UiSharedPdfListComponent, UiSharedPdfPopupComponent, UiSharedRequestPopupComponent } from '@nida-web/shared/ui';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    PdfJsViewerModule,
    PinchZoomModule,
    DxPopupModule,
    DxDataGridModule,
    UiSharedPdfListComponent,
    UiSharedPdfPopupComponent,
    UiSharedRequestPopupComponent,
  ],
  declarations: [PDFListComponent],
  exports: [PDFListComponent],
})
export class PDFViewerModule {}
