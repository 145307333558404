import { Injectable, OnDestroy } from '@angular/core';
import { IMqttMessage, IMqttServiceOptions, MqttConnectionState, MqttService } from '@meddv/ngx-mqtt';
import { QoS } from 'mqtt-packet';
import { Observable, Observer, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ConfigService } from '@nida-web/api/rest/config';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { ModuleSettingsService } from '@nida-web/core';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * Service um Topics zu subscriben und dadurch Nachrichten zu Empfangen.
 */
export class MQTTSubscribeListenerService implements OnDestroy, Observer<IMqttMessage> {
  private topicsMap: Map<string, ReplaySubject<string>>;
  private availableTopics: string[];
  private subscriptionMap: Map<string, Subscription>;
  private mqttConfigLoaded: Subject<boolean>;
  private mqttStateLogoutSub: Subscription | undefined;

  private rootTopicPath: string | undefined;

  private MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {};

  public constructor(
    private mqttService: MqttService,
    private configService: ConfigService,
    private sessionManagerService: SessionManagerService,
    private moduleSettingsService: ModuleSettingsService
  ) {
    this.topicsMap = new Map<string, ReplaySubject<string>>();
    this.subscriptionMap = new Map<string, Subscription>();
    this.availableTopics = [];
    this.mqttConfigLoaded = new ReplaySubject<boolean>(1);

    this.moduleSettingsService.getSettings().subscribe((settings) => {
      if (settings.notifications) {
        console.log('MQTT: Engaging.');

        this.sessionManagerService.onSessionInformationChanged().subscribe((sessionInformation) => {
          if (sessionInformation.loggedIn) {
            console.log('MQTT: User Logged In. Loading Config.');
            if (this.mqttStateLogoutSub) {
              this.mqttStateLogoutSub.unsubscribe();
              this.mqttStateLogoutSub = undefined;
            }
            this.configService.getConfigsByGroup('mqtt').subscribe((mqttConfigs) => {
              mqttConfigs.data.forEach((mqttConfig) => {
                switch (mqttConfig.key) {
                  case 'rootTopicPath':
                    this.rootTopicPath = mqttConfig.value;
                    break;
                  case 'username':
                    this.MQTT_SERVICE_OPTIONS.username = mqttConfig.value;
                    break;
                  case 'password':
                    this.MQTT_SERVICE_OPTIONS.password = mqttConfig.value;
                    break;
                  case 'port':
                    if (mqttConfig.value) {
                      this.MQTT_SERVICE_OPTIONS.port = parseInt(mqttConfig.value);
                    }
                    break;
                  case 'host':
                    this.MQTT_SERVICE_OPTIONS.hostname = mqttConfig.value;
                    break;
                  case 'path':
                    this.MQTT_SERVICE_OPTIONS.path = mqttConfig.value;
                    break;
                  // case 'lastWill':
                  //   this.parseLastWill(mqttConfig.value, sessionInformation.userName);
                  //   break;
                  default:
                    // all other items in mqtt group are topics
                    if (mqttConfig.value && mqttConfig.key?.startsWith('topic')) {
                      this.availableTopics.push(mqttConfig.value);
                    }
                }
              });
              console.log('MQTT: Config loaded. Connecting...');
              this.mqttService.connect(this.MQTT_SERVICE_OPTIONS);
              this.mqttConfigLoaded.next(true);
            });
          } else {
            console.log('MQTT: Logged Out.');
            for (const key of this.topicsMap) {
              this.unregisterTopic(key[0]);
            }

            this.mqttConfigLoaded.next(false);

            if (this.mqttStateLogoutSub === undefined) {
              this.mqttStateLogoutSub = this.mqttService.state.subscribe((mqttConnectionState) => {
                if (mqttConnectionState === MqttConnectionState.CONNECTED || mqttConnectionState === MqttConnectionState.CONNECTING) {
                  console.log('MQTT: REST Authentication Invalid, Killing Connection.');
                  this.mqttService.disconnect();
                }
              });
            }

            // this.mqttService.observables = {};
            this.availableTopics = [];
            this.topicsMap = new Map<string, ReplaySubject<string>>();
            this.subscriptionMap = new Map<string, Subscription>();
          }
        });
      }
    });
  }

  public getTopics(): string[] {
    return this.availableTopics;
  }

  public getConnectionStatusObservable(): Observable<MqttConnectionState> {
    return this.mqttService.state;
  }

  /**
   * Emits only once, when MQTT is connected and then completes.
   */
  public onConnected(): Observable<boolean> {
    return this.mqttService.state.pipe(
      first((state) => state === MqttConnectionState.CONNECTED),
      map(() => {
        return true;
      })
    );
  }

  /**
   * Emits only once, when MQTT is connected and then completes.
   */
  public onConnectionLost(): Observable<void> {
    return this.mqttService.state.pipe(
      first((state) => state === MqttConnectionState.CLOSED),
      map(() => {
        return;
      })
    );
  }

  public getMqttConfig(): IMqttServiceOptions {
    return this.MQTT_SERVICE_OPTIONS;
  }

  public getMqttConfigLoadedObservable(): Observable<boolean> {
    return this.mqttConfigLoaded.asObservable();
  }

  public publishMessageOnTopic(topic: string, message: string, retain = false, qos: QoS = 2): void {
    if (!topic.includes('NIDA/')) {
      topic = this.rootTopicPath + topic;
    }

    if (message === '') {
      this.mqttService
        .publish(topic, message, {
          retain: retain,
          qos: qos,
          properties: { messageExpiryInterval: 1 },
        })
        .subscribe();
    } else {
      if (retain) {
        this.mqttService.publish(topic, message, { retain: retain, qos: qos, properties: { messageExpiryInterval: 43200 } }).subscribe();
      } else {
        this.mqttService.publish(topic, message, { retain: retain, qos: qos }).subscribe();
      }
    }
  }

  /**
   * Function to Register an Object to a MQTT Topic.
   * @param topic Topic to Subscribe
   * @return ID for unregistering
   */
  public registerTopic(topic: string): Observable<string> {
    if (!topic.includes('NIDA/')) {
      topic = this.rootTopicPath + topic;
    }

    let observableResult = this.topicsMap.get(topic);
    if (observableResult === undefined || !observableResult) {
      observableResult = new ReplaySubject<string>(1);
      this.topicsMap.set(topic, observableResult);
      this.subscriptionMap.set(topic, this.mqttService.observe(topic).subscribe(this));
    }

    return observableResult.asObservable();
  }

  /**
   * Unregisters Callback by ID
   * @param topic ID from Register
   */
  public unregisterTopic(topic: string): boolean {
    if (topic === undefined || topic === null) {
      return false;
    }

    if (!topic.includes('NIDA/')) {
      topic = this.rootTopicPath + topic;
    }

    const topicFormMap = this.topicsMap.get(topic);
    const subscription = this.subscriptionMap.get(topic);
    let deleted = false;
    if (topicFormMap !== undefined && topicFormMap && subscription !== undefined && subscription) {
      subscription.unsubscribe();
      this.topicsMap.delete(topic);
      this.subscriptionMap.delete(topic);
      deleted = true;
    }
    return deleted;
  }

  public ngOnDestroy(): void {}

  public complete(): void {}

  public error(): void {}

  public next(value: IMqttMessage): void {
    const topicFormMap = this.topicsMap.get(value.topic);
    if (topicFormMap !== undefined && topicFormMap) {
      topicFormMap.next(value.payload.toString());
    }
  }

  // private parseLastWill(value?: string, userName?: string) {
  //   if (value) {
  //     const lastWill: MQTTLastWill = JSON.parse(value);
  //     let payloadString: string = JSON.stringify(lastWill.payload);
  //     payloadString = payloadString
  //       .replace(/\$\{userName\}/g, userName ? userName : '')
  //       .replace(/\$\{actDate\}/g, new Date().toISOString());
  //
  //     let rootTopicPath = '';
  //     if (this.rootTopicPath) {
  //       rootTopicPath = this.rootTopicPath;
  //     }
  //
  //     lastWill.topic = lastWill.topic
  //       .replace(/\$\{userName\}/g, userName ? userName : '')
  //       .replace(/\$\{actDate\}/g, new Date().toISOString());
  //     this.MQTT_SERVICE_OPTIONS.will = {
  //       topic: rootTopicPath + lastWill.topic,
  //       qos: 2,
  //       retain: true,
  //       payload: payloadString,
  //     };
  //   }
  // }
}
