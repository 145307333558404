import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  AppUpdateService,
  AuthGuard,
  CheckForUpdateService,
  ColumnConfigSubjectService,
  ConfigService,
  ConfigSubjectService,
  CorpulsBindingService,
  GlobalErrorHandlerService,
  MappingConfigSubjectService,
  SharedDataAccessModule,
  StartPageGuard,
} from '@nida-web/shared/data-access';

import { ServiceWorkerModule } from '@angular/service-worker';
import {
  InsuranceStoreService,
  PatientListStoreService,
  PatientManagementModule,
  PatientNotificationService,
} from '@nida-web/patient-management';
import { MQTTConfigServable, MQTTWrapperModule } from '@nida-web/api-mqtt-wrapper';
import {
  InsuranceRestAdapterService,
  MappingConfigServable,
  PatientDetailRESTAdapterService,
  PatientRestAdapterService,
  PatientViewRESTAdapterService,
  VitalSignsRESTAdapterService,
} from '@nida-web/api/rest/patient-management';
import {
  InsuranceServable,
  PatientDetailServable,
  PatientListServable,
  PatientServable,
  VitalSignsServable,
} from '@nida-web/api/generic-interfaces/patient-management';
import { AuthenticationServable } from '@nida-web/api/generic-interfaces/authentication';
import {
  AuthenticationAdapterService,
  AuthenticationRestService,
  LoginRESTService,
  PlainLoginRESTService,
  SessionManagerService,
  TwoFactorAuthenticationRestService,
  UserinfoRESTService,
} from '@nida-web/api/rest/authentication';
import { AuthenticationModule } from '@nida-web/authentication';
import { ConfigRESTServable } from '@nida-web/api/generic-interfaces/config';
import { AuthTypeSettingsService, ColumnConfigServable, CoreModule, ModuleSettingsService, ScreenService } from '@nida-web/core';
import { NavigationModule } from '@nida-web/navigation';
import * as Sentry from '@sentry/angular';
import { SentryErrorHandler } from '@sentry/angular';
import { AttachmentsServable } from '@nida-web/api/generic-interfaces/attachments';
import { AttachmentsAdapterService } from '@nida-web/api/rest/attachments';
import { EmergencyRoomServable } from '@nida-web/api/generic-interfaces/emergencyroom';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReplaySubject, Subject } from 'rxjs';
import { Oauth2OidcModule, OidcAdapterService } from '@nida-web/oauth2-oidc';
import { EmergencyRoomAdapterService } from '@nida-web/api/rest/emergencyroom';
import { CallHandlerModule } from '@nida-web/call-handler';
import { NotificationBrokerService } from '@nida-web/shared/notifications';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { EcgServable } from '@nida-web/api/generic-interfaces/ecg-viewer';
import { EcgRestAdapterService } from '@nida-web/api/rest/ecg-viewer';
import { SharedSupportModule } from '@nida-web/shared/support';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { GroupServable, UserServable } from '@nida-web/api/generic-interfaces/user-management';
import { GroupRESTAdapterService, UserRESTAdapterService } from '@nida-web/api/rest/user-management';
import { PasswordAdapterService } from '@nida-web/api/rest/nidaserver/password3';
import { environment } from '../environments/environment';
import { KisListServable, KisRestAdapterService } from '@nida-web/kis';
import { ProtocolServable } from '@nida-web/api/generic-interfaces/protocol';
import { ProtocolAdapterService } from '@nida-web/api/rest/nidaserver/protocol';
import { navigation } from './app-navigation';
import { FontHTTPLoaderService, FontServable } from '@nida-web/svg-management';
import { MqttStatusHandlerComponent } from '@nida-web/shared/mqtt-status-handler';
import { PageNotFoundModule } from '@nida-web/shared/page-not-found';

registerLocaleData(localeDE);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    TranslocoTranslationModule.forRoot(environment),
    CoreModule.forRoot({
      nanPlaceholder: '—',
    }),
    CallHandlerModule.forRoot({
      incomingCallTopic: 'NIDA/de/kl/meddv.dev-klinik/Kliniktest/tna/call',
      rescueTrackWebhook: 'https://webhook.webhooook.de',
    }),
    BrowserAnimationsModule,
    BrowserModule,
    NavigationModule.forRoot(navigation), // navbar
    Oauth2OidcModule,
    RouterModule,
    AppRoutingModule,
    PatientManagementModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    AuthenticationModule,
    SharedDataAccessModule,
    SharedSupportModule,
    MQTTWrapperModule,
    MqttStatusHandlerComponent,
    PageNotFoundModule,
  ],
  providers: [
    TranslocoModule,
    ConfigService,
    CheckForUpdateService,
    AppUpdateService,
    { provide: ConfigRESTServable, useExisting: ConfigSubjectService },
    {
      provide: SentryErrorHandler,
      useValue: Sentry.createErrorHandler({
        // Dialog is only active in production mode, we do not want to annoy developers and testers - only customers ;-)
        // showDialog: environment.production,
        showDialog: false,
        dialogOptions: {
          // user: {
          //   name: 'ksdfjus',
          //   email: UserSubjectService.getUserSubject();
          // },
          // title: "Hier ist wohl etwas schief gelaufen!"
        },
      }),
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: ColumnConfigServable, useExisting: ColumnConfigSubjectService },
    { provide: MQTTConfigServable, useExisting: ConfigSubjectService },
    { provide: MappingConfigServable, useExisting: MappingConfigSubjectService },
    NotificationBrokerService,
    { provide: PatientListServable, useClass: PatientViewRESTAdapterService },
    { provide: InsuranceServable, useClass: InsuranceRestAdapterService },
    { provide: PatientServable, useClass: PatientRestAdapterService },
    { provide: AttachmentsServable, useClass: AttachmentsAdapterService },
    { provide: PatientDetailServable, useClass: PatientDetailRESTAdapterService },
    { provide: GroupServable, useClass: GroupRESTAdapterService },
    { provide: UserServable, useClass: UserRESTAdapterService },
    { provide: VitalSignsServable, useClass: VitalSignsRESTAdapterService },
    { provide: KisListServable, useClass: KisRestAdapterService },
    { provide: ProtocolServable, useClass: ProtocolAdapterService },
    { provide: FontServable, useClass: FontHTTPLoaderService },
    PatientNotificationService,
    {
      provide: AuthenticationServable,
      useFactory: authService,
      deps: [
        OAuthService,
        AuthTypeSettingsService,
        AuthenticationRestService,
        TwoFactorAuthenticationRestService,
        LoginRESTService,
        Router,
        PasswordAdapterService,
        ModuleSettingsService,
        SessionManagerService,
        PlainLoginRESTService,
        UserinfoRESTService,
      ],
    },
    {
      provide: APP_BASE_HREF,
      useValue: environment.baseHref,
    },
    { provide: EmergencyRoomServable, useClass: EmergencyRoomAdapterService },
    PatientListStoreService,
    InsuranceStoreService,
    PasswordAdapterService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [ConfigService, AuthenticationServable, SessionManagerService, Router, PasswordAdapterService, Sentry.TraceService],
      multi: true,
    },
    { provide: EcgServable, useClass: EcgRestAdapterService },
    HttpClient,
    ScreenService,
    AuthGuard,
    StartPageGuard,
    CorpulsBindingService,
    MappingConfigSubjectService,
    DatePipe,
    { provide: 'environment', useValue: environment },
    { provide: 'SERVER_BASE_HREF', useValue: environment.serverBaseHref },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
@Injectable()
export class AppModule {}

const authServiceDefined: Subject<boolean> = new ReplaySubject(1);

function authService(
  oauthService: OAuthService,
  authTypeSettingsService: AuthTypeSettingsService,
  authenticationRestService: AuthenticationRestService,
  twoFactorAuthenticationRestService: TwoFactorAuthenticationRestService,
  loginRESTService: LoginRESTService,
  router: Router,
  passwordMobileService: PasswordAdapterService,
  moduleSettingsService: ModuleSettingsService,
  sessionManagerService: SessionManagerService,
  plainLoginRESTService: PlainLoginRESTService,
  userInfoService: UserinfoRESTService,
  translocoService: TranslocoService
) {
  const authType = environment.authType;
  let authAdapter: OidcAdapterService | AuthenticationAdapterService;
  switch (authType) {
    case 'openid':
      authAdapter = new OidcAdapterService(
        oauthService,
        router,
        authTypeSettingsService,
        loginRESTService,
        passwordMobileService,
        moduleSettingsService,
        sessionManagerService
      );
      break;
    case 'nida':
    default:
      authAdapter = new AuthenticationAdapterService(
        authenticationRestService,
        twoFactorAuthenticationRestService,
        loginRESTService,
        router,
        userInfoService,
        plainLoginRESTService,
        sessionManagerService,
        passwordMobileService,
        translocoService
      );
  }
  authServiceDefined.next(true);
  return authAdapter;
}

export function loadConfig(
  configService: ConfigService,
  authenticationService: AuthenticationServable,
  sessionManager: SessionManagerService
) {
  return (): Promise<any> => {
    return new Promise<any>((resolve) => {
      configService.loadConfig().then(() => {
        authServiceDefined.subscribe((value) => {
          if (value) {
            authenticationService.getAuthState().subscribe((resp) => {
              // important loggedIn, permissions, id
              // mandant into sessionManager
              if (resp.loggedIn) {
                const accessToken = localStorage.getItem('access_token');
                const accessToken2 = accessToken ? accessToken : '';

                sessionManager.setSessionInformation({
                  userId: resp.id,
                  userName: resp.userName,
                  permissions: resp.permissions,
                  loggedIn: resp.loggedIn,
                  loginToken: accessToken2,
                  clinics: resp.clinics,
                  tenantId: resp.idMandanten !== undefined ? resp.idMandanten : -1,
                  tenantLabels: resp.tenantLabels,
                });

                // Check if user has only the 2FA.activate permission and navigate to the 2FA activation page
                // This happens in the case that the user has not yet activated 2FA and is enforced to do so
                if (resp.permissions && resp.permissions.length === 1 && resp.permissions[0] === 'nida.2FA.activate1:rw') {
                  authenticationService.navigateTo2FAActivation();
                }
                resolve(true);
              } else {
                authenticationService.startLogin();
                resolve(true);
              }
            });
          }
        });
      });
    });
  };
}
