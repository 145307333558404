import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Findings, MissionData, PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { DateService } from '@nida-web/core';
import { Finding } from '../models/finding.model';
import { TranslocoService } from '@jsverse/transloco';
import dayjs from 'dayjs';
import { DateHelperService } from '@nida-web/shared/utils';

@Component({
  selector: 'nida-web-patient-detail-preregistration-information',
  templateUrl: './patient-detail-preregistration-information.component.html',
  styleUrls: ['./patient-detail-preregistration-information.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientDetailPreregistrationInformationComponent implements OnInit {
  @Input() patientData: PatientView;
  @Input() missionData: MissionData;
  @Input() findingData: Findings;
  @Input() arrivalIsInFuture: boolean;

  public findings: Array<Finding>;
  public strokeMain: Array<Finding>;
  public strokeTimestamps: Array<Finding>;
  public strokeSum: Array<Finding>;
  public findingName: string;
  public initialGcs?: number;

  public timeSinceFindingText?: string;

  constructor(protected dateService: DateService, private transloco: TranslocoService) {
    this.findings = [];
    this.strokeMain = [];
    this.strokeTimestamps = [];
    this.strokeSum = [];
  }

  ngOnInit() {
    this.initFindingsArray();

    const currentDate = new Date();
    const datetime: number | string | undefined = this.findingData.event_time ? this.findingData.event_time : this.findingData.event_date;
    if (datetime) {
      const days: number = dayjs(currentDate).diff(datetime, 'days');
      if (days <= 1) {
        this.timeSinceFindingText = DateHelperService.getTimeDiffStringDE(currentDate, dayjs(datetime).toDate());
      }
    }
    this.initialGcs = this.findingData.initial_gcs === undefined ? this.findingData.initial_gcs : +this.findingData.initial_gcs;
  }

  initFindingsArray() {
    if (this.patientData.diagnosis === 'Schlaganfall' || this.patientData.diagnosis?.toLowerCase() === 'stroke') {
      this.strokeMain = [
        {
          text: 'Anticoagulants',
          value: this.uc(this.findingData.supplement1value),
        },
        {
          text: 'Anticoagulant',
          value: this.findingData.stroke_anticoagulation,
        },
        {
          text: 'Pre MRS',
          value: this.findingData.stroke_prae_mrs,
        },
        {
          text: 'Previous Illness',
          value:
            '' +
            this.uc(this.findingData.stroke_previousillness_atrialfibrillation) +
            this.uc(this.findingData.stroke_previousillness_diabetes) +
            this.uc(this.findingData.stroke_previousillness_epilepsy) +
            this.uc(this.findingData.stroke_previousillness_hypertension) +
            this.uc(this.findingData.stroke_previousillness_migraine),
        },
        {
          text: 'Injuries',
          value: this.findingData.stroke_injuries ? this.findingData.stroke_injuries : this.findingData.supplement2value,
        },
      ];
      this.strokeTimestamps = [
        {
          text: 'Event time',
          value: this.combineDateTimeForDisplay(this.findingData.event_date, this.findingData.event_time),
        },
        {
          text: 'Last Seen Well',
          value: this.findingData.stroke_last_seen_well_text
            ? this.findingData.stroke_last_seen_well_text
            : this.combineDateTimeForDisplay(this.findingData.stroke_last_seen_well_date, this.findingData.stroke_last_seen_well_time),
        },
      ];
      this.strokeSum = [];
      if (
        this.findingData.supplement3value !== undefined &&
        this.findingData.stroke_snihss_sum === undefined &&
        this.findingData.stroke_face2ad_sum === undefined
      ) {
        this.strokeSum.push(
          {
            text: '4ISS Sum',
            value: this.findingData.supplement3value,
          },
          {
            text: '4ISS Vigilance',
            value: this.findingData.stroke_4iss_vigilance,
          },
          {
            text: '4ISS Turn',
            value: this.findingData.stroke_4iss_turn,
          },
          {
            text: '4ISS Hemiparese',
            value: this.findingData.stroke_4iss_hemiparese,
          },
          {
            text: '4ISS Side of hemiparese',
            value: this.findingData.stroke_4iss_hemiparese_side_value,
          },
          {
            text: '4ISS Speech disorder',
            value: this.findingData.speech_disorder,
          }
        );
      }

      if (this.findingData.stroke_snihss_sum !== undefined) {
        this.strokeSum.push(
          {
            text: 'SNIHSS Sum',
            value: this.findingData.stroke_snihss_sum,
          },
          {
            text: 'SNIHSS Consciousness',
            value: this.findingData.stroke_snihss_consciousness_nr + ' ' + this.findingData.stroke_snihss_consciousness_text,
          },
          {
            text: 'SNIHSS Face',
            value: this.findingData.stroke_snihss_face_nr + ' ' + this.findingData.stroke_snihss_face_text,
          },
          {
            text: 'SNIHSS Motorics',
            value: this.findingData.stroke_snihss_motorics_nr + ' ' + this.findingData.stroke_snihss_motorics_text,
          },
          {
            text: 'SNIHSS Sensitivity',
            value: this.findingData.stroke_snihss_sensitivity_nr + ' ' + this.findingData.stroke_snihss_sensitivity_text,
          },
          {
            text: 'SNIHSS Speech impediment',
            value: this.findingData.stroke_snihss_speech_impediment_nr + ' ' + this.findingData.stroke_snihss_speech_impediment_text,
          },
          {
            text: 'SNIHSS Washed out',
            value: this.findingData.stroke_snihss_washed_out_nr + ' ' + this.findingData.stroke_snihss_washed_out_text,
          }
        );
      }

      if (this.findingData.stroke_face2ad_sum !== undefined) {
        this.strokeSum.push(
          {
            text: 'Face2AD Sum',
            value: this.findingData.stroke_face2ad_sum,
          },
          {
            text: 'Face2AD Faceparalysis',
            value: this.findingData.stroke_face2ad_face_nr + ' ' + this.findingData.stroke_face2ad_face,
          },
          {
            text: 'Face2AD Parese arm',
            value: this.findingData.stroke_face2ad_parese_arm_nr + ' ' + this.findingData.stroke_face2ad_parese_arm,
          },
          {
            text: 'Face2AD Impaired Consciousness',
            value: this.findingData.stroke_face2ad_consciousness_nr + ' ' + this.findingData.stroke_face2ad_consciousness,
          },
          {
            text: 'Face2AD Stoveview',
            value: this.findingData.stroke_face2ad_stoveview_nr + ' ' + this.findingData.stroke_face2ad_stoveview,
          },
          {
            text: 'Face2AD Atrialfibrillation',
            value: this.findingData.stroke_face2ad_atrialfibrillation_nr + ' ' + this.findingData.stroke_face2ad_atrialfibrillation,
          },
          {
            text: 'Face2AD Diastol. RR < 85 mmHg',
            value: this.findingData.stroke_face2ad_rr_dia_85_nr + ' ' + this.findingData.stroke_face2ad_rr_dia_85,
          },
          {
            text: 'Face2AD Parese leg',
            value: this.findingData.stroke_face2ad_parese_bein,
          },
          {
            text: 'Face2AD Aphasie',
            value: this.findingData.stroke_face2ad_aphasie,
          },
          {
            text: 'Face2AD Side',
            value: this.findingData.stroke_face2ad_side,
          }
        );
      }

      this.findingName = this.patientData.diagnosis;
    } else if (
      this.patientData.diagnosis === 'Herzinfarkt' ||
      this.patientData.diagnosis?.toLowerCase() === 'heart attack' ||
      this.patientData.diagnosis === 'ACS'
    ) {
      this.findings = [
        {
          text: 'Anticoagulants',
          value: this.findingData.supplement1value,
        },
        {
          text: 'Pain',
          value: this.findingData.pain,
        },
        {
          text: 'Pain radiation',
          value: this.findingData.pain_radiation,
        },
        {
          text: 'Event time',
          value: this.combineDateTimeForDisplay(this.findingData.event_date, this.findingData.event_time),
        },
        {
          text: 'Intubation',
          value: this.findingData.cardio_intubated,
        },
        {
          text: 'Catecholamine dose',
          value: this.findingData.cardio_catecholamins,
        },
        {
          text: 'Reanimation',
          value: this.findingData.cardio_cpr,
        },
        {
          text: 'STEMI',
          value: this.findingData.cardio_stemi,
        },
        {
          text: 'Hearth catheter',
          value: this.findingData.cardio_catheter,
        },
        {
          text: 'Bypass',
          value: this.findingData.cardio_bypass,
        },
        {
          text: 'Heart attack',
          value: this.findingData.cardio_heartattack,
        },
      ];
      this.findingName = this.patientData.diagnosis;
    } else if (this.patientData.diagnosis === 'Krampfanfall' || this.patientData.diagnosis?.toLowerCase() === 'seizure') {
      this.findings = [
        {
          text: 'Eyes opened during seizure',
          enabled: this.findingData.pees_eyes,
          value: this.findingData.pees_eyes_value,
        },
        {
          text: 'Turn of eyes',
          enabled: this.findingData.pees_view,
          value: this.findingData.pees_view_value,
        },
        {
          text: 'One-sided stretched, stiff arm and/or leg',
          enabled: this.findingData.pees_arm_leg,
          value: this.findingData.pees_arm_leg_value,
        },
        {
          text: 'One-sided twitching (no shivering)',
          enabled: this.findingData.pees_twitching,
          value: this.findingData.pees_twitching_value,
        },
        {
          text: 'Nervous fidging, narrow lips and confusion',
          enabled: this.findingData.pees_mouth,
          value: this.findingData.pees_mouth_value,
        },
        {
          text: 'Extremities stretched on both sides with twitches',
          enabled: this.findingData.pees_extremities,
          value: this.findingData.pees_extremities_value,
        },
        {
          text: 'Duration of seizure',
          enabled: this.findingData.pees_duration_seizure,
          value: this.findingData.pees_duration_seizure_value,
        },
        {
          text: 'Duration of consciousness',
          enabled: this.findingData.pees_duration_consciousness,
          value: this.findingData.pees_duration_consciousness_value,
        },
        {
          text: 'Tongue bite',
          enabled: this.findingData.pees_tongue_bite,
          value: this.findingData.pees_tongue_bite_value,
        },
        {
          text: 'Epilepsy / Known seizure',
          enabled: this.findingData.pees_epilepsy,
          value: this.findingData.pees_epilepsy_value,
        },
      ];
      this.findingName = this.transloco.translate('Prehospital epilepsy emergency score (pees) sum');
    }
  }

  combineDateTimeForDisplay(datePart: string | number | undefined, timePart: string | number | undefined) {
    let returnString: string;
    const divideString = ' ';

    if (datePart === undefined && timePart === undefined) {
      returnString = '';
    } else {
      if (datePart === undefined) {
        datePart = '';
      }
      if (timePart === undefined) {
        timePart = '';
      }
      returnString = new Date(datePart).toLocaleDateString() + divideString + new Date(timePart).toLocaleTimeString();
    }

    return returnString;
  }

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }

  uc(obj: any): string {
    if (obj) {
      return obj + ' ';
    } else {
      return '';
    }
  }
}
