import { Injectable } from '@angular/core';
import { User, UserList, UserServable } from '@nida-web/api/generic-interfaces/user-management';
import { forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { PersonalRESTService } from '../actions/personal.rest.service';
import { APIPersonal } from '../models/api-personal.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PermissionsGroupRESTService } from '../actions/permissions-group.rest.service';
import { GroupRESTAdapterService } from './group-rest-adapter.service';
import { PasswordService } from '@nida-web/api/rest/nidaserver/password3';

@Injectable({
  providedIn: 'root',
})
export class UserRESTAdapterService implements UserServable {
  constructor(
    private personalRESTService: PersonalRESTService,
    private groupRESTAdapterService: GroupRESTAdapterService,
    private passwordService: PasswordService,
    private permissionsRESTService: PermissionsGroupRESTService //private permissionsMemberRESTService: PermissionsMemberRESTService
  ) {}

  public addUser(user: User): Observable<User | undefined> {
    const resultSubject = new ReplaySubject<User | undefined>(1);

    const apiUser: APIPersonal = {
      id_mandanten: user.idMandanten,
      benutzer: user.userName,
      vorname: user.firstName,
      name: user.lastName,
      mail: user.email ? user.email : undefined,
      passwort: user.userPassword,
      anmeldung: true,
    };

    this.personalRESTService.addNewPersonal(apiUser).subscribe((resultUser) => {
      if (resultUser === undefined || resultUser.id === undefined) {
        resultSubject.next(undefined);
        resultSubject.complete();
      } else if (resultUser.id && resultUser.id >= 0) {
        resultSubject.next({
          id: resultUser.id,
          idMandanten: resultUser.id_mandanten,
          userName: resultUser.benutzer ? resultUser.benutzer : '',
          firstName: apiUser.vorname ? apiUser.vorname : undefined,
          lastName: apiUser.name ? apiUser.name : undefined,
          email: apiUser.mail ? apiUser.mail : undefined,
        });
        resultSubject.complete();
      } else {
        resultSubject.next(undefined);
        resultSubject.complete();
      }
    });

    return resultSubject.asObservable();
  }

  public deleteUser(userID: number): Observable<boolean> {
    if (isNaN(userID) || userID < 0) {
      return of(false);
    }

    return this.personalRESTService.deletePersonalById(String(userID)).pipe(
      switchMap(() => this.permissionsRESTService.getAllGroupsByPersonalId(String(userID))),
      map((response) => {
        const assignmentDeleteReqests: any = [];

        if (response && response.data && response.data.length > 0) {
          //if response = false then no permissions or locations are assigned to the deleted user
          //so the forEach loop doesn't get executed
          response.data.forEach((item) => {
            if (userID && item.id) {
              assignmentDeleteReqests.push(this.groupRESTAdapterService.removeUser(userID, item.id));
            }
          });
        }

        return forkJoin(assignmentDeleteReqests);
      }),
      map((response) => {
        if (response) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        console.warn(error);
        return of(false);
      })
    );
  }

  public getAllUsers(tenant: number): Observable<UserList> {
    return this.personalRESTService.getAllPersonal(tenant).pipe(
      map((personalList) => {
        const userList: UserList = [];
        if (!personalList || (personalList && personalList.data === undefined)) {
          return userList;
        }
        personalList.data.forEach((apiUser) => {
          if (!apiUser || !apiUser.id || !apiUser.benutzer || apiUser.id_mandanten === undefined) {
            throwError('A User in the list is undefined! Check Database and/or REST-Server.');
          } else {
            const user: User = {
              id: apiUser.id ? apiUser.id : 0,
              firstName: apiUser.vorname !== null ? apiUser.vorname : undefined,
              lastName: apiUser.name !== null ? apiUser.name : undefined,
              userName: apiUser.benutzer,
              email: apiUser.mail ? apiUser.mail : undefined,
              idMandanten: apiUser.id_mandanten,
              loginType: apiUser.typ ? apiUser.typ : undefined,
            };

            userList.push(user);
          }
        });

        return userList;
      })
    );
  }

  public getUser(userID: number): Observable<User> {
    const resultSubject = new ReplaySubject<User>(1);

    if (isNaN(userID) || userID < 0) {
      resultSubject.next({ id: -1, idMandanten: -1, userName: '' });
      return resultSubject.asObservable();
    }

    this.personalRESTService.getPersonById('' + userID).subscribe((apiUser) => {
      const user: User = new User(); // TODO Konstruktor!

      if (apiUser === undefined || apiUser.id === undefined || apiUser.benutzer === undefined || apiUser.id_mandanten === undefined) {
        throwError('The User is undefined! Check Database and/or REST-Server.');

        resultSubject.next({ id: -1, idMandanten: -1, userName: '' });

        return;
      }
      user.id = apiUser.id ? apiUser.id : 0;
      user.firstName = apiUser.vorname !== null ? apiUser.vorname : undefined;
      user.lastName = apiUser.name !== null ? apiUser.name : undefined;
      user.userName = apiUser.benutzer !== null ? apiUser.benutzer : '';
      user.email = apiUser.mail ? apiUser.mail : undefined;
      user.idMandanten = apiUser.id_mandanten;
      user.loginType = apiUser.typ ? apiUser.typ : undefined;
      resultSubject.next(user);
    });
    return resultSubject.asObservable();
  }

  public updateUser(user: User): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    if (user === null || user === undefined) {
      resultSubject.next(false);
      return resultSubject.asObservable();
    }
    const apiUser: APIPersonal = {
      id_mandanten: user.idMandanten,
      benutzer: user.userName,
    };

    apiUser.id = user.id;
    apiUser.vorname = user.firstName;
    apiUser.name = user.lastName;
    apiUser.passwort = user.userPassword;
    apiUser.mail = user.email ? user.email : undefined;

    this.personalRESTService.savePerson(apiUser, apiUser.id).subscribe(() => {
      resultSubject.next(true);
    });

    return resultSubject.asObservable();
  }

  public changePassword(userId: number, oldPassword: string | undefined, newPassword: string): Observable<boolean> {
    return this.passwordService
      .changeUserPassword(
        userId,
        {
          newPassword: newPassword,
          oldPassword: oldPassword ? oldPassword : '',
        },
        'body',
        false,
        { httpHeaderAccept: 'text/plain' }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  public adminResetUserPassword(userId: number, newPassword: string): Observable<boolean> {
    return this.passwordService
      .adminResetUserPassword(
        userId,
        {
          newPassword: newPassword,
          oldPassword: '',
        },
        'body',
        false,
        { httpHeaderAccept: 'text/plain' }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  public changeMobilePin(userId: number, newPin: string): Observable<boolean> {
    return this.passwordService
      .changeUserMobilePin(
        userId,
        {
          newPassword: newPin,
          oldPassword: '',
        },
        'body',
        false,
        { httpHeaderAccept: 'text/plain' }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }
}
